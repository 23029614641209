import React from 'react';
import { IProps } from '../../domain/model';
import UserPaymentStatus from '../../components/admin/statusMgmt/userPaymentStatus';

export default function userPaymentStatusMgmtPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <UserPaymentStatus state={state} />
        </>
      )}
    </>
  );
}
