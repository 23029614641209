import React from 'react';
import { Breadcrumb, Descriptions, Divider } from 'antd';
import { getState } from '../../../domain/store';
import { numberFormater } from '../../../domain/common';
import { onBackToSearchCompany, onBackToAccounting } from '../../../domain/middleware';

export default function SourceCompanySummary(): JSX.Element {
  const summary = getState().accountingSummary;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          {/* eslint-disable-next-line */}
          <a
            onClick={() => {
              onBackToSearchCompany();
            }}
          >
            企業検索
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {/* eslint-disable-next-line */}
          <a
            onClick={() => {
              onBackToAccounting();
            }}
          >
            請求・入金管理
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>預り金付替</Breadcrumb.Item>
      </Breadcrumb>
      <Descriptions className={'summaryTable'} size="small" layout="vertical" bordered column={5}>
        <Descriptions.Item label="企業ID">{summary.companyId}</Descriptions.Item>
        <Descriptions.Item label="企業名">{summary.companyName}</Descriptions.Item>
        <Descriptions.Item className={'right'} label="請求残">
          {summary.invoiceBalance < 0 ? (
            <label style={{ color: 'red' }}>{numberFormater(summary.invoiceBalance)}</label>
          ) : (
            numberFormater(summary.invoiceBalance)
          )}
        </Descriptions.Item>
        <Descriptions.Item className={'right'} label="預り金残">
          {summary.creditBalance < 0 ? (
            <label style={{ color: 'red' }}>{numberFormater(summary.creditBalance)}</label>
          ) : (
            numberFormater(summary.creditBalance)
          )}
        </Descriptions.Item>
        <Descriptions.Item className={'right'} label="過不足">
          {summary.accountBalance < 0 ? (
            <label style={{ color: 'red' }}>{numberFormater(summary.accountBalance)}</label>
          ) : (
            numberFormater(summary.accountBalance)
          )}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">預り金の移送先を検索</Divider>
    </>
  );
}
