import React from 'react';
import {
  onUploadUserContractFile,
  onAddUserContractInvoiceFile,
  onAddUserContractDeletionFile,
  onClearUserContractInvoiceFile,
  onClearUserContractDeletionFile,
  onRemoveInvoiceFile,
  onRemoveDeletionFile
} from '../../../domain/middleware';
import { Upload, Button, Card, Row, Col } from 'antd';
import { getState } from '../../../domain/store';

export default function UserContractUpload(): JSX.Element {
  const invoiceFileList: any[] = getState().userContextUpload.invoiceFileList;
  const deletionFileList: any[] = getState().userContextUpload.deletionFileList;

  // アップロード実行
  function handleUpload(): void {
    onUploadUserContractFile(invoiceFileList, deletionFileList);
  }

  // アップロードファイルを指定するエリアのタイトル部の作成
  function createTitle(title: string): any {
    return (
      <div>
        <span style={{ verticalAlign: 'middle', fontWeight: 'bold', fontSize: 18 }}>{title}</span>
      </div>
    );
  }

  // ファイルアップロードのCard作成
  function createUploadCardParts(titlePart: any, uploadProps: any, fileList: any[], onClickFunc: () => void): any {
    let uploadCard: any = '';
    if (fileList && fileList.length > 0) {
      uploadCard = (
        <div>
          <div style={{ textAlign: 'left' }}>
            <Upload {...uploadProps} />
          </div>
          <div style={{ margin: '15px 0px 0px 0px', textAlign: 'center' }}>
            <Button onClick={onClickFunc}>選択したファイルをクリア</Button>
          </div>
        </div>
      );
    } else {
      uploadCard = (
        <div>
          <Upload {...uploadProps}>
            <span>ファイルが選択されていません。</span>
            <div style={{ margin: '5px 0px 0px 0px', textAlign: 'center' }}>
              <Button>ファイルを選択</Button>
            </div>
          </Upload>
        </div>
      );
    }

    return (
      <Card title={titlePart} style={{ verticalAlign: 'middle' }}>
        {uploadCard}
      </Card>
    );
  }

  // Uploadコンポーネントの設定情報の作成
  function createUploadProperty(displayList: any[], beforeUploadFunc: (file: File) => void, onRemoveFunc: (file: any) => void): any {
    return {
      // ファイル追加時
      beforeUpload: (file: File) => {
        beforeUploadFunc(file);
        return false;
      },
      onRemove: (file: any) => {
        onRemoveFunc(file);
      },
      // 複数アップロードを許す
      multiple: true,
      // 削除アイコンは表示
      showUploadList: {
        showRemoveIcon: true
      },
      // 画面に表示するリスト
      fileList: displayList,
      textAlign: 'left'
    };
  }

  // Uploadコンポーネントに設定する情報を作成
  const invoiceProps = createUploadProperty(invoiceFileList, onAddUserContractInvoiceFile, onRemoveInvoiceFile);
  const deletionProps = createUploadProperty(deletionFileList, onAddUserContractDeletionFile, onRemoveDeletionFile);

  // タイトル部の作成
  const titleInvoice = createTitle('請求データ');
  const titleDeletion = createTitle('請求削除データ');

  // ファイル種類別のアップロードCard部
  const invoiceUploadCard = createUploadCardParts(titleInvoice, invoiceProps, invoiceFileList, onClearUserContractInvoiceFile);
  const deletionUploadCard = createUploadCardParts(titleDeletion, deletionProps, deletionFileList, onClearUserContractDeletionFile);

  return (
    <div style={{ textAlign: 'center' }}>
      <Row gutter={16}>
        <Col span={24}>
          <div style={{ margin: '10px 20px 10px 0px', textAlign: 'center' }}>
            <Button
              block
              onClick={handleUpload}
              disabled={invoiceFileList.length === 0 && deletionFileList.length === 0}
              style={{ marginLeft: 10 }}
            >
              アップロード開始
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>{invoiceUploadCard}</Col>
        <Col span={12}>{deletionUploadCard}</Col>
      </Row>
    </div>
  );
}
