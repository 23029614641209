import React, { useState } from 'react';
import { Form, Button, DatePicker, Checkbox, Row, Col, Divider, Input, Select } from 'antd';
import { getState } from '../../../domain/store';
import { onGetAccountingInfo } from '../../../domain/middleware';
import * as common from '../../../domain/common';
import './searchForm.css';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment from 'moment';
import { INVOICE_DIGEST_SIMPLE } from 'domain/const';

const { Option } = Select;

function SearchForm(props: any): JSX.Element {
  const { companyId, targetDateFrom, targetDateTo, onlyInvoiceWithBalance, digest, invoiceNumber } = getState().accountingSearchCondition;
  const [checked, setChecked] = useState(onlyInvoiceWithBalance);
  // チェックボックスの変更
  const handleCheckBoxChange = (e: any): any => {
    setChecked(e.target.checked);
  };
  // サブミットの実行
  const handleSubmit = (e: any): any => {
    e.preventDefault();
    const { targetDateFrom, targetDateTo, digest, invoiceNumber } = props.form.getFieldsValue();
    onGetAccountingInfo(
      companyId,
      targetDateFrom ? common.yyyymmddFormatter(targetDateFrom) : '',
      targetDateTo ? common.yyyymmddFormatter(targetDateTo) : '',
      checked,
      digest,
      invoiceNumber
    );
  };
  // const handleReset = (): any => {
  //   setChecked(false);
  //   props.form.resetFields();
  // };
  return (
    <>
      <Divider orientation="left">請求・入金明細</Divider>
      <Row>
        <Col>
          <Form className="search accounting" layout="inline" onSubmit={handleSubmit}>
            <Form.Item label="日付（開始）">
              {props.form.getFieldDecorator('targetDateFrom', { initialValue: targetDateFrom ? moment(targetDateFrom) : null })(
                <DatePicker locale={locale} placeholder={'日付（開始）'} allowClear={true} format="YYYY/MM/DD" />
              )}
            </Form.Item>
            <Form.Item label="日付（終了）">
              {props.form.getFieldDecorator('targetDateTo', { initialValue: targetDateTo ? moment(targetDateTo) : null })(
                <DatePicker locale={locale} placeholder={'日付（終了）'} allowClear={true} format="YYYY/MM/DD" />
              )}
            </Form.Item>
            <Form.Item label="摘要">
              {props.form.getFieldDecorator('digest', { initialValue: digest })(
                <Select style={{ width: 200 }} allowClear={true} placeholder={'摘要を選択'}>
                  <Option value={INVOICE_DIGEST_SIMPLE}>簡易表示</Option>
                  <Option value="">すべて</Option>
                  {/* <option value="請求">請求</option>
                  <option value="入金">入金</option>
                  <option value="消込">入金消込</option>
                  <option value="返金">返金</option> */}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="請求書番号">
              {props.form.getFieldDecorator('invoiceNumber', { initialValue: invoiceNumber })(<Input placeholder={'請求書番号'} />)}
            </Form.Item>
            <Form.Item label="未入金の請求書のみ表示">
              {props.form.getFieldDecorator('onlyInvoiceWithBalance')(
                <Checkbox checked={checked} onChange={handleCheckBoxChange}></Checkbox>
              )}
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" className="searchBtn" size="large">
                検索
              </Button>
              {/* <Button style={{ marginLeft: 8 }} size="large" onClick={handleReset}>
                クリア
              </Button> */}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export const AccountingSearchForm: any = Form.create()(SearchForm);
