import React from 'react';
import { IProps } from '../../domain/model';
import InvoiceDeleteStatusDetail from '../../components/admin/statusMgmt/invoiceDeleteStatusDetail';

export default function invoiceDeleteStatusMgmtDetailPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <InvoiceDeleteStatusDetail state={state} />
        </>
      )}
    </>
  );
}
