import React from 'react';
import * as ErrorPage from '../../pages/shared/errorPage';

/* eslint-disable react/no-unescaped-entities */
export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any): any {
    return { hasError: true };
  }

  render(): any {
    if (this.state.hasError) {
      // Error path
      return <ErrorPage.SystemError />;
    }
    // Normally, just render children
    return this.props.children;
  }
}
