import React from 'react';
import moment from 'moment';
import { Card, Collapse, Table, Button, Tabs, Icon } from 'antd';
import { IProps } from '../../../domain/model';
import './serviceContents.css';
import { onGoToContractUpdate, onGetRenewProducList } from '../../../domain/middleware';
import { IContractList, ISubscriptionList, IPlans } from '../../../domain/model';
import { updateIndex, termFormatter } from '../../../domain/common';
import * as Const from '../../../domain/const';

const ProductPlan = [
  {
    productRatePlanName: 'オーナー基本サービス',
    productName: 'グリーンサイト',
    productRatePlanChargeKey: 'Project_Charge',
    rowSpan: 2,
    background: false
  },
  {
    // TODO: Lite表示時以下を有効にする
    // productRatePlanName: 'オーナー基本サービス',
    // productName: 'グリーンサイトLite',
    productRatePlanName: '-',
    productName: '-',
    productRatePlanChargeKey: 'LiteProject_Charge',
    rowSpan: 0,
    background: false
  },
  {
    productRatePlanName: '',
    productName: '',
    productRatePlanChargeKey: '',
    rowSpan: 1,
    background: true
  },
  {
    productRatePlanName: 'システム連携サービス',
    productName: 'システム連携サービス',
    productRatePlanChargeKey: 'DataLink_Initial',
    rowSpan: 1,
    background: false
  },
  {
    productRatePlanName: '',
    productName: '',
    productRatePlanChargeKey: '',
    rowSpan: 1,
    background: true
  },
  {
    productRatePlanName: 'データバックアップサービス',
    productName: 'グリーンサイト データバックアップサービス',
    productRatePlanChargeKey: 'AllBackup_Initial',
    rowSpan: 2,
    background: false
  },
  {
    // TODO: Lite表示時以下を有効にする
    // productRatePlanName: 'データバックアップサービス',
    // productName: 'グリーンサイトLite データバックアップサービス',
    productRatePlanName: '-',
    productName: '-',
    productRatePlanChargeKey: 'AllBackup_Initial',
    rowSpan: 0,
    background: false
  },
  {
    productRatePlanName: '',
    productName: '',
    productRatePlanChargeKey: '',
    rowSpan: 1,
    background: true
  },
  {
    productRatePlanName: 'オーナープライムサービス',
    productName: 'グリーンサイト プライムサービス',
    productRatePlanChargeKey: 'PrimeProject_Charge',
    rowSpan: 2,
    background: false
  },
  {
    // TODO: Lite表示時以下を有効にする
    // productRatePlanName: 'オーナープライムサービス',
    // productName: 'グリーンサイトLite プライムサービス',
    productRatePlanName: '-',
    productName: '-',
    productRatePlanChargeKey: 'LitePrimeProject_Charge',
    rowSpan: 0,
    background: false
  },
  {
    productRatePlanName: '',
    productName: '',
    productRatePlanChargeKey: '',
    rowSpan: 1,
    background: true
  },
  {
    // TODO: Lite表示時以下を有効にする
    // productRatePlanName: 'グリーンサイトLiteプライムサービスセット',
    // productName: 'グリーンサイトLiteプライムサービスセット',
    productRatePlanName: '-',
    productName: '-',
    productRatePlanChargeKey: 'LiteSet_Charge',
    rowSpan: 1,
    background: false
  }
];

interface IServiceListData {
  service: string;
  name: string;
  amount: string | null;
  key: string | null;
  rowSpan: number;
  background: boolean;
}

/* eslint-disable react/no-unescaped-entities */
export default function ServiceContents({ state }: IProps): JSX.Element {
  const { Panel } = Collapse;

  const columns = [
    {
      dataIndex: 'service',
      title: 'サービス内容',
      key: `name${updateIndex()}`,
      colSpan: 2,
      render: (value: any, row: IServiceListData, index: any) => {
        const obj = {
          children: value,
          props: {} as any
        };
        obj.props.rowSpan = row.rowSpan;
        return obj;
      }
    },
    {
      dataIndex: 'name',
      title: 'サービス内容',
      key: `name${updateIndex()}`,
      className: 'serviceName',
      colSpan: 0
    },
    {
      dataIndex: 'amount',
      title: '契約状況',
      key: `amount${updateIndex()}`
    }
  ];

  function checkRenewContract(
    isNotify: boolean,
    isOutOfRange: boolean,
    isOwner: boolean,
    serviceName: string,
    startDate: string,
    endDate: string,
    subscriptionName: string
  ): any {
    let listItems: any = '';
    listItems = (
      <Button
        type="primary"
        id="renewBtn"
        className="renewBtn"
        onClick={() => onGetRenewProducList(serviceName, startDate, endDate, subscriptionName)}
        disabled={(!isNotify && !isOutOfRange) || !isOwner}
      >
        次年度更新はこちら
        <Icon style={{ fontSize: '18px' }} type="play-circle" />
      </Button>
    );
    return listItems;
  }

  function checkUpdateContract(
    isOutOfRange: boolean,
    isOwner: boolean,
    serviceName: string,
    startDate: string,
    endDate: string,
    renewedEndDate: string,
    subscriptionName: string,
    category: string
  ): any {
    let listItems: any = '';
    listItems = (
      <Button
        type="primary"
        id="updateBtn"
        className="changeBtn"
        onClick={() => onGoToContractUpdate(serviceName, startDate, endDate, renewedEndDate, subscriptionName, category)}
        disabled={isOutOfRange || !isOwner}
      >
        変更申込はこちら
        <Icon style={{ fontSize: '18px' }} type="play-circle" />
      </Button>
    );
    return listItems;
  }

  function checkIgnoreAmount(productRatePlanChargeKey: string): boolean {
    return Const.NonDisplayAmountList.includes(productRatePlanChargeKey);
  }

  // TODO: Lite表示時以下を削除する
  function isLiteProduct(productRatePlanChargeKey: string): boolean {
    return productRatePlanChargeKey.toLowerCase().includes('lite');
  }

  function renderSubscriptionList(): any {
    let category = '';
    let key = 0;
    let items: any = [];
    let serviceNameTitle = '';
    let serviceName = '';
    let subscriptionStartDate = '';
    let subscriptionEndDate = '';
    let subscriptionName = '';
    let nextCycleNotify = '';
    let renewButton: any = '';
    const itemList: any = [];
    let renderPanelItems: any = [];
    let tempCategory = '';
    let tabKey = 1;

    // タブ表示内容
    const tabList: any[] = [];
    // サービスごとの重複防止チェック用リスト
    const serviceItemCheckList: { category: string }[] = [];
    // 契約全体の重複防止チェック用リスト
    const contractItemCheckList: { serviceName: string }[] = [];

    // subscriptionInfo（1件目：当月(または直近の契約)、2件目以降：履歴）
    state.subscriptionInfo.forEach((contract: IContractList) => {
      let dataSource: any = [];

      // 履歴はサービスサイクルをまたがった情報も入るため、サービス名、契約期間、サブスクリプション名は最初のみ入れる
      // 同時期に複数のSubscriptionが存在するようになったら修正が必要
      if (!serviceName) {
        serviceNameTitle =
          contract.serviceName +
          '（現行サービスサイクル：' +
          termFormatter(contract.subscriptionStartDate, contract.subscriptionEndDate) +
          '）';
        serviceName = contract.serviceName;
        subscriptionStartDate = contract.subscriptionStartDate;
        subscriptionEndDate = contract.subscriptionEndDate;
        subscriptionName = contract.subscriptionName;
      }

      // サービスサイクルをまたがった情報の場合は次期サービスサイクルの旨を表示
      if (subscriptionStartDate !== contract.subscriptionStartDate) {
        nextCycleNotify =
          '* 次期サービスサイクル（' + termFormatter(contract.subscriptionStartDate, contract.subscriptionEndDate) + '）の情報';
      } else {
        nextCycleNotify = ' ';
      }

      const tabTitle = moment(contract.targetDate).format('YYYY/MM') + 'を参照';

      // 表示内容リスト
      const renderPanel: any = [];

      // Notificationのリストから対象ServiceNameの通知可否を取得する
      const notification = state.notifyInfo.find(n => n.serviceName === serviceName);
      const isOutOfRange = notification ? notification.isOutOfRange : false;
      const isNotify = notification ? notification.isNotify : false;
      const isOwner = state.accountInfo.companyType === '1';

      renewButton = checkRenewContract(
        isNotify,
        isOutOfRange,
        isOwner,
        serviceName,
        subscriptionStartDate,
        subscriptionEndDate,
        subscriptionName
      );

      contract.subscriptionList.forEach((subscription: ISubscriptionList, index: number) => {
        //オーナー・ユーザー階層
        category = subscription.category;
        const headerTitle = category + 'サービス';
        key = index;

        ProductPlan.forEach(i => {
          if (i.background === false) {
            subscription.product.forEach((product: IPlans) => {
              if (product.productRatePlanChargeKey === i.productRatePlanChargeKey) {
                if (tempCategory && tempCategory !== category) {
                  dataSource = [];
                }

                //各商品
                tempCategory = subscription.category;

                const data: IServiceListData = {
                  service: i.productRatePlanName,
                  name: i.productName,
                  amount: null,
                  key: `subscriptionId${updateIndex()}`,
                  rowSpan: i.rowSpan,
                  background: false
                };

                if (product.contractStatus) {
                  if (checkIgnoreAmount(product.productRatePlanChargeKey)) {
                    data.amount = '-';
                  } else {
                    data.amount = product.contractStatusForDisplay;
                  }
                } else {
                  // 契約がないプロダクト
                  data.amount = '-';
                }
                // TODO: Lite表示時以下を削除
                if (isLiteProduct(product.productRatePlanChargeKey)) {
                  data.amount = '-';
                }
                dataSource.push(data);
              }
            });
          } else {
            // グレー帯部分
            const data: IServiceListData = {
              service: i.productRatePlanName,
              name: i.productName,
              amount: '',
              key: `subscriptionId${updateIndex()}`,
              rowSpan: i.rowSpan,
              background: true
            };
            dataSource.push(data);
          }
        });

        tabList.push(
          <Tabs.TabPane
            tab={
              <span>
                <span className="cycleTab">{tabTitle}</span>
                <span>
                  <Icon type="profile" style={{ fontSize: '20px', margin: '0px 0px 5px 10px', verticalAlign: 'middle' }} />
                </span>
              </span>
            }
            key={(tabKey++).toString()}
          >
            <div className="nextCycle">{nextCycleNotify}</div>
            <Table
              className="serviceListTable"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              rowClassName={(record: IServiceListData, index: any): string => {
                return record.background ? 'gray' : '';
              }}
            />
          </Tabs.TabPane>
        );

        renderPanelItems = (
          <Panel
            header={headerTitle}
            key={key}
            extra={checkUpdateContract(
              isOutOfRange,
              isOwner,
              serviceName,
              subscriptionStartDate,
              subscriptionEndDate,
              contract.subscriptionEndDate,
              subscriptionName,
              category
            )}
          >
            下のタブをクリックすることで該当月時点での契約情報が参照できます。
            <Tabs defaultActiveKey="1" type="card">
              {tabList}
            </Tabs>
          </Panel>
        );

        // 重複判定（カテゴリが複数になる場合を想定）
        const sameRenderIndex = serviceItemCheckList.findIndex(s => s.category === category);

        // 異なるカテゴリの場合はリストに追加
        // 同じ場合は要素を入れ替え
        if (sameRenderIndex >= 0) {
          renderPanel[sameRenderIndex] = renderPanelItems;
        } else {
          serviceItemCheckList.push({ category });
          renderPanel.push(renderPanelItems);
        }
      });

      items = (
        <Card className="oneService" title={serviceNameTitle} extra={renewButton} key={`card${updateIndex()}`}>
          {/* <div className="presentCycle">{presentCycle}</div>
          <div className="nextCycle">{nextCycle}</div> */}
          <Collapse accordion defaultActiveKey={'0'}>
            {renderPanel}
          </Collapse>
        </Card>
      );

      // 重複判定（サービスが複数になる場合を想定）
      const sameServiceRenderIndex = contractItemCheckList.findIndex(c => c.serviceName === serviceName);

      // 異なるサービスの場合はリストに追加
      // 同じ場合は要素を入れ替え
      if (sameServiceRenderIndex >= 0) {
        itemList[sameServiceRenderIndex] = items;
      } else {
        contractItemCheckList.push({ serviceName });
        itemList.push(items);
      }
    });

    return itemList;
  }

  return (
    <div className="serviceContents">
      <h2>
        <span id="serviceContents">契約内容</span>
      </h2>

      {state.subscriptionInfo && state.subscriptionInfo.length > 0 ? renderSubscriptionList() : <h3>契約がありません。</h3>}
    </div>
  );
}
