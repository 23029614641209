import React from 'react';
import { Row, Button } from 'antd';
import styled from 'styled-components';
import { navigationTo } from '../../domain/middleware';
import history from '../../domain/history';

const StyledRow = styled(Row)`
  height: 80vh;
  flex-direction: column;
  flex-wrap: wrap;
`;

/* eslint-disable react/no-unescaped-entities */
export default function CompletedPage(): JSX.Element {
  const msg = history.location.state.msg;
  return (
    <StyledRow type="flex" justify="center" align="middle">
      <h2>{msg}を実行しました。</h2>
      <h2>変更内容についてはトップ画面下部の請求書の一覧からご確認ください。</h2>
      <Button className="goBackBtn" onClick={() => navigationTo('back')}>
        トップ画面に戻る
      </Button>
    </StyledRow>
  );
}
