import React from 'react';
import { CompanySearchForm } from '../../components/admin/companySearch/searchForm';
import SourceCompanySummary from '../../components/admin/transferCreditBalance/sourceCompanySummary';
import SearchResult from '../../components/admin/transferCreditBalance/searchResult';

/* eslint-disable react/no-unescaped-entities */
export default function TransferCreditBalancePage(props: any): JSX.Element {
  return (
    <>
      {!props.state.loading && (
        <>
          <SourceCompanySummary />
          <CompanySearchForm pageId={'accountingTransfer'} />
          <SearchResult windowHeight={props.windowHeight} windowWidth={props.windowWidth} />
        </>
      )}
    </>
  );
}
