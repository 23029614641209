import './customerRemark.css';
import React, { useState } from 'react';
import { getState } from '../../../domain/store';
import { Col, Table, Input } from 'antd';

export default function CustomerRemark(): JSX.Element {
  const [remarkText, setRemarkText] = useState('');
  const { updateContract } = getState().updateForm;

  const columns = [
    {
      title: 'お客様用備考',
      dataIndex: 'notice',
      colSpan: 2,
      width: '50%',
      render: (value: any) => {
        const obj = {
          children: value
        };
        return obj;
      }
    },
    {
      dataIndex: 'textArea',
      colSpan: 0,
      width: '50%',
      render: (value: any) => {
        const obj = {
          children: value
        };
        return obj;
      }
    }
  ];

  // 「\」「"」「“」「”」はwordの制御文字扱いのため置換する
  const RESTRICTED_CHARACTERS = [
    { restrict: /\\/g, replace: '￥' },
    { restrict: /"/g, replace: "'" },
    { restrict: /“/g, replace: "'" },
    { restrict: /”/g, replace: "'" }
  ];

  const replaceRestrictedCharcter = (value: string): string => {
    let str = value;
    for (const rc of RESTRICTED_CHARACTERS) {
      str = str.replace(rc.restrict, rc.replace);
    }
    return str;
  };

  const contents = [
    {
      notice: (
        <div>
          <div>入力された内容が請求書のお客様用備考欄に設定されます。</div>
          <div>※最大文字数255文字</div>
        </div>
      ),
      textArea: (
        <div>
          <Input.TextArea
            maxLength={255}
            rows={4}
            onChange={event => {
              const value = replaceRestrictedCharcter(event.target.value);
              setRemarkText(value);
              updateContract.customerRemark = value;
            }}
            className="customerRemarkForm"
            value={remarkText}
          />
        </div>
      )
    }
  ];

  return (
    <div>
      <div className="customerRemarkTable">
        <Col span={22}>
          <Table columns={columns} dataSource={contents} pagination={false} bordered />
        </Col>
      </div>
    </div>
  );
}
