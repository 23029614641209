import React from 'react';
import { Table, Button } from 'antd';
import { IProps, IUserContractStatusDetail } from '../../../domain/model';
import './userContractStatusDetail.css';
import { jpyFormater, convertStatus, convertDataClass } from '../../../domain/common';
import { navigationTo } from '../../../domain/middleware';

export default function UserContractStatusDetail({ state }: IProps): JSX.Element {
  const columns = [
    {
      title: 'No',
      dataIndex: 'seqNo',
      className: 'right',
      fixed: true,
      width: 50
    },
    {
      title: '行番号',
      dataIndex: 'lineNo',
      className: 'right',
      fixed: true,
      width: 60
    },
    {
      title: '企業ID',
      dataIndex: 'companyId',
      fixed: true,
      width: 120
    },
    {
      title: '企業名',
      dataIndex: 'companyName',
      fixed: true,
      width: 200
    },
    {
      title: 'ステータス',
      dataIndex: 'statusDisp',
      className: 'center',
      width: 120
    },
    {
      title: 'エラーメッセージ',
      dataIndex: 'errorMessage'
    },
    {
      title: 'データ種別',
      dataIndex: 'dataClassificationDisp',
      className: 'center',
      width: 90
    },
    {
      title: '請求書番号',
      dataIndex: 'invoiceNumber'
    },
    {
      title: '前請求書番号',
      dataIndex: 'previousInvoiceNumber'
    },
    {
      title: '請求日',
      dataIndex: 'invoiceDate',
      className: 'right',
      width: 95
    },
    {
      title: '支払期日',
      dataIndex: 'dueDate',
      className: 'right',
      width: 95
    },
    {
      title: 'サービスサイクル',
      dataIndex: 'serviceCycle',
      className: 'center',
      width: 150
    },
    {
      title: '初期設定料金',
      dataIndex: 'initialChargeDisp',
      className: 'right'
    },
    {
      title: '基本ID利用料',
      dataIndex: 'idUsageChargeDisp',
      className: 'right'
    },
    {
      title: 'ID追加数',
      dataIndex: 'idCount',
      className: 'right'
    },
    {
      title: '代行登録料',
      dataIndex: 'agentCharge',
      width: 90
    },
    {
      title: '代行企業数',
      dataIndex: 'companyCount',
      width: 90
    },
    {
      title: '一人親方数',
      dataIndex: 'oneManCount',
      width: 90
    },
    {
      title: '代行登録期間(月数)',
      dataIndex: 'term'
    },
    {
      title: 'アカウント名',
      dataIndex: 'accountNumber'
    },
    {
      title: 'サブスクリプション名',
      dataIndex: 'subscriptionNumber',
      width: 160
    },
    {
      title: '請求実行ID',
      dataIndex: 'billRunId'
    }
  ];

  interface IDataSource extends IUserContractStatusDetail {
    seqNo: number;
    statusDisp: string;
    dataClassificationDisp: string;
    initialChargeDisp: string;
    idUsageChargeDisp: string;
  }
  const dataSource: IDataSource[] = [];
  let seqNo = 0;
  state.userContractStatus.detailList.forEach(e => {
    dataSource.push({
      ...e,
      seqNo: ++seqNo,
      statusDisp: convertStatus(e.status),
      dataClassificationDisp: convertDataClass(e.dataClassification),
      initialChargeDisp: jpyFormater(e.initialCharge),
      idUsageChargeDisp: jpyFormater(e.idUsageCharge)
    });
  });
  return (
    <>
      <h3>請求データ アップロードステータス 明細</h3>
      <Table
        className={'commonTable'}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 3000, y: 400 }}
        rowKey={'lineNo'}
        pagination={false}
        size={'small'}
      />
      <Button className="backBtn" onClick={() => navigationTo('userContractStatus')}>
        戻る
      </Button>
    </>
  );
}
