import React, { useState } from 'react';
import { Modal, Descriptions, Divider, Checkbox } from 'antd';
import { getState } from '../../../domain/store';
import * as common from '../../../domain/common';
import { onCloseUpdateRefundModal, onUpdateRefundInfo, onError, onGetToken, onClearToken } from 'domain/middleware';

export default function UpdateRefundModal(props: any): JSX.Element {
  const summary = getState().accountingSummary;
  const refundModalVisible = getState().updateRefundModal.modalVisible;
  const createdDate = getState().updateRefundModal.targetRefundCreatedDate;
  const paymentAmount = getState().updateRefundModal.targetRefundPaymentAmount;
  const refundInfo = getState().updateRefundModal.refundInfo;
  const isAdminMCDPUser = getState().loginInfo.managerFlag === '6';
  // eslint-disable-next-line
  const [isCompleted, setIsCompleted] = useState(refundInfo.isCompleted);
  const [okButtonDisabled, setOkButtonDisabled] = useState(true);

  const onChange = (e: any): void => {
    setOkButtonDisabled(!e.target.checked);
    setIsCompleted(e.target.checked);
  };

  async function confirmModal(): Promise<void> {
    // ワンタイムトークン取得
    await onGetToken();

    function confirmContent(): JSX.Element {
      return (
        <>
          <Descriptions size="small" layout="vertical" bordered column={5}>
            <Descriptions.Item label="企業ID">{summary.companyId}</Descriptions.Item>
            <Descriptions.Item label="企業名">{summary.companyName}</Descriptions.Item>
            <Descriptions.Item label="返金処理日付">{common.yyyymmddSlashFormatter(createdDate)}</Descriptions.Item>
            <Descriptions.Item label="返金額" span={2}>
              {common.jpyFormater(-1 * paymentAmount)}
            </Descriptions.Item>
            <Descriptions.Item label="Galileopt取引先コード">{refundInfo.galileoptClientCode}</Descriptions.Item>
            <Descriptions.Item label="返金理由" span={4}>
              {refundInfo.reason}
            </Descriptions.Item>
          </Descriptions>
        </>
      );
    }
    const onUpdateRefundInfoAsync = async (): Promise<void> => {
      try {
        onUpdateRefundInfo(refundInfo.refundId, true);
      } catch (err) {
        onError(err);
      }
    };
    Modal.confirm({
      title: '下記の返金を完了とします。',
      centered: true,
      content: confirmContent(),
      width: 800,
      okText: '返金完了を確定',
      cancelText: '戻る',
      onOk: onUpdateRefundInfoAsync,
      onCancel: onClearToken
    });
  }
  return (
    <Modal
      title="返金確定"
      width={600}
      visible={refundModalVisible}
      style={{ top: 20 }}
      okText={'内容を確認'}
      cancelText={'戻る'}
      onOk={() => {
        confirmModal();
      }}
      okButtonProps={{ disabled: !isAdminMCDPUser || okButtonDisabled }}
      onCancel={() => {
        onCloseUpdateRefundModal();
      }}
    >
      <Descriptions size="small" layout="vertical" bordered column={5}>
        <Descriptions.Item label="企業ID">{summary.companyId}</Descriptions.Item>
        <Descriptions.Item label="企業名">{summary.companyName}</Descriptions.Item>
        <Descriptions.Item label="返金処理日付">{common.yyyymmddSlashFormatter(createdDate)}</Descriptions.Item>
        <Descriptions.Item label="返金額" span={2}>
          {common.jpyFormater(-1 * paymentAmount)}
        </Descriptions.Item>
        <Descriptions.Item label="Galileopt取引先コード">{refundInfo.galileoptClientCode}</Descriptions.Item>
        <Descriptions.Item label="返金理由" span={4}>
          {refundInfo.reason}
        </Descriptions.Item>
      </Descriptions>
      <Divider></Divider>
      <Checkbox disabled={refundInfo.isCompleted} onChange={onChange} defaultChecked={refundInfo.isCompleted}>
        {'返金確定'}
      </Checkbox>
    </Modal>
  );
}
