import React from 'react';
import { IOneProduct, ISavedUserInputList, IProductAmount } from '../../../domain/model';
import { jpyFormater } from '../../../domain/common';
import ToolTipMessage from '../toolTip/toolTipMessage';
import * as Const from '../../../domain/const';

/* eslint-disable complexity */
export const renderToolTip = (text: any, product: any, isRenewed?: boolean): any => {
  if (!product.contractStatus && Const.TOOLTIP_SHOW_LIST.includes(product.productRatePlanChargeKey)) {
    return (
      <ToolTipMessage message="このサービスの契約を行う場合には、MCデータプラスの営業担当者に個別にご連絡ください。">{text}</ToolTipMessage>
    );
  } else if (Const.TOOLTIP_RENEWED_SHOW_LIST.includes(product.productRatePlanChargeKey)) {
    if (!product.contractStatus && product.isReserved) {
      return <ToolTipMessage message="契約がすでに予定されているため、契約を申し込むことはできません。">{text}</ToolTipMessage>;
    } else if (!product.contractStatus && isRenewed) {
      return <ToolTipMessage message="次年度更新がすでに行われているため、この契約を申し込むことはできません。">{text}</ToolTipMessage>;
    } else {
      return text;
    }
  } else if (!product.contractStatus && product.productRatePlanChargeKey === Const.DATALINK_CHARGE) {
    return <ToolTipMessage message="システム連携サービスの利用料は初期設定料金を選択されますと自動で選択されます。">{text}</ToolTipMessage>;
  } else {
    return text;
  }
};

export const renderPrices = (
  productRatePlanChargeKey: string,
  subtotal: IProductAmount[],
  savedUserInputList: ISavedUserInputList[],
  newCourseData?: IOneProduct
): any => {
  let tempValue = 'ー';
  if (!subtotal || subtotal.length <= 0 || !savedUserInputList || savedUserInputList.length <= 0) {
    return tempValue;
  }

  // 除外された入力データリスト（コース変更で対象外判定されたもの）
  const removedInputList = savedUserInputList.filter(
    s => s.product.productRatePlanChargeKey === productRatePlanChargeKey && s.changeCategory === 'remove'
  );

  let targetAmountInfo: IProductAmount[] = [];
  if (
    removedInputList.length > 0 &&
    newCourseData &&
    newCourseData.productRatePlanChargeKey &&
    newCourseData.productRatePlanChargeKey.startsWith('Project_') &&
    newCourseData.productRatePlanChargeKey.endsWith('Charge')
  ) {
    // 対象のプロダクトが除外指定されていて、新コースがプロジェクト利用料（コース）の場合
    // 新しいコースに相当する小計データを取得
    targetAmountInfo = subtotal.filter(st => st.productRatePlanChargeKey === newCourseData.productRatePlanChargeKey);
  } else {
    // それ以外の場合は、指定のキーの小計データを取得
    targetAmountInfo = subtotal.filter(st => st.productRatePlanChargeKey === productRatePlanChargeKey);
  }

  if (targetAmountInfo && targetAmountInfo.length > 0) {
    tempValue = jpyFormater(targetAmountInfo[0].amount);
  }

  return tempValue;
};

export const renderToolTipCharge = (text: any, disabled: boolean): any => {
  if (disabled) {
    return <ToolTipMessage message="Bコース以上をご契約の場合、追加申し込みはできません。">{text}</ToolTipMessage>;
  } else {
    return text;
  }
};
