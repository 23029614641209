import * as React from 'react';
import './footer.css';

export default function Footer({ active }: any): JSX.Element {
  function renderContents(): any {
    let tempValue: any = '';
    if (!active) {
      tempValue = <footer>Copyright (C) MC Data Plus, Inc. All Rights Reserved.</footer>;
    }
    return tempValue;
  }

  return <span>{renderContents()}</span>;
}
