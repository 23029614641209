import React from 'react';
import { Modal } from 'antd';
import { AuthCheck } from '../../domain/reducers';
import '../user/modal/confirmModal.css';

export default function showSessionTimeOutModal(): void {
  Modal.info({
    centered: true,
    icon: <img src="../Attention.png" alt="modalIcon" className="modalIcon"></img>,
    content: '一定時間操作が行われませんでした。操作をやり直してください。',
    keyboard: false,
    onOk() {
      AuthCheck('');
    }
  });
}
