import { setState, getState } from './store';
import * as Model from './model';
import { onGetUserContractUnpaidInvoiceData } from 'domain/middleware';
import { message, Modal } from 'antd';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import history from './history';
import { yyyymmddFormatter, downloadInvoiceFile, blobToText, downloadIdInvoiceZipFile, downloadFile } from './common';
import showSessionTimeOutModal from '../components/shared/sessionTimeOutModal';
import successModal from '../components/admin/modal/successModal';
import accountingSuccessModal from '../components/admin/accounting/successModal';
import transferSuccessModal from '../components/admin/transferCreditBalance/successModal';
import invoiceModal from '../components/admin/accounting/invoiceModal';
import acceptDeletionModal from '../components/user/invoiceDelete/successModal';
import deletionProceededModal from '../components/user/invoiceDelete/proceededModal';
import moment, { Moment } from 'moment';
import { ROWS_PER_PAGE } from './utils/paginationUtil';
import { onRefreshSearchUnpaidInvoiceData, onGetCustomerInfo, onGetCustomerInfoWithAdminAuth } from './middleware';

message.config({
  top: 30,
  duration: 1.5
});

// 起動環境によってAPIのエンドポイント切り替える
const baseURL = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_INVOICE_BACKEND_API_KEY;

const myHttpClient = axios.create({
  withCredentials: true,
  headers: {
    'x-api-key': apiKey,
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache'
  }
});

/**
 * 「ローディング」更新リデューサー
 * @param loading ローディング
 */
export function isLoading(loading: boolean): void {
  const currentState = getState();
  currentState.loading = loading;
  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * ワンタイムトークン取得
 */
export async function getToken(): Promise<void> {
  await myHttpClient.get(baseURL + '/token');
}

/**
 * ワンタイムトークン削除
 */
export async function clearToken(): Promise<void> {
  await myHttpClient.delete(baseURL + '/token');
  const loading = false;
  setState((state: Model.IState) => ({
    ...state,
    loading
  }));
}

/**
 * 「契約更新フォーム」カレンダー変更後リフレッシュリデューサー
 */
export function refreshPreviewData(): any {
  const currentState = getState();
  currentState.updateForm.subtotal = [];
  currentState.updateForm.total = 0;
  currentState.previewForm.products = [];
}

/**
 * 「契約更新フォーム」リフレッシュリデューサー
 */
export function refreshContractData(): void {
  const currentState = getState();
  currentState.updateForm.subtotal = [];
  currentState.updateForm.total = 0;
  currentState.updateForm.remainTerm = '';
  currentState.updateForm.savedUserInputList = [];
  currentState.updateForm.relatedProductList = [];
  currentState.updateForm.contractEffectiveDate = '';
  currentState.updateForm.newCourse = {
    productId: '',
    productRatePlanId: '',
    productRatePlanChargeId: '',
    subscriptionId: '',
    ratePlanId: '',
    ratePlanChargeId: '',
    amount: 0,
    salesUnit: 0,
    uom: '',
    productName: '',
    productRatePlanName: '',
    unitCost: 0,
    contractStatus: false,
    initialSetUp: false,
    productRatePlanChargeKey: ''
  };
  currentState.updateForm.updateContract.products = [];
  currentState.previewForm.targetDate = '';
  currentState.previewForm.initialTerm = '';
  currentState.previewForm.products = [];
  currentState.productList.productList = [];
}

/**
 * ユーザー情報リフレッシュリデューサー
 */
export function refreshUserInfo(): void {
  const currentState = getState();
  currentState.accountInfo.account = '';
  currentState.pageTitle = '企業検索';
  isLoading(false);
  history.push('/admin/companySearch');
}

/**
 * 管理者用リフレッシュリデューサー
 * refreshContractData(),refreshUserInfo()
 */
export function refreshAdmin(): void {
  refreshContractData();
  refreshUserInfo();
}

/**
 * 「顧客情報（レスポンス）」更新リデューサー
 */
export function getCustomerInfo(fromCallback = false): void {
  myHttpClient
    .get(baseURL + '/init')
    .then((response: any) => {
      const pageId = '/user/top';
      const pageTitle = '契約管理';
      const loading = false;

      if (response.accountInfo) {
        const accountInfo = response.accountInfo;
        const subscriptionInfo = response.subscriptionInfo.contract;
        const unpaidInvoiceList = response.unpaidInvoiceList;
        const completedInvoiceList = response.completedInvoiceList;
        const notifyInfo = response.notifyInfo;
        setState((state: Model.IState) => ({
          ...state,
          pageId,
          pageTitle,
          loading,
          accountInfo,
          subscriptionInfo,
          unpaidInvoiceList,
          completedInvoiceList,
          notifyInfo
        }));
        if (fromCallback) {
          history.replace('/user/top');
        } else {
          history.push('/user/top');
        }
      } else {
        history.push({
          pathname: '/cantUseCompanyPageError',
          state: {
            msg: response.message
          }
        });
      }
    })
    .catch(() => {
      return;
    });
}

/**
 * トップ画面 請求書ページングoffset保存リデューサー
 * @param searchValues 企業検索条件
 */
export function setInvoiceListOffset(offset: number): void {
  const currentState = getState();
  currentState.invoiceListPagination.offset = offset;
}

/**
 * 「請求書」取得リデューサー
 *  @param invoiceId 請求書ID
 *  @param invoiceNumber 請求書番号
 */
export function getInvoicePDF(invoiceId: string, invoiceNumber: string): void {
  // let fileName = '';
  // const invoiceDate = moment(invoiceCreatedDate).format('YYYYMMDD');
  // if (companyId && invoiceNumber) {
  //   fileName = companyId + '_' + invoiceDate + '_' + invoiceNumber;
  // } else {
  //   fileName = 'Invoice_' + invoiceDate + '.pdf';
  // }

  myHttpClient
    .get(baseURL + '/invoices-file/' + invoiceId, {
      responseType: 'blob'
    })
    .then((response: any) => {
      blobToText(response)
        .then((result: any) => {
          if (result.appStatus === 'pdfError') {
            history.push({
              pathname: '/failedCreatingPDFError',
              state: {
                msg: result.contents.message
              }
            });
            return;
          }

          if (result.data.readStatus === 'success') {
            downloadInvoiceFile(response, invoiceNumber);
          } else {
            history.push({
              pathname: '/failedCreatingPDFError',
              state: {
                msg: result.data.contents.message
              }
            });
          }
        })
        .catch(function(error) {
          history.push('/systemError');
        });
    });
}

/**
 * 契約変更、次年度更新申し込み時PDF取得リデューサー
 *  @param response 請求書IDと請求書作成日が含まれるレスポンス
 *  @param msg 契約完了時のメッセージ
 */
export function downloadPdf(response: any, msg: string): void {
  // PDF作成後の即時PDFダウンロードを止める
  // const invoiceId = response.invoiceId;
  // const invoiceNumber = response.invoiceNumber as string;
  // getInvoicePDF(invoiceId, invoiceNumber);
  const loading = false;
  setState((state: Model.IState) => ({
    ...state,
    loading
  }));
  history.push({
    pathname: '/user/contract/completed',
    state: {
      msg: msg
    }
  });
}

/*-------------契約追加・更新---------------------*/

/**
 * 基本コース取得用
 * @param productKey プロダクトキー
 */

export async function getBasicCourse(productKey: string): Promise<void> {
  const currentState = getState();
  myHttpClient
    .get(baseURL + `/products/courses/${productKey}`)
    .then((response: any) => {
      if (productKey === 'Project_Charge') {
        currentState.courseInfo.basicProjectList = response.productsCoursesList;
      } else if (productKey === 'PrimeProject_Charge') {
        currentState.courseInfo.primeProjectList = response.productsCoursesList;
      } else if (productKey === 'LiteProject_Charge') {
        currentState.courseInfo.liteProjectList = response.productsCoursesList;
      } else if (productKey === 'LitePrimeProject_Charge') {
        currentState.courseInfo.litePrimeProjectList = response.productsCoursesList;
      } else if (productKey === 'LiteSet_Charge') {
        currentState.courseInfo.liteSetList = response.productsCoursesList;
      }
    })
    .catch(() => {
      history.push('/systemError');
    });
}

/**
 * 契約追加・更新ページへの遷移用リデューサー
 * @param serviceName サービス名
 * @param startDate 契約有効期間開始日
 * @param endDate 契約有効期間終了日
 * @param renewedEndDate 現在契約有効期間終了日
 * @param subscriptionName サブスクリプション名
 * @param category カテゴリ
 */
export function goToContractUpdate(
  serviceName: string,
  startDate: string,
  endDate: string,
  renewedEndDate: string,
  subscriptionName: string,
  category: string
): void {
  refreshContractData();
  const currentState = getState();
  currentState.pageId = '/user/contract/update';
  currentState.pageTitle = '契約変更';
  currentState.productList.currentDate = yyyymmddFormatter(new Date());
  currentState.productList.subscriptionName = subscriptionName;
  currentState.productList.productList = [];

  const loading = false;

  setState((state: Model.IState) => ({
    ...state,
    loading
  }));
  history.push({
    pathname: '/user/contract/update',
    state: {
      serviceName: serviceName,
      subscriptionName: subscriptionName,
      startDate: startDate,
      endDate: endDate,
      renewedEndDate: renewedEndDate,
      category: category
    }
  });
}

/**
 * 契約追加・更新用
 * 「商品リスト（レスポンス）」リデューサー
 * @param serviceName サービス名
 * @param productList 商品リスト
 * @param targetDate 対象日付
 */

export async function getUpdateProducList(serviceName: string, category: string, targetDate: string): Promise<void> {
  const currentState = getState();
  const currentDate = currentState.productList.currentDate;
  const subscriptionName = currentState.productList.subscriptionName;
  currentState.productList.currentDate = currentDate;
  currentState.productList.subscriptionName = subscriptionName;
  await getBasicCourse('Project_Charge');
  await getBasicCourse('PrimeProject_Charge');
  await getBasicCourse('LiteProject_Charge');
  await getBasicCourse('LitePrimeProject_Charge');
  await getBasicCourse('LiteSet_Charge');
  myHttpClient
    .get(baseURL + '/subscriptions-change', {
      params: {
        serviceName: serviceName,
        category: category,
        targetDate: targetDate
      }
    })
    .then((response: any) => {
      currentState.productList = response;
      const loading = false;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    });
}

/**
 * 「契約追加」リデューサー
 */

export function updateContract(): void {
  const currentState = getState();
  currentState.loading = true;
  myHttpClient
    .put(baseURL + '/subscriptions-change', currentState.updateForm.updateContract)
    .then((response: any) => {
      if (response) {
        downloadPdf(response, '契約変更');
      }
    })
    .catch(() => {
      return;
    });
}

/*-------------契約追加・更新---------------------*/

/*-------------契約次年度更新---------------------*/

/**
 * 契約追加・更新用
 * 「商品リスト（レスポンス）」リデューサー
 * @param serviceName サービス名
 * @param startDate 契約有効期間開始日
 * @param endDate 契約有効期間終了日
 * @param subscriptionName サブスクリプション名
 */

export async function getRenewProducList(serviceName: string, startDate: string, endDate: string, subscriptionName: string): Promise<void> {
  refreshContractData();
  const FormatEndDate = yyyymmddFormatter(endDate);
  const currentState = getState();

  await getBasicCourse('Project_Charge');
  await getBasicCourse('PrimeProject_Charge');
  await getBasicCourse('LiteProject_Charge');
  await getBasicCourse('LitePrimeProject_Charge');
  await getBasicCourse('LiteSet_Charge');
  myHttpClient
    .get(baseURL + '/subscriptions-renew', {
      params: {
        serviceName: serviceName
      }
    })
    .then((response: any) => {
      currentState.pageId = '/user/contract/renew';
      currentState.pageTitle = '次年度更新';
      currentState.productList = response;
      currentState.updateForm.contractEffectiveDate = yyyymmddFormatter(moment(FormatEndDate).add(1, 'days'));
      currentState.updateForm.remainMonths = '12';
      const loading = false;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
      history.push({
        pathname: '/user/contract/renew',
        state: {
          serviceName: serviceName,
          subscriptionName: subscriptionName,
          startDate: startDate,
          endDate: endDate
        }
      });
    });
}

/**
 * 「契約追加」リデューサー
 */

export function renewContract(): void {
  const currentState = getState();
  currentState.loading = true;
  myHttpClient
    .put(baseURL + '/subscriptions-renew', currentState.updateForm.updateContract)
    .then((response: any) => {
      if (response) {
        downloadPdf(response, '次年度更新');
      }
    })
    .catch(() => {
      return;
    });
}

/*-------------契約次年度更新---------------------*/

/**
 * 「新しいコース」更新リデューサー
 * @param obj 新しい基本プロジェクトのコース
 */
export function setNewCourse(obj: Model.IOneProduct): void {
  const currentState = getState();
  currentState.updateForm.newCourse = obj;
  const updateForm = currentState.updateForm;
  setState((state: Model.IState) => ({
    ...state,
    updateForm
  }));
}

export function setPrimeCourse(obj: Model.IOneProduct): void {
  const currentState = getState();
  currentState.updateForm.primeCourse = obj;
  const updateForm = currentState.updateForm;
  setState((state: Model.IState) => ({
    ...state,
    updateForm
  }));
}

/**
 * 「契約残月」更新リデューサー
 *  @param term 契約更新の契約月数
 *  @param effectiveDate 契約開始日
 *  @param remainMonths 残りの月
 */
export function setTerm(term: string, effectiveDate: string, remainMonths: string): void {
  const currentState = getState();
  currentState.updateForm.remainTerm = term;
  currentState.updateForm.remainMonths = remainMonths;
  currentState.updateForm.contractEffectiveDate = effectiveDate;

  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 「見積もり」更新リデューサー
 * @param pageId ページID（renew : 契約更新、update : 契約変更）
 */
export function previewContract(pageId: string): void {
  const currentState = getState();
  myHttpClient.post(baseURL + '/subscriptions-calc/' + pageId, currentState.previewForm).then((response: any) => {
    const loading = false;
    // productsには料金ごとの小計額のリストが入る、合計額はtotalAmount
    currentState.updateForm.subtotal = response.products;
    currentState.updateForm.total = response.totalAmount;

    setState((state: Model.IState) => ({
      ...state,
      loading
    }));
  });
}

/*-----------------------------------Admin-------------------------------------------*/

/**
 * 「企業検索画面」リデューサー
 */
export function setAdminInfo(fromCallback = false): void {
  const currentState = getState();
  currentState.pageTitle = '企業検索';
  currentState.pageId = '/admin/companySearch';
  const loading = false;
  setState((state: Model.IState) => ({
    ...state,
    loading
  }));
  if (fromCallback) {
    history.replace('/admin/companySearch');
  } else {
    history.push('/admin/companySearch');
  }
}
/**
 * 「企業検索条件」保存リデューサー
 * @param searchValues 企業検索条件
 */
export function setSearchCondition(searchValues: any): void {
  const currentState = getState();
  currentState.searchCondition = searchValues;
}
/**
 * 「企業検索」 ページングoffset保存リデューサー
 * @param searchValues 企業検索条件
 */
export function setSearchOffset(offset: number): void {
  const currentState = getState();
  currentState.searchCompanyPagination.offset = offset;
}

/**
 * 「企業検索結果」更新リデューサー
 * @param params 企業検索条件
 */
export function searchCompany(params?: any, offset?: number): void {
  const currentState = getState();
  currentState.pageTitle = '企業検索';
  const condition = params || currentState.searchCondition;
  myHttpClient
    .post(baseURL + '/admin/companies', {
      ...condition,
      offset: offset || currentState.searchCompanyPagination.offset,
      limit: ROWS_PER_PAGE
    })
    .then((response: any) => {
      const loading = false;
      currentState.searchCompanyList.isGetSearchResult = true;
      currentState.searchCompanyList.companyList = response.companies;
      currentState.searchCompanyPagination.totalCount = response.totalCount;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    });
}

/**
 * 「超過企業」更新リデューサー
 */
export function searchOverChargeCompany(): void {
  const currentState = getState();
  myHttpClient.get(baseURL + '/admin/over-charge/companies').then((response: any) => {
    const loading = false;
    currentState.accountInfo.account = '';
    currentState.pageTitle = '超過請求';
    currentState.searchCompanyList.isGetSearchResult = true;
    currentState.searchCompanyList.companyList = response.companies;
    currentState.searchCompanyList.targetDate = response.targetDate;
    setState((state: Model.IState) => ({
      ...state,
      loading
    }));
  });
}

/**
 * 「超過数量追加」リデューサー
 * @param companyId
 * @param param
 */
export function updateOverCharge(companyId: string, param: Model.IOverChargeCompany): void {
  myHttpClient.put(baseURL + '/admin/over-charge/' + companyId, param).then((response: any) => {
    successModal();
    const loading = false;
    setState((state: Model.IState) => ({
      ...state,
      loading
    }));
  });
}

/**
 * 超過利用料情報リフレッシュリデューサー
 */
export function refreshOverChargeInfo(): void {
  const currentState = getState();
  searchOverChargeCompany();
  currentState.accountInfo.account = '';
  currentState.pageTitle = '超過請求';
  history.push('/admin/overCharge');
}

/**
 * 超過利用料情報リフレッシュリデューサー
 * refreshContractData(), refreshOverChargeInfo()
 */
export function refreshAdminOverCharge(): void {
  refreshContractData();
  refreshOverChargeInfo();
}

/**
 * 「顧客情報（レスポンス）」取得リデューサー
 * @param companyId 企業ID
 */
export function getCustomerInfoWithAdminAuth(companyId?: string): void {
  const currentState = getState();
  let url = baseURL + '/admin/init/';
  if (companyId) {
    url = url + companyId;
  }
  myHttpClient
    .get(url)
    .then((response: any) => {
      currentState.pageId = '/user/top';
      currentState.pageTitle = '契約管理';

      const loading = false;

      if (response.accountInfo) {
        currentState.accountInfo = response.accountInfo;
        currentState.subscriptionInfo = response.subscriptionInfo.contract;
        currentState.unpaidInvoiceList = response.unpaidInvoiceList;
        currentState.completedInvoiceList = response.completedInvoiceList;
        currentState.notifyInfo = response.notifyInfo;

        setState((state: Model.IState) => ({
          ...state,
          loading
        }));
        history.push('/user/top');
      } else {
        history.push({
          pathname: '/cantUseCompanyPageError',
          state: {
            msg: response.message
          }
        });
      }
    })
    .catch(() => {
      return;
    });
}

/**
 * 請求データアップロード画面リフレッシュリデューサー
 */
export async function refreshAdminUserContractUpload(): Promise<void> {
  const currentState = getState();
  currentState.pageTitle = '請求データアップロード';
  currentState.userContextUpload.invoiceFileList = [];
  const loading = false;
  await getToken();
  setState((state: Model.IState) => ({
    ...state,
    loading
  }));
  history.push('/admin/userContract/upload');
}

/**
 * 入金データアップロード画面リフレッシュリデューサー
 */
export async function refreshAdminUserPaymentUpload(): Promise<void> {
  const currentState = getState();
  currentState.pageTitle = '入金データアップロード';
  currentState.userContextUpload.paymentFileList = [];
  const loading = false;
  await getToken();
  setState((state: Model.IState) => ({
    ...state,
    loading
  }));
  history.push('/admin/userPayment/upload');
}

export function clearUserContractUploadInvoiceList(): void {
  const currentState = getState();
  currentState.pageTitle = '請求データアップロード';
  currentState.userContextUpload.invoiceFileList = [];
  const loading = false;

  setState((state: Model.IState) => ({
    ...state,
    loading
  }));

  history.push('/admin/userContract/upload');
}

export function clearUserContractUploadDeletionList(): void {
  const currentState = getState();
  currentState.pageTitle = '請求データアップロード';
  currentState.userContextUpload.deletionFileList = [];
  const loading = false;

  setState((state: Model.IState) => ({
    ...state,
    loading
  }));

  history.push('/admin/userContract/upload');
}

export function clearUserContractUploadPaymentList(): void {
  const currentState = getState();
  currentState.pageTitle = '入金データアップロード';
  currentState.userContextUpload.paymentFileList = [];
  const loading = false;

  setState((state: Model.IState) => ({
    ...state,
    loading
  }));

  history.push('/admin/userPayment/upload');
}

/**
 * ユーザー契約 請求・請求削除データ アップロード画面ファイルリストアップロードリデューサー
 */
export function uploadUserContractFile(invoiceFileList: File[], deletionFileList: File[]): void {
  const currentState = getState();
  const formData = new FormData();

  // 請求データ
  invoiceFileList.forEach(file => {
    formData.append('invoiceFiles', new Blob([file], { type: 'application/octet-stream' }), encodeURIComponent(file.name));
  });

  // 請求削除データ
  deletionFileList.forEach(file => {
    formData.append('deletionFiles', new Blob([file], { type: 'application/octet-stream' }), encodeURIComponent(file.name));
  });

  myHttpClient
    .post(baseURL + '/admin/upload/invoice', formData)
    .then((response: any) => {
      currentState.pageTitle = '請求データアップロード';

      currentState.userContextUpload.invoiceFileList = [];
      currentState.userContextUpload.deletionFileList = [];

      message.success('アップロードが完了しました。', 3);

      setState((state: Model.IState) => ({
        ...state
      }));
      // トークン再取得
      getToken();
    })
    .catch(function(error) {
      // 不正なファイルがアップロードされた場合(400エラー)、メッセージを表示する
      if (axios.isAxiosError(error) && error.response && error.response.status === 400 && error.response.data) {
        message.error(error.response.data.contents.message);
        history.replace('/admin/userContract/upload');
        // トークン再取得
        getToken();
      }
    });
}

/**
 * ユーザー契約 入金データ アップロード画面ファイルリストアップロードリデューサー
 */
export function uploadUserPaymentFile(paymentFileList: File[]): void {
  const currentState = getState();
  const formData = new FormData();

  // 入金データ
  paymentFileList.forEach(file => {
    formData.append('paymentFiles', new Blob([file], { type: 'application/octet-stream' }), encodeURIComponent(file.name));
  });

  myHttpClient
    .post(baseURL + '/admin/upload/payment', formData)
    .then((response: any) => {
      currentState.pageTitle = '入金データアップロード';
      currentState.userContextUpload.paymentFileList = [];

      message.success('アップロードが完了しました。', 3);

      setState((state: Model.IState) => ({
        ...state
      }));
      // トークン再取得
      getToken();
    })
    .catch(function(error) {
      // 不正なファイルがアップロードされた場合(400エラー)、メッセージを表示する
      if (axios.isAxiosError(error) && error.response && error.response.status === 400 && error.response.data) {
        message.error(error.response.data.contents.message);
        history.replace('/admin/userPayment/upload');
        // トークン再取得
        getToken();
      }
    });
}

/**
 * ユーザー契約アップロード画面アップデートファイル追加リデューサー
 */

export function addUserContractInvoiceFile(file: File): void {
  const currentState = getState();
  const fileList: any[] = currentState.userContextUpload.invoiceFileList;
  currentState.userContextUpload.invoiceFileList = [...fileList, file];

  setState((state: Model.IState) => ({
    ...state
  }));
}

export function addUserContractDeletionFile(file: File): void {
  const currentState = getState();
  const fileList: any[] = currentState.userContextUpload.deletionFileList;
  currentState.userContextUpload.deletionFileList = [...fileList, file];

  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * ID追加請求書検索リデューサー
 */
export function searchAdminIdInvoice(): void {
  const currentState = getState();
  const loading = false;
  const targetDate = currentState.idInvoiceDownload.params.targetDate.format('YYYY-MM-DD');

  myHttpClient
    .get(baseURL + '/admin/userid/invoice', {
      params: {
        targetDate
      }
    })
    .then((response: any) => {
      currentState.idInvoiceDownload.idInvoiceList = response.records;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    });
}

/**
 * ID追加請求書ダウンロード画面リフレッシュリデューサー
 */
export function refreshAdminIdInvoiceDownload(): void {
  const currentState = getState();
  currentState.pageTitle = 'ID追加請求書';
  currentState.downloadForm.targetDate = '';
  if (!currentState.idInvoiceDownload.params.targetDate) {
    currentState.idInvoiceDownload.params.targetDate = moment().startOf('month');
  }

  searchAdminIdInvoice();
  history.push('/admin/idInvoiceDownload');
}

/**
 * 「ID追加請求書ダウンロード」更新リデューサー
 * @param targetDate 対象日付
 */
export function downloadZip(targetDate: string): void {
  const currentState = getState();
  currentState.downloadForm.targetDate = targetDate;
  const url = baseURL + '/admin/userid/invoice-download/' + targetDate;
  myHttpClient
    .get(url, {
      responseType: 'blob'
    })
    .then(function(response: any) {
      blobToText(response)
        .then(function(result: any) {
          if (result.data.readStatus === 'success') {
            downloadIdInvoiceZipFile(response, targetDate);
          }
          message.success('ダウンロードが完了しました。');
          refreshAdminIdInvoiceDownload();
        })
        .catch(function(error) {
          history.push('/systemError');
        });
    })
    .catch(function(error) {
      if (error.response.status === 500) {
        message.success('指定した日付のファイルは存在しません。');
        refreshAdminIdInvoiceDownload();
      }
    });
}

/**
 *  ID追加請求書検索パラメーターセットリデューサー
 */
export function setIdInvoiceParams(date?: Moment): void {
  const currentState = getState();
  if (date) {
    currentState.idInvoiceDownload.params.targetDate = date;
  } else {
    currentState.idInvoiceDownload.params.targetDate = moment().startOf('month');
  }
  setState((state: Model.IState) => ({
    ...state
  }));
}

export function addUserContractPaymentFile(file: File): void {
  const currentState = getState();
  const fileList: any[] = currentState.userContextUpload.paymentFileList;
  currentState.userContextUpload.paymentFileList = [...fileList, file];

  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 削除したファイルをリストから除去する（請求データ）
 */
export function removeInvoiceFile(file: any): void {
  const currentState = getState();
  currentState.userContextUpload.invoiceFileList = currentState.userContextUpload.invoiceFileList.filter(elem => {
    // 内部的にuidを管理しているためanyに変換（File型では参照できない）
    const fileAsData: any = elem;
    return fileAsData.uid !== file.uid;
  });

  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 削除したファイルをリストから除去する（請求削除データ）
 */
export function removeDeletionFile(file: any): void {
  const currentState = getState();
  currentState.userContextUpload.deletionFileList = currentState.userContextUpload.deletionFileList.filter(elem => {
    // 内部的にuidを管理しているためanyに変換（File型では参照できない）
    const fileAsData: any = elem;
    return fileAsData.uid !== file.uid;
  });

  setState((state: Model.IState) => ({
    ...state
  }));
}

/*-----------------------------------請求・入金管理画面-------------------------------------------*/

/**
 * 請求・入金管理画面 企業サマリ取得API
 * @param companyId
 */
export async function exeGetAccountingCompanySummary(companyId: string): Promise<Model.IAccountingCompanySummary> {
  const response: Model.IAccountingCompanySummary = await myHttpClient.get(baseURL + `/admin/accounting/summary/${companyId}`);
  return response;
}

/**
 * 請求・入金管理画面 企業明細取得API
 * @param companyId
 */
export async function exeGetAccountingCompanyDetail(
  companyId: string,
  targetDateFrom: string,
  targetDateTo: string,
  onlyInvoiceWithBalance: boolean,
  digest?: string,
  invoiceNumber?: string
): Promise<Model.IAccountingCompanyDetail[]> {
  const response: any = await myHttpClient.get(baseURL + `/admin/accounting/detail/${companyId}`, {
    params: { targetDateFrom, targetDateTo, onlyInvoiceWithBalance, digest, invoiceNumber }
  });

  return response.details as Model.IAccountingCompanyDetail[];
}

/**
 * ユーザー契約請求書情報取得API
 * @param invoiceNumber 請求書番号
 */
export async function exeGetUserContractInvoiceInfo(invoiceNumber: string): Promise<Model.IAccountingUserContractInvoice> {
  const response: any = await myHttpClient.get(baseURL + `/admin/accounting/user-contract/invoice/${invoiceNumber}`);

  return response;
}

/**
 * 請求・入金管理画面 返金実行API
 * @param companyId 企業ID
 * @param amount 返金額
 */
export async function exeRefund(companyId: string, amount: number, galileoptClientCode: string, reason: string): Promise<boolean> {
  const reqBody = {
    companyId,
    amount,
    galileoptClientCode,
    reason
  };
  return myHttpClient
    .post(baseURL + '/admin/accounting/refund', reqBody)
    .then(response => {
      return response ? true : false;
    })
    .catch(() => {
      return false;
    });
}
/**
 * 請求・入金管理画面 返金実行API
 * @param companyId 企業ID
 * @param invoices 請求書リスト
 */
export async function exeReconcile(companyId: string, invoices: { invoiceNumber: string }[]): Promise<boolean> {
  const reqBody = {
    companyId,
    invoices
  };
  return myHttpClient
    .post(baseURL + '/admin/accounting/reconcile', reqBody)
    .then(response => {
      return response ? true : false;
    })
    .catch(() => {
      return false;
    });
}

/**
 * 請求・入金管理 情報取得 リデューサー
 * @param companyId 企業ID
 * @param targetDateFrom 処理日付（開始）
 * @param targetDateTo 処理日付（終了）
 * @param onlyInvoiceWithBalance 未入金の請求書のみ取得
 * @param digest 摘要
 * @param invoiceNumber 請求書番号
 */
export async function getAccountingInfo(
  companyId: string,
  targetDateFrom: string,
  targetDateTo: string,
  onlyInvoiceWithBalance: boolean,
  digest: string,
  invoiceNumber?: string
): Promise<void> {
  const loading = false;
  const [summary, details] = await Promise.all([
    exeGetAccountingCompanySummary(companyId),
    exeGetAccountingCompanyDetail(companyId, targetDateFrom, targetDateTo, onlyInvoiceWithBalance, digest, invoiceNumber)
  ]);
  setState((state: Model.IState) => ({
    ...state,
    pageTitle: '請求・入金管理',
    loading,
    accountingSummary: summary,
    accountingDetailList: details,
    accountingSearchCondition: { companyId, targetDateFrom, targetDateTo, onlyInvoiceWithBalance, digest, invoiceNumber }
  }));
  history.push('/admin/accounting');
}

/**
 * 入金消込 対象請求書情報取得 リデューサー
 * @param companyId 企業ID
 */
export async function getReconcileModalInfo(companyId: string): Promise<void> {
  const loading = false;
  const details = await exeGetAccountingCompanyDetail(companyId, '', '', true);
  setState((state: Model.IState) => ({
    ...state,
    loading,
    reconcileTargetList: details,
    reconcileModalVisible: true
  }));
}

/**
 * 入金消込モーダル 表示非表示設定
 */
export function setReconcileModalVisble(visible: boolean): void {
  setState((state: Model.IState) => ({
    ...state,
    reconcileModalVisible: visible
  }));
}

/**
 * ユーザー契約請求書情報取得 リデューサー
 * @param invoiceNumber 請求書番号
 * @param invoiceAmount 請求額
 */
export async function getUserContractInvoiceInfo(invoiceNumber: string, invoiceAmount: number, dueDate: string): Promise<void> {
  const loading = false;
  const userContractInoviceInfo = await exeGetUserContractInvoiceInfo(invoiceNumber);
  setState((state: Model.IState) => ({
    ...state,
    loading,
    userContractInoviceInfo
  }));
  invoiceModal(invoiceAmount, dueDate);
}

/**
 * 請求・入金管理リフレッシュリデューサー
 */
export async function refreshAccountingInfo(): Promise<void> {
  const loading = false;
  const { companyId, targetDateFrom, targetDateTo, onlyInvoiceWithBalance, digest } = getState().accountingSearchCondition;
  const [summary, details] = await Promise.all([
    exeGetAccountingCompanySummary(companyId),
    exeGetAccountingCompanyDetail(companyId, targetDateFrom, targetDateTo, onlyInvoiceWithBalance, digest)
  ]);
  setState((state: Model.IState) => ({
    ...state,
    pageTitle: '請求・入金管理',
    loading,
    accountingSummary: summary,
    accountingDetailList: details,
    accountingSearchCondition: { companyId, targetDateFrom, targetDateTo, onlyInvoiceWithBalance, digest }
  }));
}

/**
 * 返金実行
 * @param companyId 企業ID
 * @param amount 返金額
 */
export async function postRefund(companyId: string, amount: number, galileoptClientCode: string, reason: string): Promise<void> {
  const result = await exeRefund(companyId, amount, galileoptClientCode, reason);
  if (result) {
    const loading = false;
    setState((state: Model.IState) => ({
      ...state,
      loading,
      refundModalVisible: false
    }));
    accountingSuccessModal();
  }
}

/**
 * 入金消込実行
 * @param companyId 企業ID
 * @param invoices 請求書リスト
 */
export async function postReconcile(companyId: string, invoices: { invoiceNumber: string }[]): Promise<void> {
  const result = await exeReconcile(companyId, invoices);
  if (result) {
    const loading = false;
    setState((state: Model.IState) => ({
      ...state,
      loading,
      reconcileModalVisible: false
    }));
    accountingSuccessModal();
  }
}

/**
 * 預り金付替リフレッシュリデューサー
 */
export function refreshTransfer(): void {
  const currentState = getState();
  currentState.pageTitle = '預り金付替';
  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 預り金付替実行リデューサー
 */
export function putTransferPayment(
  transferSourceCompanyId: string,
  transferDestinationCompanyId: string,
  amount: number,
  galileoptItems: { invoiceCompanyName: string; requestNo: string }
): void {
  myHttpClient
    .put(baseURL + '/admin/transfer-payment', { transferSourceCompanyId, transferDestinationCompanyId, amount, galileoptItems })
    .then((response: any) => {
      transferSuccessModal();
      const loading = false;
      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    })
    .catch(() => {
      return;
    });
}

/**
 * 預り金付替「企業検索条件」保存リデューサー
 * @param searchValues 企業検索条件
 */
export function setSearchConditionForTransfer(searchValues: any): void {
  const currentState = getState();
  currentState.accountingTransferSearchCondition = searchValues;
}

/**
 * 「企業検索」 ページングoffset保存リデューサー
 * @param searchValues 企業検索条件
 */
export function setSearchOffsetForTransfer(offset: number): void {
  const currentState = getState();
  currentState.companyListForTransferPagination.offset = offset;
}

/**
 * 預り金付替 企業検索リデューサー
 * @param params 企業検索条件
 */
export function searchCompanyForTransfer(params?: any, offset?: number): void {
  const currentState = getState();

  const condition = params || currentState.accountingTransferSearchCondition;
  myHttpClient
    .post(baseURL + '/admin/companies', {
      ...condition,
      offset: offset || currentState.companyListForTransferPagination.offset,
      limit: ROWS_PER_PAGE
    })
    .then((response: any) => {
      const loading = false;
      currentState.companyListForTransfer = response.companies;
      currentState.companyListForTransferPagination.totalCount = response.totalCount;
      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    });
}
/**
 * 預り金付替 リフレッシュリデューサー
 * @param params 企業検索条件
 */
export async function refreshSearchCompanyForTransfer(): Promise<void> {
  const { companyId } = getState().accountingSearchCondition;
  const summary = await exeGetAccountingCompanySummary(companyId);
  setState((state: Model.IState) => ({
    ...state,
    accountingSummary: summary
  }));
  searchCompanyForTransfer();
}
/**
 * 入金消込取消リデューサー
 */
export function postDeleteInvoicePayment(companyId: string, cbaId: string): void {
  myHttpClient
    .post(baseURL + '/admin/delete-invoice-payment', { companyId, cbaId })
    .then((response: any) => {
      accountingSuccessModal();
      const loading = false;
      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    })
    .catch(() => {
      return;
    });
}

/**
 * 明細 備考登録・更新リデューサー
 */
export function putRemark(detail: any, remark: string): void {
  const invoiceId = detail.invoiceId || '';
  const cbaId = detail.cbaId || '';
  myHttpClient
    .put(baseURL + '/admin/accounting/remark', { invoiceId, cbaId, remark })
    .then((response: any) => {
      accountingSuccessModal();
      const loading = false;
      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    })
    .catch(() => {
      return;
    });
}

/**
 * 削除したファイルをリストから除去する（入金データ）
 */
export function removePaymentFile(file: any): void {
  const currentState = getState();
  currentState.userContextUpload.paymentFileList = currentState.userContextUpload.paymentFileList.filter(elem => {
    // 内部的にuidを管理しているためanyに変換（File型では参照できない）
    const fileAsData: any = elem;
    return fileAsData.uid !== file.uid;
  });

  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 請求ステータス検索リデューサー
 */
export function searchAdminUserContractStatus(): void {
  const currentState = getState();
  const loading = false;
  const targetDateFrom = currentState.userContractStatus.params.targetDateFrom.format('YYYY-MM-DD');
  const targetDateTo = currentState.userContractStatus.params.targetDateTo.format('YYYY-MM-DD');

  myHttpClient
    .get(baseURL + '/admin/user-contract/status', {
      params: {
        targetDateFrom,
        targetDateTo
      }
    })
    .then((response: any) => {
      currentState.userContractStatus.headerList = response.records;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    });
}

/**
 * 請求ステータス管理画面リフレッシュリデューサー
 * @param dlFig ダウンロードの場合true
 */
export function refreshAdminUserContractStatus(dlFlg: boolean): void {
  const currentState = getState();
  currentState.pageTitle = '請求ステータス管理';

  // 明細画面から戻ってきた場合は日付をそのまま
  if (
    currentState.pageId === '/admin/userContract/status/detail' ||
    currentState.pageId === '/admin/invoice-delete/status/detail' ||
    dlFlg
  ) {
    currentState.pageId = '/admin/userContract/status';
  } else {
    currentState.userContractStatus.params.targetDateFrom = moment().startOf('month');
    currentState.userContractStatus.params.targetDateTo = moment().endOf('month');
  }

  searchAdminUserContractStatus();
  history.push('/admin/userContract/status');
}

/**
 *  請求ステータス管理 ステータス検索パラメーターセットリデューサー
 */
export function setUserContractStatusParams(dateFrom?: Moment, dateTo?: Moment): void {
  const currentState = getState();
  if (dateFrom) {
    currentState.userContractStatus.params.targetDateFrom = dateFrom;
  } else {
    currentState.userContractStatus.params.targetDateFrom = moment().startOf('month');
  }
  if (dateTo) {
    currentState.userContractStatus.params.targetDateTo = dateTo;
  } else {
    currentState.userContractStatus.params.targetDateTo = moment().endOf('month');
  }
  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 *  請求ステータス管理明細画面リデューサー
 * @param mgmtId アップロード管理ID
 */
export function getUserContractStatusDetail(mgmtId: number, uploadType: string): void {
  const currentState = getState();
  currentState.pageTitle = '請求ステータス管理';

  if (uploadType === 'register') {
    currentState.pageId = '/admin/userContract/status/detail';
    const loading = false;

    myHttpClient.get(baseURL + `/admin/user-contract/status/detail/${mgmtId}?status=error`).then((response: any) => {
      currentState.userContractStatus.detailList = response.records;
      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
      history.push('/admin/userContract/status/detail');
    });
  } else if (uploadType === 'delete') {
    currentState.pageId = '/admin/invoice-delete/status/detail';
    const loading = false;

    myHttpClient.get(baseURL + `/admin/invoice-delete/status/detail/${mgmtId}?status=error,nodata`).then((response: any) => {
      currentState.userContractStatus.deleteDetailList = response.records;
      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
      history.push('/admin/invoice-delete/status/detail');
    });
  } else {
    history.push('/systemError');
  }
}

/**
 * 請求ステータス管理明細画面 登録明細ダウンロードリデューサー
 * @param mgmtId 管理ID
 * @param fileName ダウンロード対象のファイル名
 */
export function DownloadUserContractUploadStatusDetail(mgmtId: number): void {
  const url = baseURL + '/admin/download/user-contract/status/detail/' + mgmtId;
  myHttpClient
    .get(url, {
      responseType: 'blob'
    })
    .then((response: any) => {
      blobToText(response)
        .then((result: any) => {
          if (result.data.readStatus === 'success') {
            let fileName = '';
            const disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?|[^;\n]*)/;
              const matches = fileNameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/UTF-8['"]+/g, '');
                fileName = decodeURIComponent(fileName);
              }
            }
            downloadFile(response, fileName);
          }
          message.success('ダウンロードが完了しました。');
          refreshAdminUserContractStatus(true);
        })
        .catch(_ => {
          history.push('/systemError');
        });
    })
    .catch(error => {
      if (error.response.status === 500) {
        message.error('指定されたファイルは存在しません。');
        refreshAdminUserContractStatus(true);
      }
    });
}

/**
 * 請求ステータス管理明細画面 削除明細ダウンロードリデューサー
 * @param mgmtId 管理ID
 * @param fileName ダウンロード対象のファイル名
 */
export function DownloadInvoiceDeleteStatusDetail(mgmtId: number): void {
  const url = baseURL + '/admin/download/invoice-delete/status/detail/' + mgmtId;
  myHttpClient
    .get(url, {
      responseType: 'blob'
    })
    .then((response: any) => {
      blobToText(response)
        .then((result: any) => {
          if (result.data.readStatus === 'success') {
            let fileName = '';
            const disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?|[^;\n]*)/;
              const matches = fileNameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/UTF-8['"]+/g, '');
                fileName = decodeURIComponent(fileName);
              }
            }
            downloadFile(response, fileName);
          }
          message.success('ダウンロードが完了しました。');
          refreshAdminUserContractStatus(true);
        })
        .catch(_ => {
          history.push('/systemError');
        });
    })
    .catch(error => {
      if (error.response.status === 500) {
        message.error('指定されたファイルは存在しません。');
        refreshAdminUserContractStatus(true);
      }
    });
}

/**
 * 入金ステータス検索リデューサー
 */
export function searchAdminUserPaymentStatus(): void {
  const currentState = getState();
  const loading = false;
  const targetDateFrom = currentState.userPaymentStatus.params.targetDateFrom.format('YYYY-MM-DD');
  const targetDateTo = currentState.userPaymentStatus.params.targetDateTo.format('YYYY-MM-DD');

  myHttpClient
    .get(baseURL + '/admin/user-payment/status', {
      params: {
        targetDateFrom,
        targetDateTo
      }
    })
    .then((response: any) => {
      currentState.userPaymentStatus.headerList = response.records;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    });
}

/**
 * 入金ステータス管理画面リフレッシュリデューサー
 * @param dlFig ダウンロードの場合true
 */
export function refreshAdminUserPaymentStatus(dlFlg: boolean): void {
  const currentState = getState();
  currentState.pageTitle = '入金ステータス管理';

  // 明細画面から戻ってきた場合は日付をそのまま
  if (currentState.pageId === '/admin/userPayment/status/detail' || dlFlg) {
    currentState.pageId = '/admin/userPayment/status';
  } else {
    currentState.userPaymentStatus.params.targetDateFrom = moment().startOf('month');
    currentState.userPaymentStatus.params.targetDateTo = moment().endOf('month');
  }

  searchAdminUserPaymentStatus();
  history.push('/admin/userPayment/status');
}

/**
 *  入金ステータス管理 ステータス検索パラメーターセットリデューサー
 */
export function setUserPaymentStatusParams(dateFrom?: Moment, dateTo?: Moment): void {
  const currentState = getState();
  if (dateFrom) {
    currentState.userPaymentStatus.params.targetDateFrom = dateFrom;
  } else {
    currentState.userPaymentStatus.params.targetDateFrom = moment().startOf('month');
  }
  if (dateTo) {
    currentState.userPaymentStatus.params.targetDateTo = dateTo;
  } else {
    currentState.userPaymentStatus.params.targetDateTo = moment().endOf('month');
  }
  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 *  入金ステータス管理明細画面リデューサー
 * @param mgmtId アップロード管理ID
 */
export function getUserPaymentStatusDetail(mgmtId: number): void {
  const currentState = getState();
  currentState.pageTitle = '入金ステータス管理';
  currentState.pageId = '/admin/userPayment/status/detail';
  const loading = false;

  myHttpClient.get(baseURL + `/admin/user-payment/status/detail/${mgmtId}?status=error,warn`).then((response: any) => {
    currentState.userPaymentStatus.detailList = response.records;
    setState((state: Model.IState) => ({
      ...state,
      loading
    }));
    history.push('/admin/userPayment/status/detail');
  });
}

/**
 * 入金ステータス管理明細画面リデューサー
 * @param userPaymentMgmtId アップロード管理ID
 * @param fileName ダウンロード対象のファイル名
 */
export function DownloadUserPaymentStatusDetail(userPaymentMgmtId: number): void {
  const url = baseURL + '/admin/download/user-payment/status/detail/' + userPaymentMgmtId;
  myHttpClient
    .get(url, {
      responseType: 'blob'
    })
    .then((response: any) => {
      blobToText(response)
        .then((result: any) => {
          if (result.data.readStatus === 'success') {
            let fileName = '';
            const disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?|[^;\n]*)/;
              const matches = fileNameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/UTF-8['"]+/g, '');
                fileName = decodeURIComponent(fileName);
              }
            }
            downloadFile(response, fileName);
          }
          message.success('ダウンロードが完了しました。');
          refreshAdminUserPaymentStatus(true);
        })
        .catch(_ => {
          history.push('/systemError');
        });
    })
    .catch(error => {
      if (error.response.status === 500) {
        message.error('指定されたファイルは存在しません。');
        refreshAdminUserPaymentStatus(true);
      }
    });
}

/**
 *  返金追加情報取得リデューサー
 */
export function getUpdateRefundInfo(
  companyId: string,
  cbaId: string,
  targetRefundCreatedDate: string,
  targetRefundPaymentAmount: number
): void {
  const currentState = getState();
  const loading = false;
  myHttpClient.get(baseURL + `/admin/refund/${companyId}/${cbaId}`).then((response: any) => {
    currentState.updateRefundModal.modalVisible = true;
    currentState.updateRefundModal.refundInfo = response;
    currentState.updateRefundModal.targetRefundCreatedDate = targetRefundCreatedDate;
    currentState.updateRefundModal.targetRefundPaymentAmount = targetRefundPaymentAmount;

    setState((state: Model.IState) => ({
      ...state,
      loading
    }));
  });
}

/**
 *  返金追加情報更新リデューサー
 */
export function updateRefundInfo(refundId: string, isCompleted: true): void {
  const currentState = getState();
  const loading = false;
  const reqBody = { refundId, isCompleted };
  myHttpClient
    .put(baseURL + '/admin/refund', reqBody)
    .then((response: any) => {
      currentState.updateRefundModal.modalVisible = true;
      currentState.updateRefundModal.refundInfo = response;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
      message.success('返金を完了済みに更新しました。');
    })
    .catch(() => {
      return;
    });
}

/**
 * 返金追加情報モーダル 表示非表示設定リデューサー
 */
export function setUpdateRefundModalVisible(visible: boolean): void {
  const currentState = getState();
  currentState.updateRefundModal.modalVisible = visible;

  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 仕訳データ検索リデューサー
 */
export function searchAdminJournalData(): void {
  const currentState = getState();
  const loading = false;
  const targetDate = currentState.journalDataDownload.params.targetDate.format('YYYY-MM-DD');

  myHttpClient
    .get(baseURL + '/admin/journal', {
      params: {
        targetDate
      }
    })
    .then((response: any) => {
      currentState.journalDataDownload.journalList = response.journalData;
      currentState.journalDataDownload.batchRunList = response.batchRunStatus;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    });
}

/**
 * 仕訳実行パラメータ取得リデューサー
 */
export async function getJournalCreationParams(): Promise<void> {
  const currentState = getState();
  const loading = false;

  const response: any = await myHttpClient.get(baseURL + '/admin/journal/create', {
    params: {}
  });
  currentState.journalCreation.targetDate = moment(response.targetDate);
  currentState.journalCreation.defaultTargetDate = moment(response.targetDate);
  currentState.journalCreation.forceExecutionFlg = response.forceExecutionFlg;
  currentState.journalCreation.execInvoiceDeletionFlg = response.execInvoiceDeletionFlg;
  currentState.journalCreation.execOverdueNoticeFlg = response.execOverdueNoticeFlg;
  currentState.journalCreation.execRevenueScheduleUpdateFlg = response.execRevenueScheduleUpdateFlg;

  setState((state: Model.IState) => ({
    ...state,
    loading
  }));
}

/**
 *  仕訳実行パラメーターセットリデューサー
 */
export function setJournalCreationParams(date?: Moment): void {
  const currentState = getState();
  if (date) {
    currentState.journalCreation.targetDate = date;
  } else {
    currentState.journalCreation.targetDate = currentState.journalCreation.defaultTargetDate;
  }
  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 仕訳実行画面リフレッシュリデューサー
 */
export async function refreshAdminJournalCreation(): Promise<void> {
  const currentState = getState();
  currentState.pageTitle = '仕訳実行';

  if (!currentState.journalCreation.targetDate) {
    currentState.journalCreation.targetDate = currentState.journalCreation.defaultTargetDate;
  }
  await getToken();
  await getJournalCreationParams();
  history.push('/admin/journal/create');
}

/**
 * 仕訳実行リデューサー
 */
export function executeJournalCreation(params: Model.IJournalCreation): void {
  const currentState = getState();
  const loading = false;

  const paramsForBff: Model.IJournalCreationForBff = {
    targetDate: params.targetDate.format('YYYY-MM-DD'), // バックエンドで関らず1日を指定
    forceExecutionFlg: params.forceExecutionFlg ? '1' : '0',
    execInvoiceDeletionFlg: params.execInvoiceDeletionFlg ? '1' : '0',
    execOverdueNoticeFlg: params.execOverdueNoticeFlg ? '1' : '0',
    execRevenueScheduleUpdateFlg: params.execRevenueScheduleUpdateFlg ? '1' : '0'
  };

  myHttpClient
    .post(baseURL + '/admin/journal/create', paramsForBff)
    .then((response: any) => {
      currentState.pageTitle = '仕訳実行';

      if (response.message) {
        message.error(response.message);
      } else {
        message.success('仕訳実行を開始しました。', 3);
      }

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
      // トークン再取得
      getToken();
    })
    .catch(function(error) {
      message.error('想定外のエラーが発生しました。', 3);
      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    });
}

/**
 * 仕訳実行ステータス管理画面リフレッシュリデューサー
 */
export function refreshAdminJournalDataStatus(): void {
  const currentState = getState();
  currentState.pageTitle = '仕訳実行ステータス管理';

  if (!currentState.journalDataDownload.params.targetDate) {
    currentState.journalDataDownload.params.targetDate = moment()
      .add(-1, 'month')
      .startOf('month');
  }

  searchAdminJournalData();
  history.push('/admin/journal');
}

/**
 *  仕訳データ検索パラメーターセットリデューサー
 */
export function setJournalDataParams(date?: Moment): void {
  const currentState = getState();
  if (date) {
    currentState.journalDataDownload.params.targetDate = date;
  } else {
    currentState.journalDataDownload.params.targetDate = moment()
      .add(-1, 'month')
      .startOf('month');
  }
  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 「仕訳データダウンロード」更新リデューサー
 * @param mgmtId 管理ID
 * @param fileName ダウンロード対象のファイル名
 */
export function downloadJournalData(mgmtId: number, fileName: string): void {
  const url = baseURL + '/admin/journal/download/' + mgmtId;
  myHttpClient
    .get(url, {
      responseType: 'blob'
    })
    .then((response: any) => {
      blobToText(response)
        .then((result: any) => {
          if (result.data.readStatus === 'success') {
            downloadFile(response, fileName);
          }
          message.success('ダウンロードが完了しました。');
          refreshAdminJournalDataStatus();
        })
        .catch(_ => {
          history.push('/systemError');
        });
    })
    .catch(error => {
      if (error.response.status === 500) {
        message.error('指定されたファイルは存在しません。');
        refreshAdminJournalDataStatus();
      }
    });
}

/**
 * 入金OKファイル検索リデューサー
 */
export function searchAdminPaymentOk(): void {
  const currentState = getState();
  const loading = false;
  const targetDate = currentState.paymentOkDownload.params.targetDate.format('YYYY-MM-DD');

  myHttpClient
    .get(baseURL + '/admin/paymentOk', {
      params: {
        targetDate
      }
    })
    .then((response: any) => {
      currentState.paymentOkDownload.paymentOkList = response.records;

      setState((state: Model.IState) => ({
        ...state,
        loading
      }));
    });
}

/**
 * 入金OKファイルダウンロード画面リフレッシュリデューサー
 */
export function refreshAdminPaymentOkDownload(): void {
  const currentState = getState();
  currentState.pageTitle = '入金OKファイルダウンロード';

  if (!currentState.paymentOkDownload.params.targetDate) {
    currentState.paymentOkDownload.params.targetDate = moment().startOf('month');
  }

  searchAdminPaymentOk();
  history.push('/admin/paymentOk');
}

/**
 *  入金OKファイル検索パラメーターセットリデューサー
 */
export function setPaymentOkParams(date?: Moment): void {
  const currentState = getState();
  if (date) {
    currentState.paymentOkDownload.params.targetDate = date;
  } else {
    currentState.paymentOkDownload.params.targetDate = moment().startOf('month');
  }
  setState((state: Model.IState) => ({
    ...state
  }));
}

/**
 * 「入金OKファイルダウンロード」更新リデューサー
 * @param paymentOkId 入金OKID
 * @param fileName ダウンロード対象のファイル名
 */
export function downloadPaymentOk(paymentOkId: number, fileName: string): void {
  const url = baseURL + '/admin/paymentOk/download/' + paymentOkId;
  myHttpClient
    .get(url, {
      responseType: 'blob'
    })
    .then((response: any) => {
      blobToText(response)
        .then((result: any) => {
          if (result.data.readStatus === 'success') {
            downloadFile(response, fileName);
          }
          message.success('ダウンロードが完了しました。');
          refreshAdminPaymentOkDownload();
        })
        .catch(_ => {
          history.push('/systemError');
        });
    })
    .catch(error => {
      if (error.response.status === 500) {
        message.error('指定されたファイルは存在しません。');
        refreshAdminPaymentOkDownload();
      }
    });
}

/**
 *不要請求削除画面リデューサー
 */
export function userContractUnpaidInvoiceData(fromCallback = false): void {
  const loading = false;
  myHttpClient.get(baseURL + '/user-contract-unpaid-invoice').then((response: any) => {
    const pageId = '/user-contract/invoice/delete';
    const pageTitle = '不要請求書削除';
    const userContractUnpaidInvoiceData = response.details;
    setState((state: Model.IState) => ({
      ...state,
      loading,
      pageId,
      pageTitle,
      userContractUnpaidInvoiceData
    }));
    if (fromCallback) {
      history.replace('/user-contract/invoice/delete');
    } else {
      history.push('/user-contract/invoice/delete');
    }
  });
}

/**
 * 不要請求削除画面 請求削除実行API
 * @param companyId 企業ID
 * @param invoices 請求書リスト
 */
export function exeUnpaidInvoice(selectedInvoices: Model.IUserContractUnpaidInvoiceData[]): Promise<any> {
  const invoices = selectedInvoices.map(e => {
    return {
      invoiceId: e.invoiceId,
      invoiceNumber: e.userContractInvoiceNumber
    };
  });

  const reqBody = {
    invoices
  };
  return myHttpClient.post(baseURL + '/user-contract-unpaid-invoice/deletion', reqBody);
}

/**
 * 不要請求削除実行
 * @param selectedInvoices 選択したInvoiceの情報リスト
 */
export async function postUnpaidInvoice(selectedInvoices: Model.IUserContractUnpaidInvoiceData[]): Promise<void> {
  exeUnpaidInvoice(selectedInvoices)
    .then(result => {
      const loading = false;
      setState((state: Model.IState) => ({
        ...state,
        loading,
        deleteModalVisible: false
      }));

      if (result.isConcurrent) {
        const msg = '選択した請求書は既に削除処理中です。\nしばらくお待ちください。';
        deletionProceededModal(msg, onRefreshSearchUnpaidInvoiceData);
      } else {
        const msg = '請求書の削除を受け付けました。削除が完了するまで時間がかかる場合がございます。';
        acceptDeletionModal(msg, onRefreshSearchUnpaidInvoiceData);
      }
    })
    .catch(() => {
      return;
    });
}

/**
 * 不要請求削除画面 削除後の画面リフレッシュリデューサー
 */
export async function refreshUserContractUnpaidInvoiceData(): Promise<void> {
  const loading = false;
  await myHttpClient.get(baseURL + '/user-contract-unpaid-invoice').then((response: any) => {
    const pageId = '/user-contract/invoice/delete';
    const pageTitle = '不要請求書削除';
    const userContractUnpaidInvoiceData = response.details;
    setState((state: Model.IState) => ({
      ...state,
      pageId,
      pageTitle,
      userContractUnpaidInvoiceData,
      loading
    }));
    history.push('/user-contract/invoice/delete');
  });
}

/**
 * トップ画面 オーナー契約 未払請求キャンセル実行API
 * @param invoiceId 請求書ID
 * @param invoiceNumber 請求書番号
 */
export async function executeCancelOwnerInvoice(invoiceId: string, invoiceNumber: string): Promise<any> {
  const reqBody = {
    invoiceId,
    invoiceNumber
  };
  const response: any = await myHttpClient.post(baseURL + '/owner-contract-unpaid-invoice/deletion', reqBody);

  return response;
}

/**
 * トップ画面 オーナー契約 未払請求キャンセル実行
 * @param invoiceId 請求書ID
 * @param invoiceNumber 請求書番号
 */
export async function postCancelOwnerUnpaidInvoice(invoiceId: string, invoiceNumber: string): Promise<void> {
  executeCancelOwnerInvoice(invoiceId, invoiceNumber)
    .then(result => {
      const loading = false;
      setState((state: Model.IState) => ({
        ...state,
        loading,
        deleteModalVisible: false
      }));

      const currentState = getState();
      const onOkFunction = currentState.isAdmin ? onGetCustomerInfoWithAdminAuth : onGetCustomerInfo;

      switch (result.deletionStatus) {
        case 'started':
          acceptDeletionModal('請求書のキャンセルを受け付けました。キャンセルが完了するまで\n時間がかかる場合がございます。', onOkFunction);
          break;
        case 'concurrent':
          deletionProceededModal('選択した請求書は既にキャンセル処理中です。\nしばらくお待ちください。', onOkFunction);
          break;
        case 'ownerRenewChanged':
          Modal.warning({
            centered: true,
            content: result.message,
            width: 600,
            onOk() {
              onOkFunction();
            }
          });
          break;
        case 'ownerRenewChangeReconciled':
          Modal.error({
            centered: true,
            content: result.message,
            width: 600,
            onOk() {
              onOkFunction();
            }
          });
          break;
        default:
          history.push('/systemError');
          break;
      }
    })
    .catch(() => {
      return;
    });
}

/*-----------------------------------共通処理-------------------------------------------*/

export function logout(): void {
  myHttpClient
    .post(baseURL + '/logout')
    .then((response: any) => {
      refreshContractData();
      // loginUserInfo というキー の cookie を 削除することで認証状態を破棄する
      document.cookie = 'loginUserInfo=; max-age=0';
      setState((state: Model.IState) => ({
        ...state,
        pageTitle: '',
        loginInfo: { firstName: '', lastName: '', managerFlag: '' }
      }));
      history.push('/logout');
    })
    .catch(function() {
      history.push('/logout');
    });
}

/**
 * 「認証チェック」確認リデューサー
 */

export function AuthCheck(moveTo: string): void {
  setState((state: Model.IState) => ({
    ...state,
    loginStatus: 'AuthCheck'
  }));
  myHttpClient.get(baseURL + `/auth?moveTo=${moveTo}`).then((response: any) => {
    const authUrl = response.authorizationUrl;
    window.location.replace(authUrl);
  });
}

/**
 * 認証基盤CallBack
 * @param params Callbackで返ってきたURLのパラメータ
 */

export function AuthCallBack(params: string): void {
  setState((state: Model.IState) => ({
    ...state,
    loginStatus: 'AuthCallback'
  }));
  myHttpClient.get(baseURL + '/auth/callback' + params).then((response: any) => {
    const currentState = getState();
    currentState.isAdmin = response.isAdmin;
    currentState.loginInfo = response.loginInfo;
    currentState.loginStatus = 'Completed';
    currentState.moveTo = response.moveTo;
    if (response.moveTo === '/user-contract/invoice/delete') {
      onGetUserContractUnpaidInvoiceData(true);
    } else {
      if (response.isAdmin) {
        setAdminInfo(true);
      } else {
        history.replace('/user/top');
      }
    }
  });
}

/**
 * 「ログインユーザー情報」取得リデューサー
 */
export function getUserContext(): void {
  myHttpClient.get(baseURL + '/user').then((response: any) => {
    const isAdmin = response.isAdmin;
    const loginInfo: Model.ILoginInfo = response.userInfo;
    setState((state: Model.IState) => ({
      ...state,
      isAdmin,
      loginInfo,
      loginStatus: 'Completed'
    }));
  });
}

// API Call前共通処理（トークンセット）
myHttpClient.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    if (request.method === 'put' || request.method === 'post') {
      const cookies = document.cookie;
      const cookiesArray = cookies.split(';');
      for (const c of cookiesArray) {
        const cArray = c.split('=');
        if (cArray[0].trim() === 'csrf-token') {
          request.headers['csrf-token'] = cArray[1];
        }
      }
    }
    return request;
  },
  function(error) {
    history.push('/systemError');
    return Promise.reject(error);
  }
);

/* eslint-disable complexity */
// API Call後共通処理
myHttpClient.interceptors.response.use(
  (response: AxiosResponse<Model.IResponseData>) => {
    if (response.status === 200) {
      if (response.headers['csrf-token']) {
        document.cookie = 'csrf-token=' + response.headers['csrf-token'] + ';path=/;';
      }
      if (response.config.responseType === 'blob') {
        return response;
      } else {
        if (response.data.appStatus === 'success') {
          return response.data.contents;
        } else if (response.data.appStatus === 'pdfError') {
          history.push({
            pathname: '/failedCreatingPDFError',
            state: {
              msg: response.data.contents.message
            }
          });
        } else if (response.data.appStatus === 'mailError') {
          message.error('登録は完了しましたがメール送信に失敗しました。', 5);
          return response.data.contents;
        } else if (response.data.appStatus === 'individualContractCompanyError') {
          history.push('/individualContractCompanyError');
          return Promise.reject();
        } else {
          history.push('/systemError');
          return Promise.reject();
        }
      }
    }
  },
  function(error) {
    // 認証エラー時の処理
    switch (error.response.status) {
      case 400: // Bad Request
        history.push('/systemError');
        break;
      case 401: // 認証に失敗（Unauthorized）
        if (error.response.config.responseType === 'blob' || error.response.data.appStatus === 'sessionError') {
          // AuthCheck();
          showSessionTimeOutModal();
        } else {
          history.push('/authError');
        }
        break;
      case 403: // アクセス権がない（Forbidden）
        if (window.location.pathname.includes('/admin/')) {
          history.push({
            pathname: '/cantuse',
            state: {
              buttonType: 'Admin'
            }
          });
        } else if (window.location.pathname.includes('/user/')) {
          history.push({
            pathname: '/cantuse',
            state: {
              buttonType: 'User'
            }
          });
        } else if (window.location.pathname.includes('/user-contract/invoice/delete')) {
          history.push({
            pathname: '/cantuse',
            state: {
              buttonType: 'UserContractUnpaidInvoice'
            }
          });
        } else {
          history.push({
            pathname: '/cantuse'
          });
        }
        break;
      case 404: // Not Found
        history.push('/notFoundError');
        break;
      case 500:
        history.push('/systemError');
        break;
      default:
        // その他
        history.push('/systemError');
        break;
    }
    return Promise.reject(error);
  }
);

/**
 * エラー処理
 * @param cause エラー原因
 */
export const error = <T>(cause: unknown): void => {
  const msg = cause instanceof Error ? cause.message : '';
  message.error('想定外のエラーが発生しました。' + msg);
  history.push('/systemError');
};
