import React from 'react';
import { CompanySearchForm } from '../../components/admin/companySearch/searchForm';
import SearchResult from '../../components/admin/companySearchResult/searchResult';

/* eslint-disable react/no-unescaped-entities */
export default function CompanySearchPage(props: any): JSX.Element {
  return (
    <>
      {!props.state.loading && (
        <>
          <CompanySearchForm />
          <SearchResult windowHeight={props.windowHeight} windowWidth={props.windowWidth} />
        </>
      )}
    </>
  );
}
