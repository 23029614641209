import React, { useEffect } from 'react';
import * as Reducers from '../../domain/reducers';

export function Callback(): JSX.Element {
  useEffect(() => {
    const param = window.location.search;
    Reducers.AuthCallBack(param);
  }, []);

  return <p></p>;
}
