import React from 'react';
import { Table, Button, DatePicker, Typography, Form } from 'antd';
import { IProps, IUserPaymentStatusHeader } from '../../../domain/model';
import {
  onGetUserPaymentUploadStatusDetail,
  onSetUserPaymentStatusParams,
  onSearchUserPaymentStatus,
  onDownloadUserPaymentStatusDetail
} from '../../../domain/middleware';
import { numberFormater, convertStatus } from '../../../domain/common';
import './userPaymentStatus.css';

export default function UserPaymentStatus({ state }: IProps): JSX.Element {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'userPaymentMgmtId',
      className: 'left'
    },
    {
      title: 'ファイル名',
      dataIndex: 'fileName',
      className: 'left'
    },
    {
      title: 'ステータス',
      dataIndex: 'statusDisp',
      className: 'center'
    },
    {
      title: 'エラーメッセージ',
      dataIndex: 'errorMessage',
      className: 'left'
    },
    {
      title: '総件数',
      dataIndex: 'totalCountDisp',
      className: 'right'
    },
    {
      title: '成功件数',
      dataIndex: 'successCountDisp',
      className: 'right'
    },
    {
      title: '失敗件数',
      dataIndex: 'failureCountDisp',
      className: 'right'
    },
    {
      title: '登録日時',
      dataIndex: 'insertDatetime',
      className: 'right',
      width: 95
    },
    {
      title: '登録者',
      dataIndex: 'insertUserName',
      className: 'left'
    },
    {
      title: '更新日時',
      dataIndex: 'updateDatetime',
      className: 'right',
      width: 95
    },
    {
      title: '更新者',
      dataIndex: 'updateUserName',
      className: 'left'
    },
    {
      title: '明細画面',
      dataIndex: 'buttonToDetailPage',
      className: 'center'
    },
    {
      title: 'ダウンロード',
      dataIndex: 'buttonToDownload',
      className: 'center'
    }
  ];

  interface IDataSource extends IUserPaymentStatusHeader {
    statusDisp: string;
    totalCountDisp: string;
    successCountDisp: string;
    failureCountDisp: JSX.Element;
    buttonToDetailPage: JSX.Element;
    buttonToDownload: JSX.Element;
  }

  const { Text } = Typography;
  const failureCountFormatter = (count: number): JSX.Element => {
    return !count ? (
      <Text>{numberFormater(count)}</Text>
    ) : (
      // エラーが発生している場合は目立たせる
      <Text type="danger">{numberFormater(count)}</Text>
    );
  };
  const buttonToDetailPage = (mgmtId: number): JSX.Element => {
    return <Button onClick={() => onGetUserPaymentUploadStatusDetail(mgmtId)}>明細</Button>;
  };

  function buttonToDownload(userPaymentMgmtId: number): any {
    return <Button onClick={() => onDownloadUserPaymentStatusDetail(userPaymentMgmtId)}>ダウンロード</Button>;
  }

  const dataSource: IDataSource[] = state.userPaymentStatus.headerList.map(row => {
    return {
      ...row,
      statusDisp: convertStatus(row.status),
      totalCountDisp: numberFormater(row.totalCount),
      successCountDisp: numberFormater(row.successCount),
      failureCountDisp: failureCountFormatter(row.failureCount),
      buttonToDetailPage: buttonToDetailPage(row.userPaymentMgmtId),
      buttonToDownload: buttonToDownload(row.userPaymentMgmtId)
    };
  });

  const { RangePicker } = DatePicker;

  const from = state.userPaymentStatus.params.targetDateFrom;
  const to = state.userPaymentStatus.params.targetDateTo;

  return (
    <>
      <h3>入金 データアップロードステータス</h3>
      <Form className="search" layout="inline" onSubmit={onSearchUserPaymentStatus}>
        <Form.Item label={'登録日'}>
          <RangePicker
            format={'YYYY/MM/DD'}
            placeholder={['検索範囲開始日', '検索範囲終了日']}
            defaultValue={[from, to]}
            onChange={(dates, dateString) => {
              if (dates && dates[0] && dates[1]) {
                onSetUserPaymentStatusParams(dates[0], dates[1]);
              } else {
                onSetUserPaymentStatusParams();
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button className="statusSearchBtn" htmlType="submit">
            検索
          </Button>
        </Form.Item>
      </Form>
      <Table
        className={'commonTable'}
        columns={columns}
        dataSource={dataSource}
        rowKey="userPaymentMgmtId"
        pagination={false}
        size={'small'}
      />
    </>
  );
}
