import React, { useContext } from 'react';
import { Button } from 'antd';
import './totalPriceArea.css';
import { previewContract, jpyFormater } from '../../../domain/common';
import { CalcContext, BtnContext } from '../formParts/context';
import { alertNotification, setPlanChangeFlag, setRecalcFlag } from '../notification/alertNotification';

export default function TotalPriceArea(props: any): JSX.Element {
  const [calDisable, setCalDisable] = useContext(CalcContext);
  const [btnDisable, setBtnDisable] = useContext(BtnContext); // eslint-disable-line @typescript-eslint/no-unused-vars

  const total: number = props.total;
  const pageId: string = props.pageId;

  function calc(): void {
    const previewResult = previewContract(pageId);
    if (previewResult) {
      setBtnDisable(false);
      setCalDisable(true);
      alertNotification('planChange');
      setPlanChangeFlag(false);
      setRecalcFlag(true);
    } else {
      setBtnDisable(true);
      setCalDisable(false);
    }
  }

  const renderTotalPrices = (value?: number): string => {
    if (value) {
      return jpyFormater(value) + '(税抜)';
    } else {
      return jpyFormater(0) + '(税抜)';
    }
  };

  /* eslint-disable react/no-unescaped-entities */
  return (
    <div className="sticky_area">
      <Button className="calcBtn" onClick={calc} disabled={calDisable}>
        計算
      </Button>
      <div className="totalPriceArea">
        <span className="total">合計</span>
        <span className="total2">{renderTotalPrices(total)}</span>
      </div>
    </div>
  );
}
