import * as React from 'react';
import { Spin } from 'antd';
import LoadingOverlay from 'react-loading-overlay';

/* eslint-disable react/no-unescaped-entities */
export default function Loading({ active, children }: any): JSX.Element {
  return (
    <LoadingOverlay active={active} spinner={<Spin size="large" />}>
      {children}
    </LoadingOverlay>
  );
}
