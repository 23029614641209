import React from 'react';
import './monthPicker.css';
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { DatePicker } from 'antd';

export default function MonthPicker(props: any): JSX.Element {
  const { MonthPicker } = DatePicker;

  const placeholder = props.placeholder;
  const disabledDate = props.disabledDate;
  const onChange = props.onChange;

  return (
    <MonthPicker
      id="picker"
      className={'contractUpdateMonthPicker'}
      locale={locale}
      onChange={onChange}
      placeholder={placeholder}
      disabledDate={disabledDate}
      allowClear={false}
      format="YYYY/MM"
    ></MonthPicker>
  );
}
