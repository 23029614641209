import React from 'react';
import { Table, Button } from 'antd';
import { IProps, IInvoiceDeleteStatusDetail } from '../../../domain/model';
import './invoiceDeleteStatusDetail.css';
import { convertStatus } from '../../../domain/common';
import { navigationTo } from '../../../domain/middleware';

export default function InvoiceDeleteStatusDetail({ state }: IProps): JSX.Element {
  const columns = [
    {
      title: 'No',
      dataIndex: 'seqNo',
      className: 'right',
      width: '5%'
    },
    {
      title: '請求削除ステータス管理詳細ID',
      dataIndex: 'lineNo',
      className: 'right'
    },
    {
      title: 'ステータス',
      dataIndex: 'statusDisp',
      className: 'center',
      width: '20%'
    },
    {
      title: 'エラーメッセージ',
      dataIndex: 'errorMessage',
      width: '40%'
    },
    {
      title: '請求書番号',
      dataIndex: 'invoiceNumber'
    }
  ];

  interface IDataSource extends IInvoiceDeleteStatusDetail {
    seqNo: number;
    statusDisp: string;
  }
  const dataSource: IDataSource[] = [];
  let seqNo = 0;
  state.userContractStatus.deleteDetailList.forEach(e => {
    dataSource.push({
      ...e,
      seqNo: ++seqNo,
      statusDisp: convertStatus(e.status)
    });
  });
  return (
    <>
      <h3>請求削除データ アップロードステータス 明細</h3>
      <Table
        className="commonTable"
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 700, y: 400 }}
        rowKey="lineNo"
        pagination={false}
        size={'small'}
      />
      <Button className="backBtn" onClick={() => navigationTo('userContractStatus')}>
        戻る
      </Button>
    </>
  );
}
