import React, { useState, useEffect, useContext } from 'react';
import { Table, Switch, InputNumber } from 'antd';
import { onRefreshPreviewData, onSetPrimeCourse } from '../../../domain/middleware';
import { IState, IOneProduct } from '../../../domain/model';
import './contractTable.css';
import { jpyFormater, numberFormater, onSaveInputValues, ceilBy } from '../../../domain/common';
import { CalcContext, CalenderContext, BtnContext } from '../formParts/context';
import {
  getOwnerContractProductMap,
  OwnerContractProductMapKey,
  getProductMap,
  toggleDisabled,
  estimateNewCourse
} from '../../../domain/utils/ownerContractUpdateUtil';
import { renderPrices, renderToolTip, renderToolTipCharge } from './renderForm';
import * as Const from '../../../domain/const';
import { setPlanChangeFlag } from '../notification/alertNotification';

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable complexity */
export default function ContractUpdateForm(props: any): JSX.Element {
  const pageId = props.pageId;

  const [calDisable, setCalDisable] = useContext(CalcContext); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [isSelectCalender, setCalender] = useContext(CalenderContext); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [btnDisable, setBtnDisable] = useContext(BtnContext); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [usableTrigger, setUsableTrigger] = useState(true);

  // オーナー契約基本プロジェクト
  const [projectCharge, setProjectCharge] = useState<any | IOneProduct>({});
  const [projectChargeValue, setProjectChargeValue] = useState(0);
  const [projectChargeAddValue, setProjectChargeAddValue] = useState(0);

  // BackUp Chargeの制御
  const [backupChargeDisable, setBackupChargeDisable] = useState(true);
  const [backupChargeValue, setBackupChargeValue] = useState(0);

  // プライム契約
  const [primeProjectCharge, setPrimeProjectCharge] = useState<any | IOneProduct>({});
  const [primeProjectChargeValue, setPrimeProjectChargeValue] = useState(0);
  const [primeProjectChargeAddValue, setPrimeProjectChargeAddValue] = useState(0);

  // TODO: Lite表示時以下を有効にする
  //  Liteプロジェクト契約
  // const [liteProjectCharge, setLiteProjectCharge] = useState<any | IOneProduct>({});
  // const [liteProjectChargeValue, setLiteProjectChargeValue] = useState(0);
  // const [liteProjectChargeAddValue, setLiteProjectChargeAddValue] = useState(0);

  // TODO: Lite表示時以下を有効にする
  //  Liteプライムプロジェクト契約
  // const [litePrimeProjectCharge, setLitePrimeProjectCharge] = useState<any | IOneProduct>({});
  // const [litePrimeProjectChargeValue, setLitePrimeProjectChargeValue] = useState(0);
  // const [litePrimeProjectChargeAddValue, setLitePrimeProjectChargeAddValue] = useState(0);

  // TODO: Lite表示時以下を有効にする
  //  Liteセット契約
  // const [liteSetCharge, setLiteSetCharge] = useState<any | IOneProduct>({});
  // const [liteSetChargeValue, setLiteSetChargeValue] = useState(0);
  // const [liteSetChargeAddValue, setLiteSetChargeAddValue] = useState(0);

  // TODO: Lite表示時以下を有効にする
  // LiteBackUp Chargeの制御
  // const [liteBackupChargeDisable, setLiteBackupChargeDisable] = useState(true);
  // const [liteBackupChargeValue, setLiteBackupChargeValue] = useState(0);

  const state: IState = props.state;
  const isRenewed = state.productList.isRenewed || false;

  const ownerContractProductMap = getOwnerContractProductMap(state.productList.productList);
  const allInitial = ownerContractProductMap[Const.ALL_INITIAL];
  const allBackupInitial = ownerContractProductMap[Const.ALLBACKUP_INITIAL];
  const backupCharge = ownerContractProductMap[Const.BACKUP_CHARGE];
  const liteBackupCharge = ownerContractProductMap[Const.LITEBACKUP_CHARGE];
  const dataLinkInitial = ownerContractProductMap[Const.DATALINK_INITIAL];
  const dataLinkCharge = ownerContractProductMap[Const.DATALINK_CHARGE];

  // プライム契約の初期データ
  let projectChargeDefault: any;

  // プライム契約の初期データ
  let primeProjectChargeDefault: any;

  // TODO: Lite表示時以下を有効にする
  // Liteプライム契約の初期データ
  // let litePrimeProjectChargeDefault: any;

  // TODO: Lite表示時以下を有効にする
  // Liteプロジェクト契約の初期データ
  // let liteProjectChargeDefault: any;

  // TODO: Lite表示時以下を有効にする
  // LiteSet契約の初期データ
  // let liteSetChargeDefault: any;

  if (state.courseInfo.basicProjectList && state.courseInfo.basicProjectList.length > 0) {
    // プライム契約を契約していたらそのコースを初期コースに設定 契約していなかったらAコースを設定
    projectChargeDefault = ownerContractProductMap['Project_Charge'] || getProductMap(state.courseInfo.basicProjectList)['Project_ACharge'];
  }
  if (state.courseInfo.primeProjectList && state.courseInfo.primeProjectList.length > 0) {
    // プライム契約を契約していたらそのコースを初期コースに設定 契約していなかったらAコースを設定
    primeProjectChargeDefault =
      ownerContractProductMap['PrimeProject_Charge'] || getProductMap(state.courseInfo.primeProjectList)['PrimeProject_ACharge'];
  }

  // TODO: Lite表示時以下を有効にする
  // if (state.courseInfo.litePrimeProjectList && state.courseInfo.litePrimeProjectList.length > 0) {
  //   // Liteプライム契約を契約していたらそのコースを初期コースに設定 契約していなかったらAコースを設定
  //   litePrimeProjectChargeDefault =
  //     ownerContractProductMap['LitePrimeProject_Charge'] ||
  //     getProductMap(state.courseInfo.litePrimeProjectList)['LitePrimeProject_ACharge'];
  // }

  // TODO: Lite表示時以下を有効にする
  // if (state.courseInfo.liteProjectList && state.courseInfo.liteProjectList.length > 0) {
  //   // Lite契約を契約していたらそのコースを初期コースに設定 契約していなかったらAコースを設定
  //   liteProjectChargeDefault =
  //     ownerContractProductMap['LiteProject_Charge'] || getProductMap(state.courseInfo.liteProjectList)['LiteProject_ACharge'];
  // }

  // TODO: Lite表示時以下を有効にする
  // if (state.courseInfo.liteSetList && state.courseInfo.liteSetList.length > 0) {
  //   // LiteSet契約を契約していたらそのコースを初期コースに設定 契約していなかったらAコースを設定
  //   liteSetChargeDefault = ownerContractProductMap['LiteSet_Charge'] || getProductMap(state.courseInfo.liteSetList)['LiteSet_ACharge'];
  // }

  // 変更時共通ハンドリング
  function handleChange(value: any, product: IOneProduct): void {
    setCalDisable(false);
    onSaveInputValues(value, product);
    onRefreshPreviewData();
    setUsableTrigger(!usableTrigger);
  }

  // PJ数に応じたコースの再評価（プロジェクト利用料）
  function estimateProjectCourse(value: number, chargeKey: string, products: IOneProduct[]): void {
    const newCourse = estimateNewCourse(value, products, 'Project');

    // 元々契約がある場合、かつ、コースが変わった場合のみ変更フラグをONにする
    setPlanChangeFlag(ownerContractProductMap['Project_Charge'] && newCourse.productRatePlanChargeKey !== chargeKey);

    // 元々がAコースでコースに変更があったときは端数除去（例：109⇒110に置き換え）
    let chargeValue = value;
    if (chargeKey === 'Project_ACharge' && newCourse.productRatePlanChargeKey !== chargeKey) {
      chargeValue = ceilBy(value, 10);
    }

    setProjectChargeValue(chargeValue);
    setProjectCharge(newCourse);
    handleChange(chargeValue, newCourse);
  }

  // PJ数に応じたコースの再評価（プライムプロジェクト利用料）
  function estimatePrimeProjectCourse(value: number, chargeKey: string, products: IOneProduct[]): void {
    if (products && products.length > 0) {
      const newCourse = estimateNewCourse(value, products, 'PrimeProject');

      // 元々がAコースでコースに変更があったときは端数除去（例：109⇒110に置き換え）
      let chargeValue = value;
      if (chargeKey === 'PrimeProject_ACharge' && newCourse.productRatePlanChargeKey !== chargeKey) {
        chargeValue = ceilBy(value, 10);
      }

      setPrimeProjectCharge(newCourse);
      onSetPrimeCourse(newCourse);
      setPrimeProjectChargeValue(chargeValue);
      handleChange(chargeValue, newCourse);
    }
  }

  // TODO: Lite表示時以下を有効にする
  // PJ数に応じたコースの再評価（Liteプロジェクト利用料）
  // function estimateLiteProjectCourse(value: number, chargeKey: string, products: IOneProduct[]): void {
  //   const newCourse = estimateNewCourse(value, products, 'LiteProject');

  //   // 元々がAコースでコースに変更があったときは端数除去（例：109⇒110に置き換え）
  //   let chargeValue = value;
  //   if (chargeKey === 'LiteProject_ACharge' && newCourse.productRatePlanChargeKey !== chargeKey) {
  //     chargeValue = ceilBy(value, 10);
  //   }

  //   setLiteProjectCharge(newCourse);
  //   setLiteProjectChargeValue(chargeValue);
  //   handleChange(chargeValue, newCourse);
  // }

  // TODO: Lite表示時以下を有効にする
  // PJ数に応じたコースの再評価（Liteプライムプロジェクト利用料）
  // function estimateLitePrimeProjectCourse(value: number, chargeKey: string, products: IOneProduct[]): void {
  //   if (products && products.length > 0) {
  //     const newCourse = estimateNewCourse(value, products, 'LitePrimeProject');

  //     // 元々がAコースでコースに変更があったときは端数除去（例：109⇒110に置き換え）
  //     let chargeValue = value;
  //     if (chargeKey === 'LitePrimeProject_ACharge' && newCourse.productRatePlanChargeKey !== chargeKey) {
  //       chargeValue = ceilBy(value, 10);
  //     }

  //     setLitePrimeProjectCharge(newCourse);
  //     setLitePrimeProjectChargeValue(chargeValue);
  //     handleChange(chargeValue, newCourse);
  //   }
  // }

  // TODO: Lite表示時以下を有効にする
  // PJ数に応じたコースの再評価（Liteセット利用料）
  // function estimateLiteSetCourse(value: number, chargeKey: string, products: IOneProduct[]): void {
  //   if (products && products.length > 0) {
  //     const newCourse = estimateNewCourse(value, products, 'LiteSet');

  //     // 元々がAコースでコースに変更があったときは端数除去（例：109⇒110に置き換え）
  //     let chargeValue = value;
  //     if (chargeKey === 'LiteSet_ACharge' && newCourse.productRatePlanChargeKey !== chargeKey) {
  //       chargeValue = ceilBy(value, 10);
  //     }
  //     setLiteSetCharge(newCourse);
  //     setLiteSetChargeValue(chargeValue);
  //     handleChange(chargeValue, newCourse);
  //   }
  // }

  useEffect(() => {
    // savedUserInputList が空の時は初期処理を行う
    if (!state.updateForm.savedUserInputList.length) {
      // 基本プロジェクトの初期値を設定
      const pjAmount = (projectChargeDefault.amount || 0) as number;
      setProjectCharge(projectChargeDefault);
      setProjectChargeValue(pjAmount);

      // コース変更の再評価（次年度更新の場合のみ）
      if (pjAmount >= 0 && pageId === 'renew') {
        estimateProjectCourse(pjAmount, projectChargeDefault.productRatePlanChargeKey, state.courseInfo.basicProjectList);
      }

      // プライム契約
      const primePjAmount = (primeProjectChargeDefault.amount || 0) as number;
      setPrimeProjectCharge(primeProjectChargeDefault);
      setPrimeProjectChargeValue(primePjAmount);

      // コース変更の再評価（次年度更新の場合のみ）
      if (primePjAmount >= 0 && pageId === 'renew') {
        estimatePrimeProjectCourse(primePjAmount, primeProjectChargeDefault.productRatePlanChargeKey, state.courseInfo.primeProjectList);
      }

      // TODO: Lite表示時以下を有効にする
      // Lite契約
      // const litePjAmount = (liteProjectChargeDefault.amount || 0) as number;
      // setLiteProjectCharge(liteProjectChargeDefault);
      // setLiteProjectChargeValue(litePjAmount);

      // // コース変更の再評価（次年度更新の場合のみ）
      // if (litePjAmount >= 0 && pageId === 'renew') {
      //   estimateLiteProjectCourse(litePjAmount, liteProjectChargeDefault.productRatePlanChargeKey, state.courseInfo.liteProjectList);
      // }

      // TODO: Lite表示時以下を有効にする
      // Liteプライム契約
      // const litePrimePjAmount = (litePrimeProjectChargeDefault.amount || 0) as number;
      // setLitePrimeProjectCharge(litePrimeProjectChargeDefault);
      // setLitePrimeProjectChargeValue(litePrimePjAmount);

      // // コース変更の再評価（次年度更新の場合のみ）
      // if (litePrimePjAmount >= 0 && pageId === 'renew') {
      //   estimateLitePrimeProjectCourse(
      //     litePrimePjAmount,
      //     litePrimeProjectChargeDefault.productRatePlanChargeKey,
      //     state.courseInfo.litePrimeProjectList
      //   );
      // }

      // TODO: Lite表示時以下を有効にする
      // LiteSet契約
      // const liteSetAmount = (liteSetChargeDefault.amount || 0) as number;
      // setLiteSetCharge(liteSetChargeDefault);
      // setLiteSetChargeValue(liteSetAmount);

      // // コース変更の再評価（次年度更新の場合のみ）
      // if (liteSetAmount >= 0 && pageId === 'renew') {
      //   estimateLiteSetCourse(liteSetAmount, liteSetChargeDefault.productRatePlanChargeKey, state.courseInfo.liteSetList);
      // }

      // バックアップのトグル設定
      setBackupChargeDisable(!allBackupInitial.contractStatus);
      // TODO: Lite表示時以下を有効にする
      // setLiteBackupChargeDisable(!allBackupInitial.contractStatus);
    }
    // eslint-disable-next-line
  }, [state]);

  interface IDataSource {
    key: OwnerContractProductMapKey;
    productName: any;
    productMenu: any;
    currentContract: any;
    add: any;
    unitCost: any;
    subtotal: any;
    rowSpan: number;
    product: any;
    background: boolean;
  }

  const columns = [
    {
      title: 'サービス内容',
      dataIndex: 'productName',
      width: '25%',
      colSpan: 2,
      render: (value: any, row: IDataSource, index: any) => {
        const obj = {
          children: value,
          props: {} as any
        };
        obj.props.rowSpan = row.rowSpan;
        return obj;
      }
    },
    {
      title: 'サブメニュー',
      dataIndex: 'productMenu',
      width: '25%',
      className: 'left',
      colSpan: 0
    },
    {
      title: '契約内容',
      dataIndex: 'currentContract',
      className: 'center'
    },
    {
      title: '変更',
      dataIndex: 'add',
      render: (value: any, row: IDataSource, index: any) => {
        const obj = {
          children: value,
          props: {} as any
        };
        if (row.key === Const.DATALINK_INITIAL) {
          obj.props.rowSpan = 2;
        }
        if (row.key === Const.DATALINK_CHARGE) {
          obj.props.rowSpan = 0;
        }
        return obj;
      },
      className: 'center'
    },
    {
      title: '単価',
      dataIndex: 'unitCost',
      className: 'right'
    },
    {
      title: '小計',
      dataIndex: 'subtotal',
      width: '18%',
      className: 'right'
    }
  ];

  // プロジェクト利用料入力の非活性
  // 契約変更画面かつ契約済みのときは入力できない
  const isDisabledDefault = (productDefault: IOneProduct): boolean => {
    return isSelectCalender || (pageId !== 'renew' && productDefault.contractStatus);
  };

  // プロジェクト利用料（追加分）入力の非活性
  // 年度更新画面では入力不可 契約変更画面で未契約の場合は入力不可
  const isDisabledAdd = (productDefault: IOneProduct): boolean => {
    return isSelectCalender || pageId === 'renew' || !productDefault.contractStatus;
  };

  // 超過対応：プロジェクト利用料入力可否
  let disableProjectChargeForOverCharge = false;
  // 超過対応：プライムプロジェクト利用料入力可否
  let disablePrimeProjectChargeForOverCharge = false;
  // 超過対応：バックアップ利用料（追加分）入力可否
  let disableBackupChargeForOverCharge = false;

  const currentProjectChargeKey = projectCharge?.productRatePlanChargeKey
    ? projectCharge.productRatePlanChargeKey
    : projectChargeDefault.productRatePlanChargeKey;
  if (!currentProjectChargeKey.includes('ACharge')) {
    disableBackupChargeForOverCharge = true;
    if (pageId !== 'renew') {
      disableProjectChargeForOverCharge = true;
      if (!primeProjectChargeDefault.productRatePlanChargeKey.includes('ACharge')) {
        disablePrimeProjectChargeForOverCharge = true;
      }
    }
  }

  // プロジェクト利用料 計算結果
  const subtotalDefault = (productDefault: IOneProduct, product: IOneProduct): string => {
    return pageId !== 'renew' && productDefault.contractStatus
      ? 'ー'
      : renderPrices(product.productRatePlanChargeKey, state.updateForm.subtotal, state.updateForm.savedUserInputList);
  };

  // プロジェクト利用料（追加分）計算結果
  const subtotalAdd = (productDefault: IOneProduct, product: IOneProduct): string => {
    return pageId === 'renew' || !productDefault.contractStatus
      ? 'ー'
      : renderPrices(product.productRatePlanChargeKey, state.updateForm.subtotal, state.updateForm.savedUserInputList);
  };

  // プロジェクト利用料のフォーマッター カンマを追加する
  // 10プロジェクトごとの申し込みなら1の位を切り捨てる
  const projectFormatter = (value: any, product: IOneProduct): any => {
    if (!isNaN(value)) {
      const num = Math.floor(value / product.salesUnit) * product.salesUnit;
      return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // 数字以外の入力を取り除きます。
  const inputParser = (value: any): any => {
    return value ? value.replace(/\D/g, '') : '';
  };

  const notice = (condition: boolean): JSX.Element => {
    return condition ? <div className="notice">※半角数字のみ入力できます</div> : <></>;
  };

  const dataSource: IDataSource[] = [
    {
      key: 'Project_Initial_gray',
      productName: '初期設定料金',
      productMenu: '',
      currentContract: '',
      add: '',
      unitCost: '',
      subtotal: '',
      rowSpan: 1,
      product: '',
      background: true
    },
    {
      key: Const.ALL_INITIAL,
      productName: /*allInitial.productName*/ 'オーナー基本サービス',
      productMenu: /*allInitial.productRatePlanName*/ '初期設定料金',
      currentContract: allInitial.contractStatus ? '利用あり' : '利用なし',
      add: allInitial.contractStatus ? (
        '契約中'
      ) : (
        <Switch checkedChildren="利用" unCheckedChildren="停止" defaultChecked={allInitial.contractStatus} />
      ),
      unitCost: jpyFormater(allInitial.unitCost),
      subtotal: renderPrices(allInitial.productRatePlanChargeKey, state.updateForm.subtotal, state.updateForm.savedUserInputList),
      rowSpan: 1,
      product: allInitial,
      background: false
    },
    {
      key: Const.ALLBACKUP_INITIAL,
      productName: /*allBackupInitial.productName*/ 'データバックアップサービス',
      productMenu: /*allBackupInitial.productRatePlanName*/ '初期設定料金',
      currentContract: allBackupInitial.contractStatus ? '利用あり' : '利用なし',
      add: allBackupInitial.contractStatus
        ? '契約中'
        : renderToolTip(
            <Switch
              checkedChildren="利用"
              unCheckedChildren="停止"
              onChange={value => {
                setBackupChargeDisable(!value);
                // TODO:Lite表示時は以下を有効にする
                // setLiteBackupChargeDisable(!value);
                // OFFの時はBackupChargeも0にする。
                if (!value) {
                  setBackupChargeValue(0);
                  // TODO:Lite表示時は以下を有効にする
                  // setLiteBackupChargeValue(0);
                  onSaveInputValues(0, backupCharge);
                  onSaveInputValues(0, liteBackupCharge);
                }
                handleChange(value, allBackupInitial);
              }}
              defaultChecked={allBackupInitial.contractStatus}
              disabled={toggleDisabled(allBackupInitial, isRenewed)}
            />,
            allBackupInitial,
            isRenewed
          ),
      unitCost: jpyFormater(allBackupInitial.unitCost),
      subtotal: renderPrices(allBackupInitial.productRatePlanChargeKey, state.updateForm.subtotal, state.updateForm.savedUserInputList),
      rowSpan: 1,
      product: allBackupInitial,
      background: false
    },
    {
      key: 'Project_gray',
      productName: '施工体制台帳要作成現場向け',
      productMenu: '',
      currentContract: '',
      add: '',
      unitCost: '',
      subtotal: '',
      rowSpan: 1,
      product: '',
      background: true
    },
    {
      key: 'Project_Charge',
      productName: 'オーナー基本サービス',
      productMenu: 'グリーンサイト ' + projectCharge.productRatePlanName,
      currentContract: projectChargeDefault.contractStatus ? numberFormater(projectChargeDefault.amount) : '利用なし',
      add: (
        <>
          <InputNumber
            value={projectChargeValue}
            // TODO: Lite対応時はminを0に変更する
            min={10}
            step={projectCharge.salesUnit}
            onChange={value => {
              if (value || value === 0) {
                estimateProjectCourse(value as number, projectChargeDefault.productRatePlanChargeKey, state.courseInfo.basicProjectList);
              }
            }}
            disabled={isDisabledDefault(projectChargeDefault)}
            formatter={(value: any) => {
              return projectFormatter(value, projectCharge);
            }}
            parser={inputParser}
          />
          {notice(!isDisabledDefault(projectChargeDefault))}
        </>
      ),
      unitCost: jpyFormater(projectCharge.unitCost),
      subtotal: subtotalDefault(projectChargeDefault, projectCharge),
      rowSpan: 2,
      product: projectCharge,
      background: false
    },
    {
      key: 'Project_Charge_Add',
      productName: projectCharge.productName,
      productMenu: 'グリーンサイト' + projectCharge.productRatePlanName + '（追加分）',
      currentContract: 'ー',
      add: renderToolTipCharge(
        <>
          <InputNumber
            value={projectChargeAddValue}
            min={0}
            step={projectCharge.salesUnit}
            onChange={value => {
              if (value || value === 0) {
                setProjectChargeAddValue(value as number);
                handleChange(projectChargeValue + value, projectCharge);
              }
            }}
            disabled={isDisabledAdd(projectChargeDefault) || disableProjectChargeForOverCharge}
            formatter={(value: any) => {
              return projectFormatter(value, projectCharge);
            }}
            parser={inputParser}
          />
          {notice(!(isDisabledAdd(projectChargeDefault) || disableProjectChargeForOverCharge))}
        </>,
        disableProjectChargeForOverCharge
      ),
      unitCost: jpyFormater(projectCharge.unitCost),
      subtotal: subtotalAdd(projectChargeDefault, projectCharge),
      rowSpan: 0,
      product: projectCharge,
      background: false
    },
    {
      key: Const.BACKUP_CHARGE,
      productName: backupCharge.productName,
      productMenu: /*backupCharge.productRatePlanName + '（追加分）'*/ 'グリーンサイト一括バックアップ（追加分）',
      currentContract: 'ー',
      add: renderToolTipCharge(
        <>
          <InputNumber
            value={backupChargeValue}
            min={0}
            defaultValue={0}
            step={backupCharge.salesUnit}
            onChange={value => {
              setBackupChargeValue(value as number);
              handleChange(value, backupCharge);
            }}
            disabled={isSelectCalender || backupChargeDisable || disableBackupChargeForOverCharge}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={inputParser}
          />
          {notice(!(isSelectCalender || backupChargeDisable || disableBackupChargeForOverCharge))}
        </>,
        disableBackupChargeForOverCharge
      ),
      unitCost: jpyFormater(backupCharge.unitCost),
      subtotal: renderPrices(backupCharge.productRatePlanChargeKey, state.updateForm.subtotal, state.updateForm.savedUserInputList),
      rowSpan: 1,
      product: backupCharge,
      background: false
    },
    {
      key: 'PrimeProject_Charge',
      productName: 'オーナープライムサービス',
      productMenu: 'グリーンサイト' + primeProjectCharge.productRatePlanName,
      currentContract: primeProjectChargeDefault.contractStatus ? numberFormater(primeProjectChargeDefault.amount) : '利用なし',
      add: (
        <>
          <InputNumber
            value={primeProjectChargeValue}
            min={0}
            step={primeProjectCharge.salesUnit}
            onChange={value => {
              if (value || value === 0) {
                estimatePrimeProjectCourse(
                  value as number,
                  primeProjectChargeDefault.productRatePlanChargeKey,
                  state.courseInfo.primeProjectList
                );
              }
            }}
            disabled={isDisabledDefault(primeProjectChargeDefault)}
            formatter={(value: any) => {
              return projectFormatter(value, primeProjectCharge);
            }}
            parser={inputParser}
          />
          {notice(!isDisabledDefault(primeProjectChargeDefault))}
        </>
      ),
      unitCost: jpyFormater(primeProjectCharge.unitCost),
      subtotal: subtotalDefault(primeProjectChargeDefault, primeProjectCharge),
      rowSpan: 2,
      product: primeProjectCharge,
      background: false
    },
    {
      key: 'PrimeProject_Charge_Add',
      productName: primeProjectCharge.productName,
      productMenu: 'グリーンサイト' + primeProjectCharge.productRatePlanName + '（追加分）',
      currentContract: 'ー',
      add: renderToolTipCharge(
        <>
          <InputNumber
            value={primeProjectChargeAddValue}
            min={0}
            step={primeProjectCharge.salesUnit}
            onChange={value => {
              if (value || value === 0) {
                setPrimeProjectChargeAddValue(value);
                handleChange(primeProjectChargeValue + value, primeProjectCharge);
              }
            }}
            disabled={isDisabledAdd(primeProjectChargeDefault) || disablePrimeProjectChargeForOverCharge}
            formatter={(value: any) => {
              return projectFormatter(value, primeProjectCharge);
            }}
            parser={inputParser}
          />
          {notice(!(isDisabledAdd(primeProjectChargeDefault) || disablePrimeProjectChargeForOverCharge))}
        </>,
        disablePrimeProjectChargeForOverCharge
      ),
      unitCost: jpyFormater(primeProjectCharge.unitCost),
      subtotal: subtotalAdd(primeProjectChargeDefault, primeProjectCharge),
      rowSpan: 0,
      product: primeProjectCharge,
      background: false
    },
    // TODO: Lite表示時以下を有効にする
    // {
    //   key: 'Project_Lite_gray',
    //   productName: '施工設定台帳不要小規模物件向け',
    //   productMenu: '',
    //   currentContract: '',
    //   add: '',
    //   unitCost: '',
    //   subtotal: '',
    //   rowSpan: 1,
    //   product: '',
    //   background: true
    // },
    // {
    //   key: 'LiteProject_Charge',
    //   productName: 'オーナー基本サービス',
    //   productMenu: 'グリーンサイトLite ' + liteProjectCharge.productRatePlanName,
    //   currentContract: liteProjectChargeDefault.contractStatus ? numberFormater(liteProjectChargeDefault.amount) : '利用なし',
    //   add: (
    //     <>
    //       <InputNumber
    //         value={liteProjectChargeValue}
    //         min={0}
    //         step={liteProjectCharge.salesUnit}
    //         onChange={value => {
    //           if (value || value === 0) {
    //             estimateLiteProjectCourse(
    //               value as number,
    //               liteProjectChargeDefault.productRatePlanChargeKey,
    //               state.courseInfo.liteProjectList
    //             );
    //           }
    //         }}
    //         disabled={isDisabledDefault(liteProjectChargeDefault) || (pageId !== 'renew' && liteSetChargeDefault.contractStatus)}
    //         formatter={(value: any) => {
    //           return projectFormatter(value, liteProjectCharge);
    //         }}
    //         parser={inputParser}
    //       />
    //       {notice(!isDisabledDefault(liteProjectChargeDefault) && !(pageId !== 'renew' && liteSetChargeDefault.contractStatus))}
    //     </>
    //   ),
    //   unitCost: jpyFormater(liteProjectCharge.unitCost),
    //   subtotal: subtotalDefault(liteProjectChargeDefault, liteProjectCharge),
    //   rowSpan: 2,
    //   product: liteProjectCharge,
    //   background: false
    // },
    // {
    //   key: 'LiteProject_Charge_Add',
    //   productName: liteProjectCharge.productName,
    //   productMenu: 'グリーンサイトLite ' + liteProjectCharge.productRatePlanName + '（追加分）',
    //   currentContract: 'ー',
    //   add: (
    //     <>
    //       <InputNumber
    //         value={liteProjectChargeAddValue}
    //         min={0}
    //         step={liteProjectCharge.salesUnit}
    //         onChange={value => {
    //           if (value || value === 0) {
    //             setLiteProjectChargeAddValue(value as number);
    //             handleChange(liteProjectChargeValue + value, liteProjectCharge);
    //           }
    //         }}
    //         disabled={isDisabledAdd(liteProjectChargeDefault)}
    //         formatter={(value: any) => {
    //           return projectFormatter(value, liteProjectCharge);
    //         }}
    //         parser={inputParser}
    //       />
    //       {notice(!isDisabledAdd(liteProjectChargeDefault))}
    //     </>
    //   ),
    //   unitCost: jpyFormater(liteProjectCharge.unitCost),
    //   subtotal: subtotalAdd(liteProjectChargeDefault, liteProjectCharge),
    //   rowSpan: 0,
    //   product: liteProjectCharge,
    //   background: false
    // },
    // {
    //   key: 'LiteBackup_Charge',
    //   productName: 'データバックアップサービス',
    //   productMenu: 'グリーンサイトLite 一括バックアップ（追加分）',
    //   currentContract: 'ー',
    //   add: (
    //     <>
    //       <InputNumber
    //         value={liteBackupChargeValue}
    //         min={0}
    //         defaultValue={0}
    //         step={liteBackupCharge.salesUnit}
    //         onChange={value => {
    //           setLiteBackupChargeValue(value as number);
    //           handleChange(value, liteBackupCharge);
    //         }}
    //         disabled={isSelectCalender || liteBackupChargeDisable}
    //         formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //         parser={inputParser}
    //       />
    //       {notice(!(isSelectCalender || liteBackupChargeDisable))}
    //     </>
    //   ),
    //   unitCost: jpyFormater(liteBackupCharge.unitCost),
    //   subtotal: renderPrices(liteBackupCharge.productRatePlanChargeKey, state.updateForm.subtotal, state.updateForm.savedUserInputList),
    //   rowSpan: 1,
    //   product: liteBackupCharge,
    //   background: false
    // },
    // {
    //   key: 'LitePrimeProject_Charge',
    //   productName: 'オーナープライムサービス',
    //   productMenu: 'グリーンサイトLite ' + litePrimeProjectCharge.productRatePlanName,
    //   currentContract: litePrimeProjectChargeDefault.contractStatus ? numberFormater(litePrimeProjectChargeDefault.amount) : '利用なし',
    //   add: (
    //     <>
    //       <InputNumber
    //         value={litePrimeProjectChargeValue}
    //         min={0}
    //         step={litePrimeProjectCharge.salesUnit}
    //         onChange={value => {
    //           if (value || value === 0) {
    //             estimateLitePrimeProjectCourse(
    //               value as number,
    //               litePrimeProjectChargeDefault.productRatePlanChargeKey,
    //               state.courseInfo.litePrimeProjectList
    //             );
    //           }
    //         }}
    //         disabled={isDisabledDefault(litePrimeProjectChargeDefault) || (pageId !== 'renew' && liteSetChargeDefault.contractStatus)}
    //         formatter={(value: any) => {
    //           return projectFormatter(value, litePrimeProjectCharge);
    //         }}
    //         parser={inputParser}
    //       />
    //       {notice(!isDisabledDefault(litePrimeProjectChargeDefault) && !(pageId !== 'renew' && liteSetChargeDefault.contractStatus))}
    //     </>
    //   ),
    //   unitCost: jpyFormater(litePrimeProjectCharge.unitCost),
    //   subtotal: subtotalDefault(litePrimeProjectChargeDefault, litePrimeProjectCharge),
    //   rowSpan: 2,
    //   product: litePrimeProjectCharge,
    //   background: false
    // },
    // {
    //   key: 'LitePrimeProject_Charge_Add',
    //   productName: litePrimeProjectCharge.productName,
    //   productMenu: 'グリーンサイトLite ' + litePrimeProjectCharge.productRatePlanName + '（追加分）',
    //   currentContract: 'ー',
    //   add: (
    //     <>
    //       <InputNumber
    //         value={litePrimeProjectChargeAddValue}
    //         min={0}
    //         step={litePrimeProjectCharge.salesUnit}
    //         onChange={value => {
    //           if (value || value === 0) {
    //             setLitePrimeProjectChargeAddValue(value);
    //             handleChange(litePrimeProjectChargeValue + value, litePrimeProjectCharge);
    //           }
    //         }}
    //         disabled={isDisabledAdd(litePrimeProjectChargeDefault)}
    //         formatter={(value: any) => {
    //           return projectFormatter(value, litePrimeProjectCharge);
    //         }}
    //         parser={inputParser}
    //       />
    //       {notice(!isDisabledAdd(litePrimeProjectChargeDefault))}
    //     </>
    //   ),
    //   unitCost: jpyFormater(litePrimeProjectCharge.unitCost),
    //   subtotal: subtotalAdd(litePrimeProjectChargeDefault, litePrimeProjectCharge),
    //   rowSpan: 0,
    //   product: litePrimeProjectCharge,
    //   background: false
    // },
    // {
    //   key: 'LiteSet_Charge',
    //   productName: 'グリーンサイトLiteプライムサービスセット',
    //   productMenu: liteSetCharge.productRatePlanName,
    //   currentContract: liteSetChargeDefault.contractStatus ? numberFormater(liteSetChargeDefault.amount) : '利用なし',
    //   add: (
    //     <>
    //       <InputNumber
    //         value={liteSetChargeValue}
    //         min={0}
    //         step={liteSetCharge.salesUnit}
    //         onChange={value => {
    //           if (value || value === 0) {
    //             estimateLiteSetCourse(value as number, liteSetChargeDefault.productRatePlanChargeKey, state.courseInfo.liteSetList);
    //           }
    //         }}
    //         disabled={isDisabledDefault(liteSetChargeDefault) || (pageId !== 'renew' && liteProjectChargeDefault.contractStatus)}
    //         formatter={(value: any) => {
    //           return projectFormatter(value, liteSetCharge);
    //         }}
    //         parser={inputParser}
    //       />
    //       {notice(!isDisabledDefault(liteSetChargeDefault) && !(pageId !== 'renew' && liteProjectChargeDefault.contractStatus))}
    //     </>
    //   ),
    //   unitCost: jpyFormater(liteSetCharge.unitCost),
    //   subtotal: subtotalDefault(liteSetChargeDefault, liteSetCharge),
    //   rowSpan: 2,
    //   product: liteSetCharge,
    //   background: false
    // },
    // {
    //   key: 'LiteSet_Charge_Add',
    //   productName: liteSetCharge.productName,
    //   productMenu: liteSetCharge.productRatePlanName + '（追加分）',
    //   currentContract: 'ー',
    //   add: (
    //     <>
    //       <InputNumber
    //         value={liteSetChargeAddValue}
    //         min={0}
    //         step={liteSetCharge.salesUnit}
    //         onChange={value => {
    //           if (value || value === 0) {
    //             setLiteSetChargeAddValue(value);
    //             handleChange(liteSetChargeValue + value, liteSetCharge);
    //           }
    //         }}
    //         disabled={isDisabledAdd(liteSetChargeDefault)}
    //         formatter={(value: any) => {
    //           return projectFormatter(value, liteSetCharge);
    //         }}
    //         parser={inputParser}
    //       />
    //       {notice(!isDisabledAdd(liteSetChargeDefault))}
    //     </>
    //   ),
    //   unitCost: jpyFormater(liteSetCharge.unitCost),
    //   subtotal: subtotalAdd(liteSetChargeDefault, liteSetCharge),
    //   rowSpan: 0,
    //   product: liteSetCharge,
    //   background: false
    // },
    {
      key: 'Project_co_gray',
      productName: '共通',
      productMenu: '',
      currentContract: '',
      add: '',
      unitCost: '',
      subtotal: '',
      rowSpan: 1,
      product: '',
      background: true
    },
    {
      key: Const.DATALINK_INITIAL,
      productName: (
        <>
          <div>{dataLinkInitial.productName}</div>
          <div>※貴社にて別途、システム開発が必要です。</div>
          <div>※CCUSデータ連携サービスとは異なります。</div>
        </>
      ),
      productMenu: dataLinkInitial.productRatePlanName,
      currentContract: dataLinkInitial.contractStatus ? '利用あり' : '利用なし',
      add: dataLinkInitial.contractStatus
        ? '契約中'
        : renderToolTip(
            <Switch
              checkedChildren="利用"
              unCheckedChildren="停止"
              onChange={value => handleChange(value, dataLinkInitial)}
              defaultChecked={dataLinkInitial.contractStatus}
              disabled={toggleDisabled(dataLinkInitial, isRenewed)}
            />,
            dataLinkInitial,
            isRenewed
          ),
      unitCost: jpyFormater(dataLinkInitial.unitCost),
      subtotal: renderPrices(dataLinkInitial.productRatePlanChargeKey, state.updateForm.subtotal, state.updateForm.savedUserInputList),
      rowSpan: 2,
      product: dataLinkInitial,
      background: false
    },
    {
      key: Const.DATALINK_CHARGE,
      productName: dataLinkCharge.productName,
      productMenu: dataLinkCharge.productRatePlanName,
      currentContract: dataLinkCharge.contractStatus ? '利用あり' : '利用なし',
      add: '',
      unitCost: jpyFormater(dataLinkCharge.unitCost),
      subtotal: renderPrices(dataLinkCharge.productRatePlanChargeKey, state.updateForm.subtotal, state.updateForm.savedUserInputList),
      rowSpan: 0,
      product: dataLinkCharge,
      background: false
    }
  ];

  return (
    <div>
      <div className="updateContractTable">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
          rowClassName={(record: IDataSource, index: any): string => {
            return record.background ? 'gray' : '';
          }}
        />
      </div>
    </div>
  );
}
