import React, { useState } from 'react';
import moment from 'moment';
import { Col } from 'antd';
import { onSetTerm, onGetUpdateProducList } from '../../../domain/middleware';
import { refreshContractData } from '../../../domain/reducers';
import { IState } from '../../../domain/model';
import './contractUpdateForm.css';
import { yyyymmddFormatter, isChangedData } from '../../../domain/common';
import MonthPicker from '../formParts/monthPicker';
import BtnGroup from '../formParts/btnGroup';
import TotalPriceArea from '../formParts/totalPriceArea';
import CustomerRemarks from '../formParts/customerRemark';
import { CalcContext, BtnContext, UsableTriggerContext, CalenderContext } from '../formParts/context';
import ContractTable from './contractTable';

export default function ContractUpdateForm(props: any): JSX.Element {
  const [calDisable, setCalDisable] = useState(true);
  const [btnDisable, setBtnDisable] = useState(true);
  const [usableTrigger, setUsableTrigger] = useState(true);
  const [isSelectCalender, setCalender] = useState(true);

  const state: IState = props.state;

  const currentDate = state.productList && state.productList.currentDate ? moment(state.productList.currentDate).format('YYYY/MM/DD') : '';
  const targetYM = state.updateForm.contractEffectiveDate ? moment(state.updateForm.contractEffectiveDate).format('YYYY/MM') : '';
  // Notificationのリストから対象ServiceNameの通知可否を取得する
  const notification = state.notifyInfo.find(n => n.serviceName === props.serviceName);
  const isRenewed = notification ? notification.isRenewed : false;

  function checkElementHidden(): React.CSSProperties {
    const style = {
      padding: '0px 10px'
    } as React.CSSProperties;

    if (!state.updateForm.remainTerm) {
      if (calDisable) {
        // hiddenで非表示
        style.visibility = 'hidden';
      } else {
        style.visibility = 'visible';
      }
    } else {
      style.visibility = 'visible';
    }

    return style;
  }

  // リストが入っていない場合は非表示とする領域
  let categoryTitle: any = '';
  let contractNotify: any = '';
  let totalPriceArea: any = '';
  let customerRemark: any = '';

  if (state.productList.productList.length > 0) {
    categoryTitle = (
      <div className="TitleAndDate">
        <span>{'【' + props.category + 'サービス】'}</span>
        <span style={checkElementHidden()} className="remainTerm">
          (お申込みのサービスサイクル：{state.updateForm.remainTerm})
        </span>
      </div>
    );
    contractNotify = (
      <div className="contractStateNotify">
        {currentDate}時点の申込状況を元に{targetYM}時点の契約状況を表示しています
      </div>
    );
    customerRemark = <CustomerRemarks />;
    totalPriceArea = <TotalPriceArea total={state.updateForm.total} pageId={'update'} />;
  }

  /* eslint-disable complexity */
  function disabledDate(current: any): any {
    //引数currentは月＋１
    const today = state.productList.currentDate;
    const currentDay = moment(new Date(today)); //今日
    const startDay = moment(new Date(today)).startOf('month'); //今月の1日の日
    const endOf3week = moment(startDay).add(23, 'days'); //今月の24日の日
    const twoMonthsFromToday = moment(startDay).add(2, 'months'); // 今月1日から2ヶ月進めた日
    const endDay = moment(props.renewedEndDate).startOf('month'); //契約最終月
    const isUntilTwentyFour = moment(currentDay).isBetween(startDay, endOf3week, 'day', '[]'); //今日が1日～24日の間ならTrue

    if (twoMonthsFromToday < endDay) {
      //今月の2ヶ月後が契約最終月を越えなければTrue
      if (isUntilTwentyFour) {
        //今月・来月・再来月
        return (current && current < startDay) || moment(twoMonthsFromToday).add(1, 'months') < current;
      } else {
        //来月・再来月
        return (current && current < moment(startDay).add(1, 'months')) || moment(twoMonthsFromToday).add(1, 'months') < current;
      }
    } else {
      //最終契約月まで2ヶ月以内
      if (moment(twoMonthsFromToday).isSame(endDay, 'month')) {
        //今月+2ヶ月＝最終契約月
        if (isUntilTwentyFour) {
          //今月・来月・再来月のみ選択可能
          return (current && current < startDay) || moment(twoMonthsFromToday).add(1, 'months') < current;
        } else {
          //来月・再来月のみ選択可能
          return (current && current < moment(startDay).add(1, 'months')) || moment(twoMonthsFromToday).add(1, 'months') < current;
        }
      } else if (moment(moment(startDay).add(1, 'months')).isSame(endDay, 'month')) {
        //今月+1ヶ月＝最終契約月
        if (isUntilTwentyFour) {
          //今月・来月のみ選択可能
          return (current && current < startDay) || moment(endDay).add(1, 'months') < current;
        } else {
          //来月のみ選択可能
          return (current && current < moment(startDay).add(1, 'months')) || moment(endDay).add(1, 'months') < current;
        }
      } else {
        //今月＝最終契約月
        if (isUntilTwentyFour) {
          //今月のみ選択可能
          return (current && current < startDay) || moment(endDay).add(1, 'months') < current;
        } else {
          //選択不可
          return (current && current < startDay) || moment(endDay) < current;
        }
      }
    }
  }

  function changeMonth(date: any, dateString: string): void {
    refreshContractData();
    if (state.updateForm.remainMonths !== '') {
      setCalDisable(!isChangedData());
    }

    // 日付文字列がYYYY/MM形式のためYYYY-MM-DD形式に変換する
    // スラッシュ区切りが今後のバージョンアップで対応されなくなることを考慮
    const standardDateString = moment(dateString, 'YYYY/MM').format('YYYY-MM-DD');

    onSetTerm(standardDateString, props.endDate, isRenewed, props.renewedEndDate);
    onGetUpdateProducList(props.serviceName, props.category, yyyymmddFormatter(standardDateString));
    setCalender(false);
    setCalDisable(true);
  }

  return (
    <div>
      <CalcContext.Provider value={[calDisable, setCalDisable]}>
        <BtnContext.Provider value={[btnDisable, setBtnDisable]}>
          <UsableTriggerContext.Provider value={[usableTrigger, setUsableTrigger]}>
            <CalenderContext.Provider value={[isSelectCalender, setCalender]}>
              <Col span={22}>
                <h2>
                  契約内容：{props.serviceName}
                  {props.cycle}
                </h2>
                <MonthPicker
                  id="monthPicker"
                  placeholder={'契約開始月'}
                  onChange={(date: any, dateString: string) => changeMonth(date, dateString)}
                  disabledDate={(current: any) => disabledDate(current)}
                />
                <span className="monthPickerMsg">&emsp;←のカレンダーから利用開始月を選択してください。</span>
                {categoryTitle}
                {contractNotify}
                {state.productList.productList.length > 0 && <ContractTable state={state} pageId={'update'} />}
              </Col>
              {customerRemark}
              {totalPriceArea}
              <BtnGroup pageId={'update'} subscriptionName={props.subscriptionName} />
            </CalenderContext.Provider>
          </UsableTriggerContext.Provider>
        </BtnContext.Provider>
      </CalcContext.Provider>
    </div>
  );
}
