import React from 'react';
import { Table, Icon, Button, Modal, Descriptions, Input, Popover } from 'antd';
import { getState } from '../../../domain/store';
import * as common from '../../../domain/common';
import './companyDetail.css';
import { IAccountingCompanyDetail } from '../../../domain/model';
import {
  onGetInvoicePDF,
  onGetUserContractInvoiceInfo,
  onPostDeleteInvoicePayment,
  onGetUpdateRefundInfo,
  onError,
  onPutRemark,
  onGetToken,
  onClearToken
} from '../../../domain/middleware';
import UpdateRefundModal from './updateRefundModal';
import { ColumnProps, SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';

interface IDataSource {
  createdDate: string;
  createdDateTime: string;
  tranNumber: string;
  targetDate: string;
  digest: string;
  invoiceNumber: any;
  zuoraInvoiceNumber: any;
  invoiceAmount: any;
  invoiceId?: string;
  isReconciled: any;
  dueDate: any;
  paymentAmount: any;
  payerName?: string;
  execute?: any;
  category: any;
  transferCompany: any;
  isRefunded?: boolean;
  remark: any;
}

export default function CompanyDetail(props: any): JSX.Element {
  const detailList = getState().accountingDetailList;
  const summary = getState().accountingSummary;
  const isAdminMCDPUser = getState().loginInfo.managerFlag === '6';

  const DispDate = (date: string): JSX.Element => {
    return <div>{date}</div>;
  };

  const sortByCreateDate = (d1: IDataSource, d2: IDataSource): number => {
    const date1 = d1.createdDateTime;
    const date2 = d2.createdDateTime;

    if (moment(date1).isSame(moment(date2))) {
      if (d1.tranNumber > d2.tranNumber) {
        return 1;
      } else {
        return -1;
      }
    } else if (moment(date1) > moment(date2)) {
      return 1;
    } else {
      return -1;
    }
  };

  const sortByTargetDate = (d1: IDataSource, d2: IDataSource, order?: SortOrder): number => {
    const date1 = d1.targetDate;
    const date2 = d2.targetDate;
    if (date1 === '' && date2 === '') {
      return sortByCreateDate(d1, d2);
    }
    // ブランクの行はソート順に関係なく後回しにする
    if (date1 === '') {
      return order === 'descend' ? -1 : 1;
    }
    if (date2 === '') {
      return order === 'descend' ? 1 : -1;
    }

    if (moment(date1).isSame(moment(date2))) {
      return sortByCreateDate(d1, d2);
    } else if (moment(date1) > moment(date2)) {
      return 1;
    } else {
      return -1;
    }
  };

  const columns: ColumnProps<IDataSource>[] = [
    {
      title: '処理日付',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 80,
      render: (text: string) => {
        return DispDate(common.yyyymmddSlashFormatter(text).slice(2));
      },
      sorter: (d1: IDataSource, d2: IDataSource) => sortByCreateDate(d1, d2),
      sortDirections: ['ascend', 'descend', 'ascend'], // 必ずソートさせる
      defaultSortOrder: 'descend'
    },
    {
      title: '請求/入金日',
      dataIndex: 'targetDate',
      key: 'targetDate',
      width: 80,
      render: (text: string) => {
        return DispDate(text === '' ? '' : common.yyyymmddSlashFormatter(text).slice(2));
      },
      sorter: (d1: IDataSource, d2: IDataSource, order?: SortOrder) => sortByTargetDate(d1, d2, order),
      sortDirections: ['ascend', 'descend', 'ascend'] // 必ずソートさせる
    },
    {
      title: '摘要',
      dataIndex: 'digest',
      key: 'digest',
      width: 60
    },
    {
      title: '請求書番号',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 90
    },
    {
      title: 'Zuora請求書番号',
      dataIndex: 'zuoraInvoiceNumber',
      key: 'zuoraInvoiceNumber',
      width: 80
    },
    {
      title: '仕分',
      dataIndex: 'category',
      key: 'category',
      width: 40
    },
    {
      title: '請求額',
      dataIndex: 'invoiceAmount',
      key: 'invoiceAmount',
      className: 'right',
      width: 95
    },
    {
      title: '消込済',
      dataIndex: 'isReconciled',
      key: 'isReconciled',
      width: 50,
      className: 'center'
    },
    {
      title: '支払期日',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 80
    },
    {
      title: '入金額',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      className: 'right',
      width: 95
    },
    {
      title: '振込名義人',
      dataIndex: 'payerName',
      key: 'payerName',
      width: 100
    },
    {
      title: '付替対象企業',
      dataIndex: 'transferCompany',
      key: 'transferCompany',
      width: 120
    },
    {
      title: '',
      dataIndex: 'execute',
      key: 'execute',
      width: 100,
      className: 'center'
    },
    {
      title: '備考',
      dataIndex: 'remark',
      key: 'remark',
      width: 180,
      className: 'left'
    }
  ];
  function getInvoiceInfo(invoice: IAccountingCompanyDetail): any {
    if (invoice.digest === '請求' && invoice.invoiceNumber && invoice.isUserContract) {
      return (
        <div>
          <Button
            className={'userContractInvoiceBtn'}
            onClick={() =>
              onGetUserContractInvoiceInfo(invoice.invoiceNumber as string, invoice.invoiceAmount as number, invoice.dueDate as string)
            }
            icon="info-circle"
          ></Button>
          {invoice.invoiceNumber}
        </div>
      );
    } else if (invoice.digest === '請求' && invoice.invoiceNumber && !invoice.isUserContract && invoice.invoiceId) {
      return (
        <div>
          <Button
            className={'downloadInvoiceBtn'}
            onClick={() => onGetInvoicePDF(invoice.invoiceId as string, invoice.invoiceNumber as string)}
            type="primary"
            icon="download"
          ></Button>
          {invoice.invoiceNumber}
        </div>
      );
    } else {
      return invoice.invoiceNumber;
    }
  }

  // 入金消込取消モーダル
  async function confirmCancelReconcileModal(detail: any): Promise<void> {
    // ワンタイムトークン取得
    await onGetToken();

    function confirmModalContent(): any {
      return (
        <>
          <Descriptions size="small" title="企業情報" layout="vertical" bordered>
            <Descriptions.Item label="企業ID">{summary.companyId}</Descriptions.Item>
            <Descriptions.Item label="企業名" span={2}>
              {summary.companyName}
            </Descriptions.Item>
            <Descriptions.Item label="入金消込処理日付">{common.yyyymmddSlashFormatter(detail.targetDate)}</Descriptions.Item>
            <Descriptions.Item label="請求書番号">{detail.invoiceNumber}</Descriptions.Item>
            <Descriptions.Item label="請求額">{common.jpyFormater(-1 * detail.invoiceAmount)}</Descriptions.Item>
          </Descriptions>
        </>
      );
    }
    const onPostDeleteInvoicePaymentAsync = async (): Promise<void> => {
      try {
        onPostDeleteInvoicePayment(summary.companyId, detail.cbaId);
      } catch (err) {
        onError(err);
      }
    };
    Modal.confirm({
      title: <span>消込取消を実行します。</span>,
      centered: true,
      content: confirmModalContent(),
      width: 600,
      okText: '消込取消を確定',
      cancelText: '取消',
      onOk: onPostDeleteInvoicePaymentAsync,
      onCancel: onClearToken
    });
  }

  function renderExecute(e: any): any {
    if (e.digest === '入金消込') {
      return (
        <Button
          className={'detailExecuteBtn'}
          onClick={() => {
            confirmCancelReconcileModal(e);
          }}
          disabled={!isAdminMCDPUser || !e.canCancelInvoicePayment}
        >
          消込取消
        </Button>
      );
    } else if (e.digest === '返金') {
      return (
        <Button
          className={e.isRefunded ? 'detailExecuteBtn_refunded' : 'detailExecuteBtn'}
          onClick={() => {
            onGetUpdateRefundInfo(summary.companyId, e.cbaId, e.createdDate, e.paymentAmount);
          }}
        >
          返金確定
        </Button>
      );
    }
  }

  // 備考編集モーダル
  async function editRemarkModal(detail: any): Promise<void> {
    // ワンタイムトークン取得
    await onGetToken();

    let remark = detail.remark;
    function editRemarkContent(): any {
      return (
        <Descriptions size="small" layout="vertical" bordered>
          <Descriptions.Item label="備考">
            <Input.TextArea defaultValue={remark} maxLength={255} rows={10} onChange={(e: any) => (remark = e.target.value)}>
              {remark}
            </Input.TextArea>
          </Descriptions.Item>
        </Descriptions>
      );
    }
    const onPostEditRemarkAsync = async (): Promise<void> => {
      try {
        onPutRemark(detail, remark);
      } catch (err) {
        onError(err);
      }
    };
    Modal.confirm({
      title: <span>備考編集</span>,
      centered: true,
      content: editRemarkContent(),
      width: 600,
      okText: '更新',
      cancelText: '取消',
      onOk: onPostEditRemarkAsync,
      onCancel: onClearToken
    });
  }

  // 一覧に表示する備考文の生成
  function shortenRemark(remark: string): string {
    // 改行削除
    remark = remark.replace(/\r?\n/g, '');

    // 11字以上ある場合は文頭のみ残して表示
    if (remark.length > 10) {
      remark = remark.slice(0, 10) + '...';
    }

    return remark;
  }

  const dataSource: IDataSource[] = [];
  /* eslint-disable complexity */
  detailList.forEach(e => {
    const content = (
      <div>
        <pre className="popoverPre">{e.remark}</pre>
      </div>
    );
    dataSource.push({
      createdDate: e.createdDate,
      createdDateTime: e.createdDateTime,
      tranNumber: e.tranNumber,
      targetDate: e.targetDate ? e.targetDate : '',
      digest: e.digest,
      invoiceId: e.invoiceId,
      invoiceNumber: getInvoiceInfo(e),
      zuoraInvoiceNumber: e.zuoraInvoiceNumber,
      category: e.category,
      invoiceAmount:
        e.invoiceAmount && e.invoiceAmount < 0 ? (
          <label style={{ color: 'red' }}>{common.numberFormater(e.invoiceAmount)}</label>
        ) : (
          e.invoiceAmount && common.numberFormater(e.invoiceAmount)
        ),
      isReconciled: e.isReconciled ? '◯' : e.digest === '請求' ? <Icon type="line" /> : '',
      dueDate: e.dueDate ? common.yyyymmddSlashFormatter(e.dueDate).slice(2) : '',
      paymentAmount:
        e.paymentAmount && e.paymentAmount < 0 ? (
          <label style={{ color: 'red' }}>{common.numberFormater(e.paymentAmount)}</label>
        ) : (
          e.paymentAmount && common.numberFormater(e.paymentAmount)
        ),
      payerName: e.payerName,
      execute: renderExecute(e),
      transferCompany:
        (e.transferCompanyId && e.transferCompanyName ? `${e.transferCompanyId}:${e.transferCompanyName}` : '') +
        (e.galileoptInvoiceCompanyName && e.galileoptRequestNo
          ? `（Galileopt請求書企業名：${e.galileoptInvoiceCompanyName}、Galileopt申請No：${e.galileoptRequestNo}）`
          : ''),
      isRefunded: e.isRefunded,
      remark: (
        <div>
          <Button className={'userContractInvoiceBtn'} onClick={() => editRemarkModal(e)} disabled={!isAdminMCDPUser} icon="edit"></Button>
          <Popover content={content}>
            <span>{shortenRemark(e.remark)}</span>
          </Popover>
        </div>
      )
    });
  });
  function scrollSize(): { x?: number; y?: number } {
    return { y: props.windowHeight - 200 };
  }

  return (
    <>
      <Table
        size={'small'}
        scroll={scrollSize()}
        bordered
        className={'commonTable'}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <UpdateRefundModal />
    </>
  );
}
