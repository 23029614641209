import React from 'react';
import { IProps, IIdInvoice } from '../../../domain/model';
import { onDownloadZip, onSearchIdInvoice, onSetIdInvoiceParams } from '../../../domain/middleware';
import { Form, Table, Button, DatePicker } from 'antd';
import { yyyymmddFormatter } from 'domain/common';
import './downloadForm.css';
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';

export function IdInvoiceList({ state }: IProps): JSX.Element {
  const columns = [
    {
      title: 'No',
      dataIndex: 'lineNo',
      className: 'right',
      width: 80
    },
    {
      title: '対象日付',
      dataIndex: 'targetDate',
      className: 'center',
      width: 150
    },
    {
      title: 'ファイル名',
      dataIndex: 'fileName',
      className: 'left'
    },
    {
      title: '登録日時',
      dataIndex: 'insertDatetime',
      className: 'center',
      width: 210
    },
    {
      title: 'ダウンロード',
      dataIndex: 'buttonToDownload',
      className: 'center',
      width: 140
    }
  ];

  interface IDataSource extends IIdInvoice {
    buttonToDownload: JSX.Element;
  }

  const dataSource: IDataSource[] = state.idInvoiceDownload.idInvoiceList.map(row => {
    return {
      ...row,
      buttonToDownload: (
        <Button
          onClick={() => {
            const formatDate = yyyymmddFormatter(row.targetDate);
            onDownloadZip(formatDate);
          }}
        >
          ダウンロード
        </Button>
      )
    };
  });

  const { MonthPicker } = DatePicker;
  const targetDate = state.idInvoiceDownload.params.targetDate;

  return (
    <>
      <Form className="search" layout="inline" onSubmit={onSearchIdInvoice}>
        <Form.Item label="対象日付">
          <MonthPicker
            id="targetDate"
            locale={locale}
            format="YYYY年MM月分"
            defaultValue={targetDate}
            onChange={(date, dateString) => {
              if (date) {
                onSetIdInvoiceParams(date);
              } else {
                onSetIdInvoiceParams();
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button className="searchBtn" htmlType="submit">
            検索
          </Button>
        </Form.Item>
      </Form>
      <Table className={'commonTable'} columns={columns} dataSource={dataSource} rowKey="fileName" pagination={false} size={'small'} />
    </>
  );
}
