import React from 'react';
import { IProps } from '../../domain/model';
import UserContractUpload from '../../components/admin/upload/userContractUpload';

export default function userContractUploadPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <UserContractUpload />
        </>
      )}
    </>
  );
}
