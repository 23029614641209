import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import TopPage from './pages/user/topPage';
import ContractUpdatePage from './pages/user/contractUpdatePage';
import ContractNewPage from './pages/user/contractNewPage';
import CompletedPage from './pages/user/completedPage';
import * as ErrorPage from './pages/shared/errorPage';
import LoginPage from './pages/shared/loginPage';
import LogoutPage from './pages/shared/logoutPage';
import InvoiceDeletePage from './pages/user/invoiceDeletePage';
import UserLoginPage from './pages/user/userLoginPage';
/* TODO: ↓↓↓バックドアが不要になったら削除する。↓↓↓*/
import DummyLoginPage from './dummy/dummyLoginPage';
import CompanySearchPage from './pages/admin/companySearchPage';
import { IState } from './domain/model';
import { getState } from './domain/store';
import smoothscroll from 'smoothscroll-polyfill';
import { Callback } from './pages/shared/callback';
import { navigationTo } from './domain/middleware';
import { getUserContext } from './domain/reducers';
import UserContractUploadPage from './pages/admin/userContractUploadPage';
import AccountingPage from './pages/admin/accountingPage';
import TransferCreditBalancePage from './pages/admin/transferCreditBalancePage';

import IdInvoiceDownloadPage from './pages/admin/idInvoiceDownloadPage';
import UserPaymentUploadPage from './pages/admin/userPaymentUploadPage';
import UserContractStatusMgmtPage from './pages/admin/userContractStatusMgmtPage';
import UserContractStatusMgmtDetailPage from './pages/admin/userContractStatusMgmtDetailPage';
import UserPaymentStatusMgmtPage from './pages/admin/userPaymentStatusMgmtPage';
import UserPaymentStatusMgmtDetailPage from './pages/admin/userPaymentStatusMgmtDetailPage';
import InvoiceDeleteStatusMgmtDetailPage from './pages/admin/invoiceDeleteStatusMgmtDetailPage';
import JournalDataCreationPage from './pages/admin/journalDataCreationPage';
import JournalDataDownloadPage from './pages/admin/journalDataDownloadPage';
import PaymentOkDownloadPage from './pages/admin/paymentOkDownloadPage';
import './components/shared/commonTable.css';

export type AppProps = {
  state: IState;
};

export function App({ state }: AppProps): JSX.Element {
  // kick off the polyfill
  smoothscroll.polyfill();

  function onUpdate(): void {
    const child: any = document.querySelector('.app');
    child.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  /* TODO: ↓↓↓バックドアが不要になったら削除する。↓↓↓*/
  function checkDummyPage(): boolean {
    let isDummy = true;
    const path = window.location.pathname;
    if (path !== '/dummy') {
      isDummy = false;
    }
    return isDummy;
  }

  async function checkReload(): Promise<void> {
    /* TODO:! ↓↓↓バックドアが不要になったら削除する。↓↓↓*/
    if (!checkDummyPage()) {
      if (state.pageId) {
        onUpdate();
      } else {
        const currentState = getState();
        // ログイン処理中はセッションチェックを実施しない
        if ('Init' === currentState.loginStatus || 'Completed' === currentState.loginStatus) {
          const path = window.location.pathname;
          getUserContext();
          if (path.includes('/admin/')) {
            navigationTo('adminSearch');
          } else if (path.includes('/user/')) {
            navigationTo('top');
          } else if (path.includes('/user-contract/invoice/delete')) {
            navigationTo('UserContractUnpaidInvoice');
          }
        }
      }
    }
  }

  useEffect(() => {
    checkReload();
    // eslint-disable-next-line
  }, [state.pageId]);

  /*eslint no-warning-comments: [0, { "location": "anywhere" }]*/

  const getWindowDimensions = (): any => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const onResize = (): any => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <Switch>
      <Route exact path="/user/top">
        <TopPage state={state} />
      </Route>
      <Route exact path="/user/contract/renew">
        <ContractNewPage state={state} />
      </Route>
      <Route exact path="/user/contract/update">
        <ContractUpdatePage state={state} />
      </Route>
      <Route exact path="/user/contract/completed">
        <CompletedPage></CompletedPage>
      </Route>
      <Route exact path="/notFoundError">
        <ErrorPage.NotFound />
      </Route>
      <Route exact path="/authError">
        <ErrorPage.AuthError />
      </Route>
      <Route exact path="/noDataError">
        <ErrorPage.NoDataError />
      </Route>
      <Route exact path="/systemError">
        <ErrorPage.SystemError />
      </Route>
      <Route exact path="/cantuse">
        <ErrorPage.CantUse />
      </Route>
      <Route exact path="/failedCreatingPDFError">
        <ErrorPage.FailedCreatingPDFError />
      </Route>
      <Route exact path="/cantUseCompanyPageError">
        <ErrorPage.CantUseCompanyPageError />
      </Route>
      <Route exact path="/individualContractCompanyError">
        <ErrorPage.IndividualContractCompanyError />
      </Route>
      <Route exact path="/admin/companySearch">
        <CompanySearchPage state={state} windowHeight={windowDimensions.height} windowWidth={windowDimensions.width} />
      </Route>
      <Route exact path="/admin/userContract/upload">
        <UserContractUploadPage state={state} />
      </Route>
      <Route exact path="/admin/accounting">
        <AccountingPage state={state} windowHeight={windowDimensions.height} windowWidth={windowDimensions.width} />
      </Route>
      <Route exact path="/admin/accounting/transfer">
        <TransferCreditBalancePage state={state} windowHeight={windowDimensions.height} windowWidth={windowDimensions.width} />
      </Route>
      <Route exact path="/admin/idInvoiceDownload">
        <IdInvoiceDownloadPage state={state} />
      </Route>
      <Route exact path="/admin/userPayment/upload">
        <UserPaymentUploadPage state={state} />
      </Route>
      <Route exact path="/admin/userContract/status">
        <UserContractStatusMgmtPage state={state} />
      </Route>
      <Route exact path="/admin/userContract/status/detail">
        <UserContractStatusMgmtDetailPage state={state} />
      </Route>
      <Route exact path="/admin/userPayment/status">
        <UserPaymentStatusMgmtPage state={state} />
      </Route>
      <Route exact path="/admin/userPayment/status/detail">
        <UserPaymentStatusMgmtDetailPage state={state} />
      </Route>
      <Route exact path="/admin/invoice-delete/status/detail">
        <InvoiceDeleteStatusMgmtDetailPage state={state} />
      </Route>
      <Route exact path="/admin/journal/create">
        <JournalDataCreationPage state={state} />
      </Route>
      <Route exact path="/admin/journal">
        <JournalDataDownloadPage state={state} />
      </Route>
      <Route exact path="/admin/paymentOk">
        <PaymentOkDownloadPage state={state} />
      </Route>
      <Route exact path="/user-contract/invoice/delete">
        <InvoiceDeletePage state={state} />
      </Route>
      <Route exact path="/auth/callback" component={Callback} />
      <Route exact path="/" component={LoginPage} />
      <Route exact path="/user-contract" component={UserLoginPage} />
      <Route exact path="/logout" component={LogoutPage} />
      {/* TODO: ↓↓↓バックドアが不要になったら削除する。↓↓↓*/}
      <Route
        exact
        path="/dummy"
        component={process.env.REACT_APP_API_BASEURL === 'https://api.invoice.mcdppf.com/v1' ? ErrorPage.NotFound : DummyLoginPage}
      />
      <Route exact path="/dummy" component={DummyLoginPage} />
      <Route path="*">
        <ErrorPage.NotFound />
      </Route>
    </Switch>
  );
}
