import React from 'react';
import { onDownloadJournalData, onSearchJournalData, onSetJournalDataParams } from '../../../domain/middleware';
import { Form, Table, Button, DatePicker } from 'antd';
import { convertJournalStatus } from '../../../domain/common';
import { IProps, IJournalData } from '../../../domain/model';
import './journalDataDownload.css';
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { getState } from '../../../domain/store';
import * as common from '../../../domain/common';

export function JournalDataList({ state }: IProps): JSX.Element {
  const columns = [
    {
      title: 'No',
      dataIndex: 'lineNo',
      className: 'left',
      width: 60
    },
    {
      title: '対象日付',
      dataIndex: 'targetDate',
      className: 'center',
      width: 130
    },
    {
      title: 'ファイル名',
      dataIndex: 'fileName',
      className: 'left'
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      className: 'center'
    },
    {
      title: 'エラーメッセージ',
      dataIndex: 'errorMessage',
      className: 'left'
    },
    {
      title: '登録者',
      dataIndex: 'insertUserName',
      className: 'left',
      width: 150
    },
    {
      title: '登録日時',
      dataIndex: 'insertDatetime',
      className: 'right',
      width: 150
    },
    {
      title: '更新者',
      dataIndex: 'updateUserName',
      className: 'left',
      width: 150
    },
    {
      title: '更新日時',
      dataIndex: 'updateDatetime',
      className: 'right',
      width: 150
    },
    {
      title: 'ダウンロード',
      dataIndex: 'buttonToDownload',
      className: 'center',
      width: 140
    }
  ];

  interface IDataSource extends IJournalData {
    buttonToDownload: JSX.Element;
  }

  const dataSource: IDataSource[] = state.journalDataDownload.journalList.map(row => {
    const disabled = !row.fileName;
    return {
      ...row,
      status: convertJournalStatus(row.status),
      buttonToDownload: (
        <Button onClick={() => onDownloadJournalData(row.journalMgmtId, row.fileName)} disabled={disabled}>
          ダウンロード
        </Button>
      )
    };
  });

  const { MonthPicker } = DatePicker;
  const targetDate = state.journalDataDownload.params.targetDate;

  const expandedRowRenderer = (record: any): any => {
    const columns = [
      { title: '処理', dataIndex: 'processName', key: 'processName', className: 'processName' },
      { title: 'ステータス', dataIndex: 'status', key: 'status', className: 'center' },
      { title: 'エラーメッセージ', dataIndex: 'chErrorMessage', key: 'chErrorMessage' },
      { title: '処理件数', dataIndex: 'totalCount', key: 'totalCount', className: 'count' },
      { title: 'エラー件数', dataIndex: 'errorCount', key: 'errorCount', className: 'count' },
      { title: '登録者', dataIndex: 'insertUserName', key: 'insertUserName' },
      { title: '登録日時', dataIndex: 'insertDatetime', key: 'chInsertDate', className: 'dateColumn' },
      { title: '更新者', dataIndex: 'updateUserName', key: 'updateUserName' },
      { title: '更新日時', dataIndex: 'updateDatetime', key: 'updateDatetime', className: 'dateColumn' }
    ];

    const data = getState()
      .journalDataDownload.batchRunList.filter(ch => ch.journalMgmtId === record.journalMgmtId)
      .map(row => {
        return {
          ...row,
          totalCount: common.numberFormater(row.totalCount),
          errorCount: common.numberFormater(row.errorCount),
          status: convertJournalStatus(row.status)
        };
      });

    return <Table className={'journalChildTable'} columns={columns} dataSource={data} pagination={false} />;
  };

  return (
    <>
      <Form className="search" layout="inline" onSubmit={onSearchJournalData}>
        <Form.Item label="対象日付">
          <MonthPicker
            id="targetDate"
            locale={locale}
            format="YYYY年MM月度"
            defaultValue={targetDate}
            onChange={(date, dateString) => {
              if (date) {
                onSetJournalDataParams(date);
              } else {
                onSetJournalDataParams();
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button className="searchBtn" htmlType="submit">
            検索
          </Button>
        </Form.Item>
      </Form>
      <Table
        className={'commonTable'}
        columns={columns}
        dataSource={dataSource}
        rowKey="journalMgmtId"
        expandedRowRender={expandedRowRenderer}
        pagination={false}
        size={'small'}
      />
    </>
  );
}
