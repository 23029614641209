import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getState } from '../../domain/store';
import { Row, Button } from 'antd';
import { setLoading, navigationTo, onBackToSearchCompany } from '../../domain/middleware';
import history from '../../domain/history';

const StyledRow = styled(Row)`
  height: 80vh;
  flex-direction: column;
  flex-wrap: wrap;
`;

/* eslint-disable react/no-unescaped-entities */
export function NotFound(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <StyledRow type="flex" justify="center" align="middle">
      <h2>お探しのページは見つかりません。</h2>
    </StyledRow>
  );
}

export function AuthError(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <StyledRow type="flex" justify="center" align="middle">
      <h2>契約ポータルを利用する権限がありません。</h2>
    </StyledRow>
  );
}

export function IndividualContractCompanyError(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <StyledRow type="flex" justify="center" align="middle">
      <h2>大変申し訳ありませんが、お客様の契約は個別契約であるため、契約ポータルで契約変更・更新することはできません。</h2>
    </StyledRow>
  );
}

export function CantUse(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  const buttonType = history.location.state.buttonType;
  let backButton = null;
  switch (buttonType) {
    case 'Admin':
      backButton = (
        <Button className="goBackBtn" onClick={() => navigationTo('adminSearch')}>
          企業検索画面に戻る
        </Button>
      );
      break;
    case 'User':
      backButton = (
        <Button className="goBackBtn" onClick={() => navigationTo('back')}>
          トップ画面に戻る
        </Button>
      );
      break;
    case 'UserContractUnpaidInvoice':
      backButton = (
        <Button className="goBackBtn" onClick={() => navigationTo('UserContractUnpaidInvoice')}>
          不要請求書削除画面に戻る
        </Button>
      );
      break;
    default:
      break;
  }

  return (
    <StyledRow type="flex" justify="center" align="middle">
      <h2>リクエストを処理できませんでした。</h2>
      <h2>お手数ですが、もう一度はじめから操作してください。</h2>
      {backButton}
    </StyledRow>
  );
}

export function SystemError(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <StyledRow type="flex" justify="center" align="middle">
      <h2>内部エラーが発生しました。</h2>
      <h2>お手数ですが、時間をおいて再度ログインし処理を行ってください。</h2>
    </StyledRow>
  );
}

export function NoDataError(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <StyledRow type="flex" justify="center" align="middle">
      <h2>お探しの情報が見つからないか、無効となっている可能性があります。操作をやり直してください。</h2>
    </StyledRow>
  );
}

export function SessionTimeOutError(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <StyledRow type="flex" justify="center" align="middle">
      <h2>一定時間操作が行われなかったため、セッションのタイムアウトエラーが発生しました。</h2>
      <h2>お手数ですが、再度ログインし直してください。</h2>
    </StyledRow>
  );
}

export function FailedCreatingPDFError(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  const msg = history.location.state.msg;
  const msgArray = msg.match(/[^\n]+?。/g);
  let idx = 0;
  function createMSG(): any {
    const listItems = msgArray.map((obj: any) => <h2 key={idx++}>{obj}</h2>);
    return listItems;
  }

  return (
    <StyledRow type="flex" justify="center" align="middle">
      {createMSG()}
      <Button className="goBackBtn" onClick={() => navigationTo('back')}>
        トップ画面に戻る
      </Button>
    </StyledRow>
  );
}

export function CantUseCompanyPageError(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  const msg = history.location.state.msg;
  const msgArray = msg.match(/[^\n]+?。/g);
  let idx = 0;
  function createMSG(): any {
    const listItems = msgArray.map((obj: any) => <h2 key={idx++}>{obj}</h2>);
    return listItems;
  }

  const currentState = getState();

  let retButton: any = '';
  if (currentState.isAdmin) {
    retButton = (
      <Button
        className="goBackBtn"
        onClick={() => {
          onBackToSearchCompany();
        }}
      >
        企業検索画面に戻る
      </Button>
    );
  }

  return (
    <StyledRow type="flex" justify="center" align="middle">
      {createMSG()}
      {retButton}
    </StyledRow>
  );
}
