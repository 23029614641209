import React, { useState } from 'react';
import moment from 'moment';
import { Col } from 'antd';
import { IState } from '../../../domain/model';
import './contractNewForm.css';
import TotalPriceArea from '../formParts/totalPriceArea';
import BtnGroup from '../formParts/btnGroup';
import { CalcContext, BtnContext, UsableTriggerContext, CalenderContext } from '../formParts/context';
import CustomerRemark from '../formParts/customerRemark';
import ContractTable from './contractTable';

/* eslint-disable react/no-unescaped-entities */
export default function ContractNewForm(props: any): JSX.Element {
  const [calDisable, setCalDisable] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [usableTrigger, setUsableTrigger] = useState(true);
  const [isSelectCalender, setCalender] = useState(false);
  const state: IState = props.state;

  const currentDate = state.productList && state.productList.currentDate ? moment(state.productList.currentDate).format('YYYY/MM/DD') : '';
  const targetYM = props.endDate ? moment(props.endDate).format('YYYY/MM') : '';

  return (
    <div>
      <CalcContext.Provider value={[calDisable, setCalDisable]}>
        <BtnContext.Provider value={[btnDisable, setBtnDisable]}>
          <UsableTriggerContext.Provider value={[usableTrigger, setUsableTrigger]}>
            <CalenderContext.Provider value={[isSelectCalender, setCalender]}>
              <Col span={22}>
                <h2>
                  契約内容：{props.serviceName}
                  {props.cycle}
                </h2>
                <div className="Title">
                  <h2>次年度更新：オーナーサービス</h2>
                </div>
                <div className="contractStateNotify">
                  {currentDate}時点の申込状況を元に{targetYM}時点の契約状況を表示しています
                </div>
                <div className="invoiceList">
                  {state.productList.productList.length > 0 && <ContractTable state={state} pageId={'renew'} />}
                </div>
              </Col>

              <CustomerRemark />
              <TotalPriceArea total={state.updateForm.total} pageId={'renew'} />
              <BtnGroup pageId={'renew'} subscriptionName={props.subscriptionName} />
            </CalenderContext.Provider>
          </UsableTriggerContext.Provider>
        </BtnContext.Provider>
      </CalcContext.Provider>
    </div>
  );
}
