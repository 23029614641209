import React from 'react';
import { IProps } from '../../domain/model';
import ContractUpdateForm from '../../components/user/form/contractUpdateForm';
import history from '../../domain/history';
import { termFormatter } from '../../domain/common';

/* eslint-disable react/no-unescaped-entities */
export default function ContractUpdatePage({ state }: IProps): JSX.Element {
  const serviceName = history.location.state.serviceName;
  const subscriptionName = history.location.state.subscriptionName;
  const startDate = history.location.state.startDate;
  const endDate = history.location.state.endDate;
  const renewedEndDate = history.location.state.renewedEndDate;
  const category = history.location.state.category;

  const cycle = '（貴社のサービスサイクル：' + termFormatter(startDate, endDate) + '）';
  return (
    <ContractUpdateForm
      state={state}
      serviceName={serviceName}
      cycle={cycle}
      subscriptionName={subscriptionName}
      endDate={endDate}
      renewedEndDate={renewedEndDate}
      startDate={startDate}
      category={category}
    />
  );
}
