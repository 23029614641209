import React from 'react';
import { Modal, Descriptions, Icon } from 'antd';
import * as common from '../../../domain/common';
import { getState } from '../../../domain/store';

export default function invoiceModal(invoiceAmount: number, dueDate: string): void {
  const invoice = getState().userContractInoviceInfo;

  function content(): JSX.Element {
    if (invoice) {
      return (
        <>
          <Descriptions size="small" layout="vertical" bordered>
            <Descriptions.Item label="請求書番号">{invoice.invoiceNumber}</Descriptions.Item>
            <Descriptions.Item label="仕分">{invoice.category}</Descriptions.Item>
            <Descriptions.Item label="CCUS">{invoice.ccusContract ? '◯' : <Icon type="line" />}</Descriptions.Item>
            <Descriptions.Item label="ID">
              {invoice.idCount !== undefined ? common.numberFormater(invoice.idCount) : <Icon type="line" />}
            </Descriptions.Item>
            <Descriptions.Item label="代行企業">
              {invoice.agentCount !== undefined ? common.numberFormater(invoice.agentCount) : <Icon type="line" />}
            </Descriptions.Item>
            <Descriptions.Item label="一人親方">
              {invoice.oneManCount !== undefined ? common.numberFormater(invoice.oneManCount) : <Icon type="line" />}
            </Descriptions.Item>
            <Descriptions.Item label="請求額">{common.numberFormater(invoiceAmount)}</Descriptions.Item>
            <Descriptions.Item label="請求日">{common.yyyymmddSlashFormatter(invoice.invoiceDate)}</Descriptions.Item>
            <Descriptions.Item label="支払期日">{common.yyyymmddSlashFormatter(dueDate)}</Descriptions.Item>
          </Descriptions>
        </>
      );
    } else {
      return <span>no data</span>;
    }
  }
  Modal.info({
    title: '請求書情報',
    centered: true,
    content: content(),
    okText: '戻る',
    width: 800
  });
}
