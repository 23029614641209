import React, { useContext } from 'react';
import { Button } from 'antd';
import './btnGroup.css';
import { navigationTo, onRenewContract, onUpdateContract } from '../../../domain/middleware';
import { getState } from '../../../domain/store';
import { BtnContext } from '../formParts/context';
import { showConfirmModal } from '../modal/confirmModal';
import { createContractRequestData } from '../../../domain/common';

export default function BtnGroup(props: any): JSX.Element {
  const [btnDisable, setBtnDisable] = useContext(BtnContext); // eslint-disable-line @typescript-eslint/no-unused-vars
  const isAdmin: boolean = getState().isAdmin;
  const pageId: string = props.pageId;
  const subscriptionName: string = props.subscriptionName;
  const isAdminMCDPUser = getState().loginInfo.managerFlag === '6';

  function onSubmit(): any {
    if (pageId === 'renew') {
      const onOKClick = (): void => {
        createContractRequestData(subscriptionName);
        onRenewContract();
      };
      const text = '次年度更新申し込みを行います。よろしいですか？';
      showConfirmModal(text, onOKClick);
    } else {
      const onOKClick = (): void => {
        createContractRequestData(subscriptionName);
        onUpdateContract();
      };
      const text = '追加申し込みを行います。よろしいですか？';
      showConfirmModal(text, onOKClick);
    }
  }

  /* eslint-disable react/no-unescaped-entities */
  return (
    <Button.Group className="applyBtnGroup">
      <Button className="applyBtn" onClick={() => navigationTo('back')}>
        戻る
      </Button>
      <Button type="primary" className="applyBtn" onClick={onSubmit} disabled={btnDisable || (isAdmin && !isAdminMCDPUser)}>
        確定申込
      </Button>
    </Button.Group>
  );
}
