import React, { useEffect, useState } from 'react';
import { Input, Button, Select, Checkbox } from 'antd';
import { isLoading, DummyLogin } from './dummyReducers';
import './dummyLoginPage.css';

const { Option } = Select;

/* eslint-disable react/no-unescaped-entities */
export default function DummyLoginPage(): JSX.Element {
  useEffect(() => {
    isLoading(false);
  }, []);

  const [btnDisable, setBtnDisable] = useState(true);
  const [inputState, setInputState] = useState('');
  const [managerFlag, setManagerFlag] = useState('6');
  const [moveToUserContractInvoice, setMoveToUserContractInvoice] = useState();

  function goToTopPage(isAdmin: boolean): void {
    const companyId = inputState.toString();
    const userInfo = { companyId, isAdmin, managerFlag, moveToUserContractInvoice };
    DummyLogin(userInfo);
  }

  function handleChange(e: any): void {
    const value = e.target.value;
    setInputState(value);
    if (value && /^[0-9]+$/i.test(value) && value.length === 10) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }
  function handleChangeManagerFlag(e: any): void {
    setManagerFlag(e);
  }
  const onChange = (e: any): void => {
    setMoveToUserContractInvoice(e.target.checked);
  };

  return (
    <div className="loginContents">
      <div className="innerContents">
        企業ID:
        <Input className="dummyCompanyId" placeholder="企業ID" name="companyId" onChange={handleChange} />
        管理者ログイン種別:
        <Select className="dummyManagerFlag" defaultValue={'6'} onChange={handleChangeManagerFlag}>
          <Option value="5">5:ヘルプデスク</Option>
          <Option value="6">6:MCDP運用担当者</Option>
        </Select>
        <Checkbox className="dummyManagerFlag" onChange={onChange}>
          ユーザー契約削除画面に遷移
        </Checkbox>
        <div className="btnGroup">
          <Button type="primary" onClick={() => goToTopPage(false)} disabled={btnDisable}>
            一般ユーザーログイン
          </Button>
          <Button type="primary" onClick={() => goToTopPage(true)}>
            管理者ログイン
          </Button>
        </div>
      </div>
    </div>
  );
}
