import React from 'react';
import { Table, Button, DatePicker, Typography, Form } from 'antd';
import { IProps, IUserContractStatusHeader } from '../../../domain/model';
import {
  onGetUserContractUploadStatusDetail,
  onSetUserContractStatusParams,
  onSearchUserContractStatus,
  onDownloadUserContractUploadStatusDetail,
  onDownloadInvoiceDeleteStatusDetail
} from '../../../domain/middleware';
import { numberFormater, convertStatus } from '../../../domain/common';
import './userContractStatus.css';

const { Text } = Typography;

export default function UserContractStatus({ state }: IProps): JSX.Element {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'mgmtIdDisp',
      className: 'left'
    },
    {
      title: 'ファイル名',
      dataIndex: 'fileName',
      className: 'left'
    },
    {
      title: 'ステータス',
      dataIndex: 'statusDisp',
      className: 'center'
    },
    {
      title: 'エラーメッセージ',
      dataIndex: 'errorMessage',
      className: 'left'
    },
    {
      title: '総件数',
      dataIndex: 'totalCountDisp',
      className: 'right'
    },
    {
      title: '成功件数',
      dataIndex: 'successCountDisp',
      className: 'right'
    },
    {
      title: '失敗件数',
      dataIndex: 'failureCountDisp',
      className: 'right'
    },
    {
      title: '登録件数',
      dataIndex: 'registeredCountDisp',
      className: 'right'
    },
    {
      title: '削除件数',
      dataIndex: 'deletedCountDisp',
      className: 'right'
    },
    {
      title: '登録者',
      dataIndex: 'insertUserName',
      className: 'left'
    },
    {
      title: '登録日時',
      dataIndex: 'insertDatetime',
      className: 'right',
      width: 95
    },
    {
      title: '更新者',
      dataIndex: 'updateUserName',
      className: 'left'
    },
    {
      title: '更新日時',
      dataIndex: 'updateDatetime',
      className: 'right',
      width: 95
    },
    {
      title: '明細画面',
      dataIndex: 'buttonToDetailPage',
      className: 'center'
    },
    {
      title: 'ダウンロード',
      dataIndex: 'buttonToDownload',
      className: 'center'
    }
  ];

  function buttonToDetailPage(mgmtId: number, uploadType: string): any {
    return <Button onClick={() => onGetUserContractUploadStatusDetail(mgmtId, uploadType)}>明細</Button>;
  }

  function buttonToDownload(mgmtId: number, uploadType: string): any {
    if (uploadType === 'register') {
      return <Button onClick={() => onDownloadUserContractUploadStatusDetail(mgmtId)}>ダウンロード</Button>;
    } else {
      return <Button onClick={() => onDownloadInvoiceDeleteStatusDetail(mgmtId)}>ダウンロード</Button>;
    }
  }

  interface IDataSource extends IUserContractStatusHeader {
    mgmtIdDisp: string;
    statusDisp: string;
    totalCountDisp: string;
    successCountDisp: string;
    failureCountDisp: any;
    registeredCountDisp: string;
    deletedCountDisp: string;
    buttonToDetailPage: any;
    buttonToDownload: any;
  }

  const dataSource: IDataSource[] = [];
  state.userContractStatus.headerList.forEach(e => {
    dataSource.push({
      ...e,
      mgmtIdDisp: e.uploadType === 'register' ? `REG-${e.mgmtId}` : `DEL-${e.mgmtId}`,
      statusDisp: convertStatus(e.status),
      totalCountDisp: numberFormater(e.totalCount),
      successCountDisp: numberFormater(e.successCount),
      failureCountDisp: !e.failureCount ? numberFormater(e.failureCount) : <Text type="danger">{numberFormater(e.failureCount)}</Text>,
      registeredCountDisp: e.uploadType === 'register' ? numberFormater(e.registeredCount) : '-',
      deletedCountDisp: numberFormater(e.deletedCount),
      buttonToDetailPage: buttonToDetailPage(e.mgmtId, e.uploadType),
      buttonToDownload: buttonToDownload(e.mgmtId, e.uploadType)
    });
  });

  const { RangePicker } = DatePicker;

  const from = state.userContractStatus.params.targetDateFrom;
  const to = state.userContractStatus.params.targetDateTo;

  return (
    <>
      <h3>請求 データアップロードステータス</h3>
      <Form className="search" layout="inline" onSubmit={onSearchUserContractStatus}>
        <Form.Item label={'登録日'}>
          <RangePicker
            format={'YYYY/MM/DD'}
            placeholder={['検索範囲開始日', '検索範囲終了日']}
            defaultValue={[from, to]}
            onChange={(dates, dateString) => {
              if (dates && dates[0] && dates[1]) {
                onSetUserContractStatusParams(dates[0], dates[1]);
              } else {
                onSetUserContractStatusParams();
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button className="statusSearchBtn" htmlType="submit">
            検索
          </Button>
        </Form.Item>
      </Form>
      <Table className={'commonTable'} columns={columns} dataSource={dataSource} rowKey="mgmtId" pagination={false} size={'small'} />
    </>
  );
}
