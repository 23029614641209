import { Modal } from 'antd';

export default function deletionProceededModal(content: string, onOkFunction: () => void): void {
  Modal.warning({
    centered: true,
    content,
    width: 550,
    onOk() {
      onOkFunction();
    }
  });
}
