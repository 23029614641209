import React from 'react';
import { IProps } from '../../domain/model';
import ContractNewForm from '../../components/user/form/contractNewForm';
import history from '../../domain/history';
import { termFormatter } from '../../domain/common';

/* eslint-disable react/no-unescaped-entities */
export default function ContractNewPage({ state }: IProps): JSX.Element {
  const serviceName = history.location.state.serviceName;
  const subscriptionName = history.location.state.subscriptionName;
  const startDate = history.location.state.startDate;
  const endDate = history.location.state.endDate;

  const cycle = '（現契約の期間：' + termFormatter(startDate, endDate) + '）';

  return <ContractNewForm state={state} serviceName={serviceName} cycle={cycle} subscriptionName={subscriptionName} endDate={endDate} />;
}
