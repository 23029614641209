import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import ReactDOM from 'react-dom';
import { App } from './app';
import { subscribe, getState } from './domain/store';
import { IState } from './domain/model';
import ErrorBoundary from './components/shared/errorBoundary';
import { Router } from 'react-router-dom';
import History from './domain/history';
import Loading from './components/shared/loading';
import Header from './components/shared/header/header';
import Footer from './components/shared/footer/footer';

function renderer(state: IState): void {
  ReactDOM.render(
    <ErrorBoundary>
      <Loading active={state.loading}>
        <div className="body">
          <Header state={state} />
          <main className="app">
            <div className="appContainer">
              <Router history={History}>
                <App state={state} />
              </Router>
            </div>
          </main>
          <Footer active={state.loading} />
        </div>
      </Loading>
    </ErrorBoundary>,

    document.getElementById('root')
  );
}

subscribe(renderer);
renderer(getState());
