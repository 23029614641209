import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import { setLoading } from '../../domain/middleware';

const StyledRow = styled(Row)`
  height: 80vh;
  flex-direction: column;
  flex-wrap: wrap;
`;

/* eslint-disable react/no-unescaped-entities */
export default function Logout(): JSX.Element {
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <StyledRow type="flex" justify="center" align="middle">
      <h2>ご利用ありがとうございました。</h2>
    </StyledRow>
  );
}
