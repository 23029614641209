import React from 'react';
import { Table, Button } from 'antd';
import { IProps, IUserPaymentStatusDetail } from '../../../domain/model';
import './userPaymentStatusDetail.css';
import { jpyFormater, convertStatus } from '../../../domain/common';
import { navigationTo } from '../../../domain/middleware';

export default function UserPaymentStatusDetail({ state }: IProps): JSX.Element {
  const columns = [
    {
      title: 'No',
      dataIndex: 'seqNo',
      className: 'right',
      fixed: true,
      width: 50
    },
    {
      title: '照会番号',
      dataIndex: 'refNo',
      fixed: true,
      width: 80
    },
    {
      title: 'ステータス',
      dataIndex: 'statusDisp',
      className: 'center',
      fixed: true,
      width: 90
    },
    {
      title: '企業ID',
      dataIndex: 'companyId',
      fixed: true,
      width: 120
    },
    {
      title: '企業名',
      dataIndex: 'companyName',
      fixed: true,
      width: 200
    },
    {
      title: '仮想口座番号',
      dataIndex: 'virtualAccountNumber'
    },
    {
      title: '仮想口座番号(消込用)',
      dataIndex: 'virtualAccountNumberPayment',
      width: 180
    },
    {
      title: '入金日',
      dataIndex: 'paymentDate',
      className: 'right',
      width: 95
    },
    {
      title: '金額',
      dataIndex: 'amountDisp',
      className: 'right'
    },
    {
      title: '振込依頼人コード',
      dataIndex: 'payerCd'
    },
    {
      title: '振込依頼人名',
      dataIndex: 'payerName'
    },
    {
      title: '仕向銀行名',
      dataIndex: 'payerBank'
    },
    {
      title: '仕向店名',
      dataIndex: 'payerBankBranch'
    },
    {
      title: 'EDI情報',
      dataIndex: 'ediInfo'
    },
    {
      title: 'アカウントID',
      dataIndex: 'accountId',
      className: 'breakAll'
    },
    {
      title: 'アカウント番号',
      dataIndex: 'accountNumber'
    },
    {
      title: 'エラーメッセージ',
      dataIndex: 'errorMessage'
    },
    {
      title: '登録日時',
      dataIndex: 'insertDatetime',
      className: 'right',
      width: 95
    },
    {
      title: '登録者',
      dataIndex: 'insertUserName',
      className: 'left'
    },
    {
      title: '更新日時',
      dataIndex: 'updateDatetime',
      className: 'right',
      width: 95
    },
    {
      title: '更新者',
      dataIndex: 'updateUserName',
      className: 'left'
    }
  ];

  interface IDataSource extends IUserPaymentStatusDetail {
    seqNo: number;
    statusDisp: string;
    amountDisp: string;
  }

  let seqNo = 0;
  const dataSource: IDataSource[] = state.userPaymentStatus.detailList.map(row => {
    return {
      ...row,
      seqNo: ++seqNo,
      statusDisp: convertStatus(row.status),
      amountDisp: jpyFormater(row.amount)
    };
  });

  return (
    <>
      <h3>入金データ アップロードステータス 明細</h3>
      <Table
        className={'commonTable'}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 3000, y: 400 }}
        rowKey={'lineNo'}
        pagination={false}
        size={'small'}
      />
      <Button className="backBtn" onClick={() => navigationTo('userPaymentStatus')}>
        戻る
      </Button>
    </>
  );
}
