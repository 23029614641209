import React, { useState } from 'react';
import { Table, Button, Modal, Descriptions, InputNumber, Divider, Input, Row, Col } from 'antd';
import { getState } from '../../../domain/store';
import { updateIndex } from '../../../domain/common';
import * as common from '../../../domain/common';
import { onPutTransferPayment, onSearchCompanyForTransfer, onError, onGetToken, onClearToken } from 'domain/middleware';
import * as paginationUtil from '../../../domain/utils/paginationUtil';
import './transferModal.css';

/** MCDP本口座企業ID */
const MCDP_ACCOUNT_ID = '8000000002';

interface ICompanySearchResult {
  key: string;
  companyId: string;
  accountNumber: string;
  companyName: string;
  creditBalance: any;
  totalInvoiceBalance: any;
  virtualAccountNoPayment: string;
  executeTransfer: any;
}

export default function SearchResult(props: any): JSX.Element {
  const [transferModalVisible, setTransferModalVisible] = useState(false);
  const [transferAmount, setTransferAmount] = useState(0);
  const [remainingSourceCreditBalance, setRemainingSourceCreditBalance] = useState(getState().accountingSummary.creditBalance);
  const [remainingDestinationCreditBalance, setRemainingDestinationSourceCreditBalance] = useState(0);
  const [transferDestinationCompany, setTransferDestinationCompany] = useState<any>({});
  const [okButtonDisabled, setOkButtonDisabled] = useState(true);
  const [galileoptInvoiceCompanyName, setGalileoptInvoiceCompanyName] = useState('');
  const [galileoptRequestNo, setGalileoptRequestNo] = useState('');

  const summary = getState().accountingSummary;

  const columns = [
    {
      key: `companyId${updateIndex()}`,
      title: '企業ID',
      dataIndex: 'companyId',
      width: '10%'
    },
    {
      key: `companyName${updateIndex()}`,
      title: '企業名',
      dataIndex: 'companyName',
      width: '35%',
      className: 'left'
    },
    {
      key: `virtualAccountNoPayment${updateIndex()}`,
      title: '仮想口座番号',
      dataIndex: 'virtualAccountNoPayment',
      width: '10%'
    },
    {
      key: `accountNumber${updateIndex()}`,
      title: 'Zuoraアカウント番号',
      dataIndex: 'accountNumber',
      width: '15%'
    },
    {
      key: `totalInvoiceBalance${updateIndex()}`,
      title: '請求残',
      dataIndex: 'totalInvoiceBalance',
      width: '15%',
      className: 'right'
    },
    {
      key: `creditBalance${updateIndex()}`,
      title: '預り金残',
      dataIndex: 'creditBalance',
      width: '15%',
      className: 'right'
    },

    {
      key: `accountingButton${updateIndex()}`,
      title: '預り金付替',
      dataIndex: 'executeTransfer',
      width: '10%'
    }
  ];

  function executeTransfer(obj: any): any {
    return (
      <Button
        onClick={() => {
          setTransferDestinationCompany(obj);
          setRemainingDestinationSourceCreditBalance(obj.creditBalance);

          // Galileopt連携情報の初期化
          setGalileoptInvoiceCompanyName(MCDP_ACCOUNT_ID === obj.companyId ? summary.companyName : '');
          setGalileoptRequestNo('');

          setTransferModalVisible(true);
        }}
        disabled={summary.companyId === obj.companyId}
      >
        預り金付替
      </Button>
    );
  }

  function transferAmountOnChange(value: any): void {
    setTransferAmount(isNaN(value + 0) ? 0 : value);
    if (value > 0) {
      setOkButtonDisabled(false);
    } else {
      setOkButtonDisabled(true);
    }
    setRemainingSourceCreditBalance(isNaN(summary.creditBalance - value) ? 0 : summary.creditBalance - value);
    setRemainingDestinationSourceCreditBalance(
      isNaN(transferDestinationCompany.creditBalance + value) ? 0 : transferDestinationCompany.creditBalance + value
    );
  }

  const companyList = getState().companyListForTransfer;
  const companySearchResult: ICompanySearchResult[] = [];
  companyList.forEach(obj => {
    companySearchResult.push({
      key: `companyId${updateIndex()}`,
      companyId: obj.companyId,
      accountNumber: obj.accountNumber,
      companyName: obj.companyName,
      creditBalance: common.numberFormater(obj.creditBalance),
      totalInvoiceBalance: common.numberFormater(obj.totalInvoiceBalance),
      virtualAccountNoPayment: obj.virtualAccountNoPayment,
      executeTransfer: executeTransfer(obj)
    });
  });

  function renderDescription(): any {
    return (
      <>
        <Descriptions size="small" title="預り金付替元企業情報" layout="vertical" bordered>
          <Descriptions.Item label="企業ID">{summary.companyId}</Descriptions.Item>
          <Descriptions.Item label="企業名" span={2}>
            {summary.companyName}
          </Descriptions.Item>

          <Descriptions.Item label="預り金残">{common.numberFormater(summary.creditBalance)}</Descriptions.Item>
          <Descriptions.Item label="預り金付替額">{transferAmount ? '-' + common.numberFormater(transferAmount) : 0}</Descriptions.Item>
          <Descriptions.Item label="付替後預り金残">{common.numberFormater(remainingSourceCreditBalance)}</Descriptions.Item>
        </Descriptions>
        <Divider></Divider>
        <Descriptions size="small" title="預り金付替先企業情報" layout="vertical" bordered>
          <Descriptions.Item label="企業ID">{transferDestinationCompany.companyId}</Descriptions.Item>
          <Descriptions.Item label="企業名" span={2}>
            {transferDestinationCompany.companyName}
          </Descriptions.Item>

          <Descriptions.Item label="預り金残">
            {transferDestinationCompany.creditBalance && common.numberFormater(transferDestinationCompany.creditBalance)}
          </Descriptions.Item>
          <Descriptions.Item label="預り金付替額">{transferAmount ? '+' + common.numberFormater(transferAmount) : 0}</Descriptions.Item>
          <Descriptions.Item label="付替後預り金残">{common.numberFormater(remainingDestinationCreditBalance)}</Descriptions.Item>
        </Descriptions>
      </>
    );
  }

  const confirmDescription = (): any => {
    return (
      <>
        {renderDescription()}
        <Divider></Divider>
        <Descriptions size="small" title="Galileopt連携情報" layout="horizontal" bordered column={1}>
          <Descriptions.Item label="Galileopt請求書企業名">{galileoptInvoiceCompanyName}</Descriptions.Item>
          <Descriptions.Item label="Galileopt申請No">{galileoptRequestNo}</Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  const validation = (): boolean => {
    const nameLength = galileoptInvoiceCompanyName.length;
    const requestLength = galileoptRequestNo.length;
    return (0 === nameLength && 0 === requestLength) || (0 !== nameLength && 0 !== requestLength);
  };
  const onPutTransferPaymentAsync = async (): Promise<void> => {
    try {
      onPutTransferPayment(summary.companyId, transferDestinationCompany.companyId, transferAmount, {
        invoiceCompanyName: galileoptInvoiceCompanyName,
        requestNo: galileoptRequestNo
      });
    } catch (err) {
      onError(err);
    }
  };
  async function confirmModal(): Promise<void> {
    // ワンタイムトークン取得
    await onGetToken();

    Modal.confirm({
      title: <span>預り金付替を実行します。</span>,
      content: confirmDescription(),
      width: 600,
      okText: '預り金付替を確定',
      cancelText: '戻る',
      onOk: onPutTransferPaymentAsync,
      onCancel: onClearToken
    });
  }

  const alertModal = (): void => {
    Modal.warning({
      title: <span>入力内容にエラーがあります。</span>,
      content: <div>Galileopt請求書企業名とGalileopt申請Noはペアで入力してください。</div>,
      width: 480
    });
  };

  const condition = getState().accountingTransferSearchCondition;
  const totalRows = getState().companyListForTransferPagination.totalCount;
  const currentPage = paginationUtil.getPageNo(getState().companyListForTransferPagination.offset);

  function onPageChange(page: number): void {
    onSearchCompanyForTransfer(condition, paginationUtil.getOffset(page));
  }
  function scrollSize(): { x?: number; y?: number } {
    return { y: props.windowHeight - 260 };
  }

  const onChangeCompanyName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setGalileoptInvoiceCompanyName(value);
  };

  const onChangeRequestNo = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setGalileoptRequestNo(value);
  };

  function transferAmountHandleCancel(): void {
    setTransferModalVisible(false);
    setRemainingSourceCreditBalance(summary.creditBalance);
    setRemainingDestinationSourceCreditBalance(transferDestinationCompany.creditBalance);
    setTransferAmount(0);
    setOkButtonDisabled(true);
  }

  return (
    <>
      <div className="oneContent" id="searchResltTable">
        {companyList.length > 0 && (
          <>
            <h3>検索結果</h3>
            <Table
              columns={columns}
              dataSource={companySearchResult}
              scroll={scrollSize()}
              pagination={{
                defaultPageSize: paginationUtil.ROWS_PER_PAGE,
                defaultCurrent: currentPage,
                total: totalRows,
                onChange: onPageChange
              }}
            />
          </>
        )}
        {companyList.length === 0 && <h3>検索結果がありません。</h3>}
      </div>
      <Modal
        title="預り金付替"
        visible={transferModalVisible}
        style={{ top: 20 }}
        okText={'預り金付替内容を確認'}
        cancelText={'取消'}
        onOk={() => {
          if (validation()) {
            confirmModal();
          } else {
            alertModal();
          }
        }}
        okButtonProps={{ disabled: okButtonDisabled }}
        onCancel={transferAmountHandleCancel}
      >
        {renderDescription()}
        <InputNumber
          size={'large'}
          style={{ width: 200, marginTop: 10 }}
          min={0}
          max={summary.creditBalance}
          value={transferAmount}
          defaultValue={0}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
          onChange={transferAmountOnChange}
        />
        <div className="additionalInput">
          <Divider orientation="left">本口座への付替のときのみ、以下を入力してください。</Divider>
          <Row className="inputRow">
            <Col span={8} className="header">
              <span className="headerItem">Galileopt請求書企業名</span>
            </Col>
            <Col span={16}>
              <Input defaultValue={''} onChange={onChangeCompanyName} value={galileoptInvoiceCompanyName} />
            </Col>
          </Row>
          <Row className="inputRow">
            <Col span={8} className="header">
              <span className="headerItem">Galileopt申請No</span>
            </Col>
            <Col span={16}>
              <Input defaultValue={''} onChange={onChangeRequestNo} value={galileoptRequestNo} />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
