import React, { useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import { onSearchCompany, onSearchCompanyForTransfer } from '../../../domain/middleware';
import { getState } from '../../../domain/store';
import InputField from './inputField';
import { SearchBtnContext } from '../context';
import './searchForm.css';

/* eslint-disable react/no-unescaped-entities */
export function SearchForm(props: any): JSX.Element {
  const [searchDisable, setSearchDisable] = useState(true);
  const pageId = props.pageId || '';
  const searchCondition = pageId === 'accountingTransfer' ? getState().accountingTransferSearchCondition : getState().searchCondition;
  const searchValue = props.form.getFieldsValue();

  if (
    searchDisable &&
    (searchValue.companyId || searchValue.companyName || searchValue.virtualAccountNoPayment || searchValue.invoiceNumber)
  ) {
    setSearchDisable(false);
  }
  useEffect(
    () => {
      props.form.setFieldsValue(searchCondition);
    },
    // eslint-disable-next-line
    []
  );

  const handleSubmit = (e: any): any => {
    e.preventDefault();
    const values = props.form.getFieldsValue();
    if (values.companyId || values.companyName || values.virtualAccountNoPayment || values.invoiceNumber) {
      if (pageId === 'accountingTransfer') {
        onSearchCompanyForTransfer(values);
      } else {
        onSearchCompany(values);
      }
    }
  };

  // const handleReset = (): any => {
  //   props.form.resetFields();
  // };

  return (
    <SearchBtnContext.Provider value={[searchDisable, setSearchDisable]}>
      <Form className="search" layout="inline" onSubmit={handleSubmit}>
        <InputField form={props.form} id={'企業ID（部分一致）'} label={'companyId'} placeholder={'企業ID'}></InputField>
        <InputField form={props.form} id={'企業名（部分一致）'} label={'companyName'} placeholder={'企業名'}></InputField>
        <InputField
          form={props.form}
          id={'仮想口座番号（部分一致）'}
          label={'virtualAccountNoPayment'}
          placeholder={'仮想口座番号'}
        ></InputField>
        <InputField form={props.form} id={'請求書番号（完全一致）'} label={'invoiceNumber'} placeholder={'請求書番号'}></InputField>
        <Form.Item>
          <Button htmlType="submit" className="searchBtn" size="large" disabled={searchDisable}>
            検索
          </Button>
          {/* <Button style={{ marginLeft: 8 }} size="large" onClick={handleReset}>
            クリア
          </Button> */}
        </Form.Item>
      </Form>
    </SearchBtnContext.Provider>
  );
}

export const CompanySearchForm: any = Form.create()(SearchForm);
