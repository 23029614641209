import { IProductList, IOneProduct } from '../model';
import * as Const from '../const';
import _ from 'lodash';

export type OwnerContractProductMapKey =
  | 'All_Initial'
  | 'Project_Charge'
  | 'Project_Charge_Add'
  | 'AllBackup_Initial'
  | 'Backup_Charge'
  | 'DataLink_Initial'
  | 'DataLink_Charge'
  | 'Cacicar_SingleCharge'
  | 'Cacicar_UnitCharge'
  | 'PrimeProject_Charge'
  | 'PrimeProject_Charge_Add'
  | 'LiteProject_Charge'
  | 'LiteProject_Charge_Add'
  | 'LitePrimeProject_Charge'
  | 'LitePrimeProject_Charge_Add'
  | 'LiteSet_Charge'
  | 'LiteSet_Charge_Add'
  | 'LiteBackup_Charge'
  | 'Project_Initial_gray'
  | 'Project_gray'
  | 'Project_Lite_gray'
  | 'Project_co_gray';

/**
 * プロダクトの配列から変更画面用のMapを作成します。
 * @param productList プロダクト配列
 */
export const getOwnerContractProductMap = (productList: IProductList[]): { [key in OwnerContractProductMapKey]: IOneProduct } => {
  const ownerContractProductMap: { [key in OwnerContractProductMapKey]?: IOneProduct } = {};
  productList.forEach(e => {
    if (e.category === 'オーナー') {
      e.product.forEach(p => {
        if (Const.ALL_INITIAL === p.productRatePlanChargeKey) {
          ownerContractProductMap[Const.ALL_INITIAL] = p;
        }
        if (Const.isProductCharge(p.productRatePlanChargeKey)) {
          ownerContractProductMap['Project_Charge'] = p;
        }
        if (Const.isPrimeProjectCharge(p.productRatePlanChargeKey)) {
          ownerContractProductMap['PrimeProject_Charge'] = p;
        }
        if (Const.isLiteProjectCharge(p.productRatePlanChargeKey)) {
          ownerContractProductMap['LiteProject_Charge'] = p;
        }
        if (Const.isPrimeLiteProjectCharge(p.productRatePlanChargeKey)) {
          ownerContractProductMap['LitePrimeProject_Charge'] = p;
        }
        if (Const.isLiteSetCharge(p.productRatePlanChargeKey)) {
          ownerContractProductMap['LiteSet_Charge'] = p;
        }
        if (Const.ALLBACKUP_INITIAL === p.productRatePlanChargeKey) {
          ownerContractProductMap[Const.ALLBACKUP_INITIAL] = p;
        }
        if (Const.LITEBACKUP_CHARGE === p.productRatePlanChargeKey) {
          ownerContractProductMap[Const.LITEBACKUP_CHARGE] = p;
        }
        if (Const.BACKUP_CHARGE === p.productRatePlanChargeKey) {
          ownerContractProductMap[Const.BACKUP_CHARGE] = p;
        }
        if (Const.DATALINK_INITIAL === p.productRatePlanChargeKey) {
          ownerContractProductMap[Const.DATALINK_INITIAL] = p;
        }
        if (Const.DATALINK_CHARGE === p.productRatePlanChargeKey) {
          ownerContractProductMap[Const.DATALINK_CHARGE] = p;
        }
        if (Const.CACICAR_SINGLE_CHARGE === p.productRatePlanChargeKey) {
          ownerContractProductMap[Const.CACICAR_SINGLE_CHARGE] = p;
        }
        if (Const.CACICAR_UNIT_CHARGE === p.productRatePlanChargeKey) {
          ownerContractProductMap[Const.CACICAR_UNIT_CHARGE] = p;
        }
      });
    }
  });
  return ownerContractProductMap as { [key in OwnerContractProductMapKey]: IOneProduct };
};

/**
 * プロダクトの配列からMapを作成します。
 * @param projectList
 */
export const getProductMap = (projectList: IOneProduct[]): { [key in string]: IOneProduct } => {
  return _.mapKeys(projectList, 'productRatePlanChargeKey');
};

/**
 * プロジェクト数からコースを取得します。
 * @param value プロジェクト数
 */
/* eslint-disable complexity */
export const getOwnerCourseRange = (value: number): Course => {
  if (0 <= value && value <= 99) {
    return 'A';
  } else if (100 <= value && value <= 249) {
    return 'B';
  } else if (250 <= value && value <= 499) {
    return 'C';
  } else if (500 <= value && value <= 999) {
    return 'D';
  } else if (1000 <= value && value <= 2999) {
    return 'E';
  } else if (3000 <= value && value <= 4999) {
    return 'F';
  } else if (5000 <= value) {
    return 'G';
  } else {
    return 'A';
  }
};

type Course = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

/**
 * 次年度更新時に、ユーザーの入力数からプロジェクト利用料のどのコースに該当するかを取得します。
 * @param userInputValue ユーザーの入力数
 * @param basicProjectList プロジェクト利用料の配列
 * @param serviceName プロダクトのserviceネーム _の前部分
 */
export const estimateNewCourse = (userInputValue: number, basicProjectList: IOneProduct[], serviceName: string): IOneProduct => {
  const productMap = getProductMap(basicProjectList);
  const courseRange = getOwnerCourseRange(userInputValue);
  const productMapKey = serviceName + '_' + courseRange + 'Charge';
  return productMap[productMapKey as OwnerContractProductMapKey];
};

/**
 * スイッチを非活性にするかどうかを判定します。
 * @param product プロダクト
 * @param isRenewed オーナー契約が次年度更新済みかどうか
 */
export const toggleDisabled = (product: IOneProduct, isRenewed: boolean): boolean => {
  if (product.contractStatus || product.isReserved || isRenewed) {
    //現契約があるか次年度更新済みか未来の契約がある
    return true;
  } else {
    return false;
  }
};
