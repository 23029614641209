import React from 'react';
import { Table, Button } from 'antd';
import { getState } from '../../../domain/store';
import { onGetCustomerInfoWithAdminAuth, onGetAccountingInfo, onSearchCompany } from '../../../domain/middleware';
import { updateIndex } from '../../../domain/common';
import * as common from '../../../domain/common';
import * as paginationUtil from '../../../domain/utils/paginationUtil';
import { INVOICE_DIGEST_SIMPLE } from '../../../domain/const';
import './searchResult.css';

interface ICompanySearchResult {
  key: string;
  companyId: string;
  accountNumber: string;
  companyName: string;
  companyType: string;
  company: any;
  accounting: any;
  creditBalance: any;
  totalInvoiceBalance: any;
  virtualAccountNoPayment: string;
}

export default function SearchResult(props: any): JSX.Element {
  const columns = [
    {
      key: `companyId${updateIndex()}`,
      title: '企業ID',
      dataIndex: 'companyId',
      width: 120
    },
    {
      key: `companyName${updateIndex()}`,
      title: '企業名',
      dataIndex: 'companyName',
      className: 'left'
    },
    {
      key: `companyType`,
      title: '企業区分',
      dataIndex: 'companyType',
      className: 'center'
    },
    {
      key: `virtualAccountNoPayment${updateIndex()}`,
      title: '仮想口座番号',
      dataIndex: 'virtualAccountNoPayment',
      width: '15%'
    },
    {
      key: `accountNumber${updateIndex()}`,
      title: 'Zuoraアカウント番号',
      dataIndex: 'accountNumber',
      width: '17%'
    },
    {
      key: `totalInvoiceBalance${updateIndex()}`,
      title: '請求残',
      dataIndex: 'totalInvoiceBalance',
      width: '14%',
      className: 'right'
    },
    {
      key: `creditBalance${updateIndex()}`,
      title: '預り金残',
      dataIndex: 'creditBalance',
      width: '14%',
      className: 'right'
    },

    {
      key: `accountingButton${updateIndex()}`,
      title: '請求・入金管理',
      dataIndex: 'accounting',
      width: 140
    },
    {
      key: `companyButton${updateIndex()}`,
      title: '企業ページ',
      dataIndex: 'company',
      width: 120
    }
  ];

  function goToUserTopPage(companyId: string): any {
    return <Button onClick={() => onGetCustomerInfoWithAdminAuth(companyId)}>企業ページ</Button>;
  }

  // 請求・入金画面へ
  function goToAccountingPage(companyId: string): any {
    const dateFrom = common.getFirstDayOfMonth(new Date(), -2);
    const dateTo = common.yyyymmddFormatter(new Date());
    const digest = INVOICE_DIGEST_SIMPLE;
    return <Button onClick={() => onGetAccountingInfo(companyId, dateFrom, dateTo, false, digest)}>請求・入金管理</Button>;
  }

  const companyList = getState().searchCompanyList.companyList;
  const isGetResult = getState().searchCompanyList.isGetSearchResult;
  const condition = getState().searchCondition;
  const totalRows = getState().searchCompanyPagination.totalCount;
  const currentPage = paginationUtil.getPageNo(getState().searchCompanyPagination.offset);
  const companySearchResult: ICompanySearchResult[] = [];
  companyList.forEach(obj => {
    const companyType = common.convertCompanyType(obj.companyType);
    companySearchResult.push({
      key: `companyId${updateIndex()}`,
      companyId: obj.companyId,
      accountNumber: obj.accountNumber,
      companyName: obj.companyName,
      companyType: companyType,
      company: goToUserTopPage(obj.companyId),
      accounting: goToAccountingPage(obj.companyId),
      creditBalance: common.numberFormater(obj.creditBalance),
      totalInvoiceBalance: common.numberFormater(obj.totalInvoiceBalance),
      virtualAccountNoPayment: obj.virtualAccountNoPayment
    });
  });

  function onPageChange(page: number): void {
    onSearchCompany(condition, paginationUtil.getOffset(page));
  }
  function scrollSize(): { x?: number; y?: number } {
    return { y: props.windowHeight - 260 };
  }
  return (
    <div className="oneContent" id="searchResltTable">
      {companyList.length > 0 && isGetResult && (
        <>
          <h3>検索結果</h3>
          <Table
            columns={columns}
            dataSource={companySearchResult}
            scroll={scrollSize()}
            pagination={{
              defaultPageSize: paginationUtil.ROWS_PER_PAGE,
              defaultCurrent: currentPage,
              total: totalRows,
              onChange: onPageChange
            }}
          />
        </>
      )}
      {companyList.length === 0 && isGetResult && <h3>検索結果がありません。</h3>}
    </div>
  );
}
