import { Modal } from 'antd';
import { onRefreshAccountingInfo } from '../../../domain/middleware';

export default function successModal(): void {
  Modal.success({
    centered: true,
    content: '登録が完了しました。',
    onOk() {
      onRefreshAccountingInfo();
    }
  });
}
