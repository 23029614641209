import React from 'react';
import { IProps } from '../../domain/model';
import { IdInvoiceList } from '../../components/admin/idInvoiceDownload/downloadForm';

export default function IdInvoiceDownloadPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <IdInvoiceList state={state} />
        </>
      )}
    </>
  );
}
