import React from 'react';
import { onDownloadPaymentOk, onSearchPaymentOk, onSetPaymentOkParams } from '../../../domain/middleware';
import { Form, Table, Button, DatePicker } from 'antd';
import { IProps, IPaymentOk } from '../../../domain/model';
import './paymentOkDownload.css';
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';

export function PaymentOkList({ state }: IProps): JSX.Element {
  const columns = [
    {
      title: 'No',
      dataIndex: 'lineNo',
      className: 'right',
      width: 50
    },
    {
      title: '対象日付',
      dataIndex: 'targetDate',
      className: 'center',
      width: 150
    },
    {
      title: 'ファイル名',
      dataIndex: 'fileName',
      className: 'left'
    },
    {
      title: 'データ件数',
      dataIndex: 'count',
      className: 'right',
      width: 150
    },
    {
      title: 'ダウンロード',
      dataIndex: 'buttonToDownload',
      className: 'center',
      width: 150
    }
  ];

  interface IDataSource extends IPaymentOk {
    buttonToDownload: JSX.Element;
  }

  const dataSource: IDataSource[] = state.paymentOkDownload.paymentOkList.map(row => {
    return {
      ...row,
      buttonToDownload: <Button onClick={() => onDownloadPaymentOk(row.paymentOkId, row.fileName)}>ダウンロード</Button>
    };
  });

  const { MonthPicker } = DatePicker;
  const targetDate = state.paymentOkDownload.params.targetDate;

  return (
    <>
      <Form className="search" layout="inline" onSubmit={onSearchPaymentOk}>
        <Form.Item label="対象日付">
          <MonthPicker
            id="targetDate"
            locale={locale}
            format="YYYY年MM月度"
            defaultValue={targetDate}
            onChange={(date, dateString) => {
              if (date) {
                onSetPaymentOkParams(date);
              } else {
                onSetPaymentOkParams();
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button className="searchBtn" htmlType="submit">
            検索
          </Button>
        </Form.Item>
      </Form>
      <Table className={'commonTable'} columns={columns} dataSource={dataSource} rowKey="paymentOkId" pagination={false} size={'small'} />
    </>
  );
}
