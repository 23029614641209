import { setState, getState } from '../domain/store';
import { IState, IResponseData } from '../domain/model';
import axios, { AxiosResponse } from 'axios';
import history from '../domain/history';
import { onGetUserContractUnpaidInvoiceData } from '../domain/middleware';

// 起動環境によってAPIのエンドポイント切り替える
const baseURL = process.env.REACT_APP_API_BASEURL;
const apiKey = process.env.REACT_APP_INVOICE_BACKEND_API_KEY;

const myHttpClient = axios.create({
  withCredentials: true,
  headers: {
    'x-api-key': apiKey
  }
});
/**
 * 「ローディング」更新リデューサー
 * @param loading ローディング
 */
export function isLoading(loading: boolean): void {
  const currentState = getState();
  currentState.loading = loading;
  setState((state: IState) => ({
    ...state
  }));
}

/**
 * 「顧客情報（レスポンス）」更新リデューサー
 * @param pageId
 * @param loading
 * @param accountInfo
 * @param invoiceList
 */

export function getCustomerInfo(): void {
  const currentState = getState();
  myHttpClient.get(baseURL + '/init').then((response: any) => {
    currentState.pageId = '/user/top';
    currentState.pageTitle = '契約管理';
    const loading = false;
    currentState.accountInfo = response.accountInfo;
    currentState.subscriptionInfo = response.subscriptionInfo.contract;
    currentState.unpaidInvoiceList = response.unpaidInvoiceList;
    currentState.completedInvoiceList = response.completedInvoiceList;
    currentState.notifyInfo = response.notifyInfo;

    setState((state: IState) => ({
      ...state,
      loading
    }));
  });
}

/**
 * 「企業検索画面」リデューサー
 */
export function setAdminInfo(): void {
  const currentState = getState();
  currentState.pageTitle = '企業検索';
  currentState.pageId = '/admin/companySearch';
  const loading = false;
  setState((state: IState) => ({
    ...state,
    loading
  }));
  history.push('/admin/companySearch');
}

/**
 * ダミーログイン
 */

export function DummyLogin(userInfo: any): void {
  myHttpClient.post(baseURL + '/dummy-login', userInfo).then((response: any) => {
    const currentState = getState();
    currentState.isAdmin = response.isAdmin;
    currentState.loginInfo.lastName = response.loginInfo.lastName;
    currentState.loginInfo.firstName = response.loginInfo.firstName;
    currentState.loginInfo.managerFlag = response.loginInfo.managerFlag;
    currentState.loginStatus = 'Completed';
    if (response.isAdmin) {
      setAdminInfo();
    } else {
      if (response.moveTo === '/user-contract/invoice/delete') {
        // history.push('/user-contract/invoice/delete');
        onGetUserContractUnpaidInvoiceData();
      } else {
        history.push('/user/top');
      }
    }
  });
}

/* eslint-disable complexity */
// API Call後共通処理
myHttpClient.interceptors.response.use(
  (response: AxiosResponse<IResponseData>) => {
    if (response.status === 200) {
      if (response.data.appStatus === 'success') {
        return response.data.contents;
      } else {
        history.push('/systemError');
      }
    }
  },
  function(error) {
    // 認証エラー時の処理
    switch (error.response.status) {
      case 400: // Bad Request
        history.push('/systemError');
        break;
      case 401: // 認証に失敗（Unauthorized）
        history.push('/authError');
        break;
      case 403: // アクセス権がない（Forbidden）
        history.push('/cantuse');
        break;
      case 404: // Not Found
        history.push('/notFoundError');
        break;
      case 500:
        history.push('/systemError');
        break;
      default:
        // その他
        history.push('/systemError');
        break;
    }
    return Promise.reject(error);
  }
);
