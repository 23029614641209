import React, { useEffect } from 'react';
import { Col } from 'antd';
import './topPage.css';
import { IProps } from '../../domain/model';
// import IAccountInfo from '../../components/user/accountInfo/accountInfo';
import ServiceContents from '../../components/user/serviceContents/serviceContents';
import IInvoiceList from '../../components/user/invoiceList/invoiceList';
import Notification from '../../components/user/notification/notification';
import { setRecalcFlag } from '../../components/user/notification/alertNotification';
import { onGetCustomerInfo, onGetCustomerInfoWithAdminAuth } from '../../domain/middleware';
/* eslint-disable react/no-unescaped-entities */
export default function TopPage({ state }: IProps): JSX.Element {
  useEffect(() => {
    if (state.isAdmin) {
      if (state.pageId !== '/user/top') {
        onGetCustomerInfoWithAdminAuth();
      }
    } else {
      if (!state.pageId) {
        onGetCustomerInfo(true);
      } else if (state.pageId !== '/user/top') {
        onGetCustomerInfo();
      }
    }
    // eslint-disable-next-line
  }, []);

  function renderContents(): any {
    let tempValue: any = '';
    setRecalcFlag(false);
    if (!state.loading) {
      tempValue = (
        <div>
          <Notification state={state} />
          {/* 
            accountInfoコンポーネントについて、一時的にTOP画面から非表示にすることとなったため、
            .eslintignore および tsconfig.jsonの設定にてaccountInfoコンポーネントをlintやビルドの対象外としている。
            仕様確定後再度表示する場合には、.eslintignore ファイルと tsconfig.jsonの設定を見直す必要がある。
            
          <Col span={22}>
            <IAccountInfo state={state} />
          </Col> */}

          <Col span={22}>
            <ServiceContents state={state} />
          </Col>

          <Col span={22} className="list">
            <IInvoiceList state={state} />
          </Col>
        </div>
      );
    }
    return tempValue;
  }

  return <span>{renderContents()}</span>;
}
