import React from 'react';
import { Tooltip } from 'antd';
import './toolTipMessage.css';

/* eslint-disable react/no-unescaped-entities */
export default function ToolTipMessage({ children, message }: any): JSX.Element {
  const text = <span>{message}</span>;

  return (
    <Tooltip placement="top" title={text} className="customTool">
      <li className="customToolChildren">{children}</li>
    </Tooltip>
  );
}
