import React, { useState, useEffect } from 'react';
import { onSearchJournalData, onSetJournalCreationParams, onExecuteJournalCreation } from '../../../domain/middleware';
import { Form, Button, DatePicker, Checkbox } from 'antd';
import { IProps } from '../../../domain/model';
import './journalDataCreation.css';
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';

export function JournalDataCreation({ state }: IProps): JSX.Element {
  const [execForcedChecked, setExecForcedFlag] = useState(false);
  const [execInvoiceDeletionChecked, setExecInvoiceDeletion] = useState(false);
  const [execOverdueNoticeChecked, setExecOverdueNotice] = useState(false);
  const [execRevenueScheduleUpdateChecked, setExecRevenueScheduelUpdate] = useState(false);

  const { MonthPicker } = DatePicker;
  const targetDate = state.journalCreation.targetDate;
  const disableMonth = !state.journalCreation.forceExecutionFlg;

  useEffect(() => {
    setExecForcedFlag(state.journalCreation.forceExecutionFlg);
    setExecInvoiceDeletion(state.journalCreation.execInvoiceDeletionFlg);
    setExecOverdueNotice(state.journalCreation.execOverdueNoticeFlg);
    setExecRevenueScheduelUpdate(state.journalCreation.execRevenueScheduleUpdateFlg);
  }, [state]);

  const onChangeExecForced = (e: any): void => {
    state.journalCreation.forceExecutionFlg = e.target.checked;

    if (!state.journalCreation.forceExecutionFlg) {
      state.journalCreation.targetDate = state.journalCreation.defaultTargetDate;
      onSetJournalCreationParams(state.journalCreation.targetDate);
    }

    setExecForcedFlag(e.target.checked);
  };

  const onChangeExecInvoiceDeletion = (e: any): void => {
    state.journalCreation.execInvoiceDeletionFlg = e.target.checked;
    setExecInvoiceDeletion(e.target.checked);
  };

  const onChangeExecOverdueNotice = (e: any): void => {
    state.journalCreation.execOverdueNoticeFlg = e.target.checked;
    setExecOverdueNotice(e.target.checked);
  };

  const onChangeExecRevenueScheduleUpdate = (e: any): void => {
    state.journalCreation.execRevenueScheduleUpdateFlg = e.target.checked;
    setExecRevenueScheduelUpdate(e.target.checked);
  };
  return (
    <>
      <Form className="search" layout="inline" onSubmit={onSearchJournalData}>
        <div>
          <Form.Item label="対象日付">
            <MonthPicker
              id="targetDate"
              locale={locale}
              format="YYYY年MM月度"
              defaultValue={targetDate}
              disabled={disableMonth}
              value={targetDate}
              onChange={date => {
                if (date) {
                  onSetJournalCreationParams(date);
                } else {
                  onSetJournalCreationParams();
                }
              }}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox checked={execForcedChecked} onChange={onChangeExecForced}>
              強制実行する
            </Checkbox>
          </Form.Item>
        </div>
        <div className="execFlag">
          <div>
            <Form.Item>
              <Checkbox checked={execInvoiceDeletionChecked} onChange={onChangeExecInvoiceDeletion}>
                未入金請求削除
              </Checkbox>
            </Form.Item>
          </div>
          <div>
            <Form.Item>
              <Checkbox checked={execOverdueNoticeChecked} onChange={onChangeExecOverdueNotice}>
                入金督促メール送信
              </Checkbox>
            </Form.Item>
          </div>
          <div>
            <Form.Item>
              <Checkbox checked={execRevenueScheduleUpdateChecked} onChange={onChangeExecRevenueScheduleUpdate}>
                収益スケジュール変更
              </Checkbox>
            </Form.Item>
          </div>
        </div>
        <div>
          <Form.Item>
            <Button className="createBtn" onClick={() => onExecuteJournalCreation(state.journalCreation)}>
              仕訳実行
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
