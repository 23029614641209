import React, { useEffect } from 'react';
import { AuthCheck } from '../../domain/reducers';

/* eslint-disable react/no-unescaped-entities */
export default function LoginPage(): JSX.Element {
  useEffect(() => {
    AuthCheck('/user/top');
  }, []);

  return <div></div>;
}
