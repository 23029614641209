/**
 * データバックアップ,サービス利用料金
 */
export const BACKUP_CHARGE = 'Backup_Charge';
/**
 * データバックアップ,サービス利用料金(Lite)
 */
export const LITEBACKUP_CHARGE = 'LiteBackup_Charge';
/**
 * Liteセット,Aコース
 */
export const LITESET_ACHARGE = 'LiteSet_ACharge';
/**
 * Liteセット,Bコース
 */
export const LITESET_BCHARGE = 'LiteSet_BCharge';
/**
 * Liteセット,Cコース
 */
export const LITESET_CCHARGE = 'LiteSet_CCharge';
/**
 * Liteセット,Dコース
 */
export const LITESET_DCHARGE = 'LiteSet_DCharge';
/**
 * Liteセット,Eコース
 */
export const LITESET_ECHARGE = 'LiteSet_ECharge';
/**
 * Liteセット,Fコース
 */
export const LITESET_FCHARGE = 'LiteSet_FCharge';
/**
 * Liteセット,Gコース
 */
export const LITESET_GCHARGE = 'LiteSet_GCharge';
/**
 * データバックアップ,初期設定料金
 */
export const ALLBACKUP_INITIAL = 'AllBackup_Initial';
/**
 * データバックアップ,超過利用
 */
export const BACKUP_OVERCHARGE = 'Backup_OverCharge';

/**
 * データバックアップ,超過利用登録手数料
 */
export const BACKUP_OVERINITIAL = 'Backup_OverInitial';
/**
 * cacicar for 建設 1ID（無料）
 */
// export const CACICAR_INITIAL = 'Cacicar_Initial';
export const CACICAR_SINGLE_CHARGE = 'Cacicar_SingleCharge';

/**
 * cacicar for 建設 10ID
 */
export const CACICAR_UNIT_CHARGE = 'Cacicar_UnitCharge';
/**
 * CCUS連携,Aコース【1-99】
 */
export const CCUS_ACHARGE = 'Ccus_ACharge';
/**
 * CCUS連携,Bコース【100-249】
 */
export const CCUS_BCHARGE = 'Ccus_BCharge';
/**
 * CCUS連携,Cコース【250-499】
 */
export const CCUS_CCHARGE = 'Ccus_CCharge';
/**
 * CCUS連携,Dコース【500-999】
 */
export const CCUS_DCHARGE = 'Ccus_DCharge';
/**
 * CCUS連携,Eコース【1000-2999】
 */
export const CCUS_ECHARGE = 'Ccus_ECharge';
/**
 * CCUS連携,Fコース【3000-4999】
 */
export const CCUS_FCHARGE = 'Ccus_FCharge';
/**
 * CCUS連携,Gコース【5000-】
 */
export const CCUS_GCHARGE = 'Ccus_GCharge';
/**
 * CCUS連携,初期設定料金
 */
// export const CCUS_INITIAL = 'Ccus_Initial';
/**
 * システムデータ連携,サービス利用料金
 */
export const DATALINK_CHARGE = 'DataLink_Charge';
/**
 * システムデータ連携,初期設定料金
 */
export const DATALINK_INITIAL = 'DataLink_Initial';
/**
 * 基本サービス(プロジェクト(旧契約・個別)),プロジェクト利用料
 */
export const PROJECTOLDSP_CHARGE = 'ProjectOldSp_Charge';
/**
 * 基本サービス(プロジェクト(旧契約・個別)),契約手数料金
 */
export const PROJECTOLDSP_COMMISSION = 'ProjectOldSp_Commission';
/**
 * 基本サービス(プロジェクト(旧契約)),プロジェクト利用料
 */
export const PROJECTOLD_CHARGE = 'ProjectOld_Charge';
/**
 * 基本サービス(プロジェクト(旧契約)),初期設定料金
 */
export const PROJECTOLD_INITIAL = 'ProjectOld_Initial';
/**
 * 基本サービス(プロジェクト(旧契約)),超過利用
 */
export const PROJECTOLD_OVERINITIAL = 'ProjectOld_OverInitial';
/**
 * 基本サービス(プロジェクト),Aコース【10-99】
 */
export const PROJECT_ACHARGE = 'Project_ACharge';
/**
 * 基本サービス(プロジェクト),Bコース【100-249】
 */
export const PROJECT_BCHARGE = 'Project_BCharge';
/**
 * 基本サービス(プロジェクト),Cコース【250-499】
 */
export const PROJECT_CCHARGE = 'Project_CCharge';
/**
 * 基本サービス(プロジェクト),Dコース【500-999】
 */
export const PROJECT_DCHARGE = 'Project_DCharge';
/**
 * 基本サービス(プロジェクト),Eコース【1000-2999】
 */
export const PROJECT_ECHARGE = 'Project_ECharge';
/**
 * 基本サービス(プロジェクト),Fコース【3000-4999】
 */
export const PROJECT_FCHARGE = 'Project_FCharge';
/**
 * 基本サービス(プロジェクト),Gコース【5000-】
 */
export const PROJECT_GCHARGE = 'Project_GCharge';
/**
 * 基本サービス(プロジェクト),初期設定料金
 */
export const ALL_INITIAL = 'All_Initial';
/**
 * 基本サービス(プロジェクト),超過利用料
 */
export const PROJECT_OVERCHARGE = 'Project_OverCharge';
/**
 * 基本サービス(プロジェクト),超過利用登録手数料
 */
export const PROJECT_OVERINITIAL = 'Project_OverInitial';
/**
 * 基本サービス(Liteプロジェクト),Aコース 【10-99】
 */
export const LITEPROJECT_ACHARGE = 'LiteProject_ACharge';
/**
 * 基本サービス(Liteプロジェクト),Bコース 【100-249】
 */
export const LITEPROJECT_BCHARGE = 'LiteProject_BCharge';
/**
 * 基本サービス(Liteプロジェクト),Cコース 【250-499】
 */
export const LITEPROJECT_CCHARGE = 'LiteProject_CCharge';
/**
 * 基本サービス(Liteプロジェクト),Dコース 【500-999】
 */
export const LITEPROJECT_DCHARGE = 'LiteProject_DCharge';
/**
 * 基本サービス(Liteプロジェクト),Eコース 【1000-2999】
 */
export const LITEPROJECT_ECHARGE = 'LiteProject_ECharge';
/**
 * 基本サービス(Liteプロジェクト),Fコース 【3000-4999】
 */
export const LITEPROJECT_FCHARGE = 'LiteProject_FCharge';
/**
 * 基本サービス(Liteプロジェクト),Gコース 【5000-】
 */
export const LITEPROJECT_GCHARGE = 'LiteProject_GCharge';

// export const TOOLTIP_SHOW_LIST: ReadonlyArray<string> = [DATALINK_INITIAL, DATALINK_CHARGE, CACICAR_INITIAL];
// export const TOOLTIP_SHOW_LIST: ReadonlyArray<string> = [CACICAR_INITIAL];
export const TOOLTIP_SHOW_LIST: ReadonlyArray<string> = [];

export const TOOLTIP_RENEWED_SHOW_LIST: ReadonlyArray<string> = [ALLBACKUP_INITIAL, DATALINK_INITIAL, CACICAR_SINGLE_CHARGE];

/**
 * CCUS連携,コースのリスト
 */
export const CCUS_CHARGE_LIST: ReadonlyArray<string> = [
  CCUS_ACHARGE,
  CCUS_BCHARGE,
  CCUS_CCHARGE,
  CCUS_DCHARGE,
  CCUS_ECHARGE,
  CCUS_FCHARGE,
  CCUS_GCHARGE
];

/**
 * 基本サービス(プロジェクト),コースのリスト
 */
export const PROJECT_CHARGE_LIST: ReadonlyArray<string> = [
  PROJECT_ACHARGE,
  PROJECT_BCHARGE,
  PROJECT_CCHARGE,
  PROJECT_DCHARGE,
  PROJECT_ECHARGE,
  PROJECT_FCHARGE,
  PROJECT_GCHARGE
];

/**
 * 基本サービス(Liteプロジェクト),コースのリスト
 */
export const LITEPROJECT_CHARGE_LIST: ReadonlyArray<string> = [
  LITEPROJECT_ACHARGE,
  LITEPROJECT_BCHARGE,
  LITEPROJECT_CCHARGE,
  LITEPROJECT_DCHARGE,
  LITEPROJECT_ECHARGE,
  LITEPROJECT_FCHARGE,
  LITEPROJECT_GCHARGE
];

/**
 * 基本サービス(プロジェクト)の利用料であるかどうか
 */
export function isProductCharge(productRatePlanChargeKey: string): boolean {
  return PROJECT_CHARGE_LIST.includes(productRatePlanChargeKey);
}

/**
 * 基本サービス(Liteプロジェクト)の利用料であるかどうか
 */
export function isLiteProjectCharge(productRatePlanChargeKey: string): boolean {
  return LITEPROJECT_CHARGE_LIST.includes(productRatePlanChargeKey);
}

/**
 * 初期設定料金と利用料の紐づけ
 */
export const PRODUCT_CATEGORY_LIST: Readonly<any> = {
  ALL_INITIAL: PROJECT_CHARGE_LIST,
  ALLBACKUP_INITIAL: BACKUP_CHARGE,
  CCUS_INITIAL: CCUS_CHARGE_LIST,
  DATALINK_INITIAL: DATALINK_CHARGE,
  PROJECTOLDSP_COMMISSION: PROJECTOLDSP_CHARGE,
  PROJECTOLD_INITIAL: PROJECTOLD_CHARGE
};

/**
 * 変更可能な商品の初期設定料金のリスト
 */
// export const changeableToggleList: ReadonlyArray<string> = [ALLBACKUP_INITIAL, CCUS_INITIAL, DATALINK_INITIAL, DATALINK_CHARGE];
export const changeableToggleList: ReadonlyArray<string> = [ALLBACKUP_INITIAL, DATALINK_INITIAL, DATALINK_CHARGE];
/**
 * トップ画面で契約している数量を表示しない商品リスト
 */
export const NonDisplayAmountList: ReadonlyArray<string> = Array.from(new Set([...CCUS_CHARGE_LIST, BACKUP_CHARGE]));

/**
 * プライム契約(プロジェクト),Aコース【10-99】
 */
export const PRIME_PROJECT_ACHARGE = 'PrimeProject_ACharge';
/**
 * プライム契約(プロジェクト),Bコース【100-249】
 */
export const PRIME_PROJECT_BCHARGE = 'PrimeProject_BCharge';
/**
 * プライム契約(プロジェクト),Cコース【250-499】
 */
export const PRIME_PROJECT_CCHARGE = 'PrimeProject_CCharge';
/**
 * プライム契約(プロジェクト),Dコース【500-999】
 */
export const PRIME_PROJECT_DCHARGE = 'PrimeProject_DCharge';
/**
 * プライム契約(プロジェクト),Eコース【1000-2999】
 */
export const PRIME_PROJECT_ECHARGE = 'PrimeProject_ECharge';
/**
 * プライム契約(プロジェクト),Fコース【3000-4999】
 */
export const PRIME_PROJECT_FCHARGE = 'PrimeProject_FCharge';
/**
 * プライム契約(プロジェクト),Gコース【5000-】
 */
export const PRIME_PROJECT_GCHARGE = 'PrimeProject_GCharge';
/**
 * プライム契約(Liteプロジェクト),Aコース 【10-99】
 */
export const PRIME_LITEPROJECT_ACHARGE = 'LitePrimeProject_ACharge';
/**
 * プライム契約(Liteプロジェクト),Bコース 【100-249】
 */
export const PRIME_LITEPROJECT_BCHARGE = 'LitePrimeProject_BCharge';
/**
 * プライム契約(Liteプロジェクト),Cコース 【250-499】
 */
export const PRIME_LITEPROJECT_CCHARGE = 'LitePrimeProject_CCharge';
/**
 * プライム契約(Liteプロジェクト),Dコース 【500-999】
 */
export const PRIME_LITEPROJECT_DCHARGE = 'LitePrimeProject_DCharge';
/**
 * プライム契約(Liteプロジェクト),Eコース 【1000-2999】
 */
export const PRIME_LITEPROJECT_ECHARGE = 'LitePrimeProject_ECharge';
/**
 * プライム契約(Liteプロジェクト),Fコース 【3000-4999】
 */
export const PRIME_LITEPROJECT_FCHARGE = 'LitePrimeProject_FCharge';
/**
 * プライム契約(Liteプロジェクト),Eコース 【5000-】
 */
export const PRIME_LITEPROJECT_GCHARGE = 'LitePrimeProject_GCharge';

/**
 * プライム契約(プロジェクト)コースのリスト
 */
export const PRIME_PROJECT_CHARGE_LIST: ReadonlyArray<string> = [
  PRIME_PROJECT_ACHARGE,
  PRIME_PROJECT_BCHARGE,
  PRIME_PROJECT_CCHARGE,
  PRIME_PROJECT_DCHARGE,
  PRIME_PROJECT_ECHARGE,
  PRIME_PROJECT_FCHARGE,
  PRIME_PROJECT_GCHARGE
];

/**
 * プライム契約(Liteプロジェクト)コースのリスト
 */
export const PRIME_LITEPROJECT_CHARGE_LIST: ReadonlyArray<string> = [
  PRIME_LITEPROJECT_ACHARGE,
  PRIME_LITEPROJECT_BCHARGE,
  PRIME_LITEPROJECT_CCHARGE,
  PRIME_LITEPROJECT_DCHARGE,
  PRIME_LITEPROJECT_ECHARGE,
  PRIME_LITEPROJECT_FCHARGE,
  PRIME_LITEPROJECT_GCHARGE
];

/**
 * Lite Set 契約コースのリスト
 */
export const LITESET_CHARGE_LIST: ReadonlyArray<string> = [
  LITESET_ACHARGE,
  LITESET_BCHARGE,
  LITESET_CCHARGE,
  LITESET_DCHARGE,
  LITESET_ECHARGE,
  LITESET_FCHARGE,
  LITESET_GCHARGE
];

/**
 * 基本サービス(プロジェクト)の利用料であるかどうか
 */
export function isPrimeProjectCharge(productRatePlanChargeKey: string): boolean {
  return PRIME_PROJECT_CHARGE_LIST.includes(productRatePlanChargeKey);
}

/**
 * 基本サービス(Liteプロジェクト)の利用料であるか
 */
export function isPrimeLiteProjectCharge(productRatePlanChargeKey: string): boolean {
  return PRIME_LITEPROJECT_CHARGE_LIST.includes(productRatePlanChargeKey);
}

/**
 * LiteSetの利用料であるか
 */
export function isLiteSetCharge(productRatePlanChargeKey: string): boolean {
  return LITESET_CHARGE_LIST.includes(productRatePlanChargeKey);
}

/**
 * 2つのproductRatePlanChargeKeyが同じものかを判定（A～Gコースは同じものとみなす）
 * @param productRatePlanChargeKey1
 * @param productRatePlanChargeKey2
 */
export function isSameProduct(productRatePlanChargeKey1: string, productRatePlanChargeKey2: string): boolean {
  if (isProductCharge(productRatePlanChargeKey1) && isProductCharge(productRatePlanChargeKey2)) {
    return true;
  }
  if (isPrimeProjectCharge(productRatePlanChargeKey1) && isPrimeProjectCharge(productRatePlanChargeKey2)) {
    return true;
  }
  if (isLiteProjectCharge(productRatePlanChargeKey1) && isLiteProjectCharge(productRatePlanChargeKey2)) {
    return true;
  }
  if (isPrimeLiteProjectCharge(productRatePlanChargeKey1) && isPrimeLiteProjectCharge(productRatePlanChargeKey2)) {
    return true;
  }
  if (isLiteSetCharge(productRatePlanChargeKey1) && isLiteSetCharge(productRatePlanChargeKey2)) {
    return true;
  }
  return productRatePlanChargeKey1 === productRatePlanChargeKey2;
}

/**
 * 請求・入金画面 digest(簡易表示)
 */
export const INVOICE_DIGEST_SIMPLE =
  '請求,請求（再発行）,請求削除（システム）,請求削除（顧客）,請求削除（請求データ削除）,請求再作成,入金,返金,入金（付替）,入金取消（付替）';

/**
 * 契約変更・次年度更新 追加数上限
 */
export const ADD_QUANTITY_LIMIT = 9999999;
