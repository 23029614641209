import React from 'react';
import CompanyDetail from '../../components/admin/accounting/companyDetail';
import CompanySummary from '../../components/admin/accounting/companySummary';
import { AccountingSearchForm } from '../../components/admin/accounting/searchForm';

/* eslint-disable react/no-unescaped-entities */
export default function AccountingPage(props: any): JSX.Element {
  return (
    <>
      {!props.state.loading && (
        <>
          <CompanySummary />
          <AccountingSearchForm />
          <CompanyDetail windowHeight={props.windowHeight} windowWidth={props.windowWidth} />
        </>
      )}
    </>
  );
}
