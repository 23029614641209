import React, { useState } from 'react';
import { Table, Button, Modal } from 'antd';
import { onPostDeleteUnpaidInvoice, onError, onGetToken, onClearToken } from '../../../domain/middleware';
import * as Model from '../../../domain/model';
import './invoiceDelete.css';

export default function InvoiceDelete({ state }: Model.IProps): JSX.Element {
  const [selectedInvoices, setSelectedInvoices] = useState([] as Model.IUserContractUnpaidInvoiceData[]);
  const [DeleteButtonDisabled, setDeleteButtonDisabled] = useState(true);

  interface IDataSource {
    invoiceId: any;
    userContractInvoiceNumber: any;
    invoiceDate: string;
    userContractCategoryForDisp: string;
  }

  const columns = [
    {
      title: '請求書No',
      dataIndex: 'userContractInvoiceNumber',
      key: 'invoiceNumber',
      width: 180,
      className: 'invoiceNumber'
    },
    {
      title: '発行日',
      dataIndex: 'invoiceDate',
      key: 'createdDate',
      width: 120,
      className: 'date'
    },
    {
      title: '請求内容',
      dataIndex: 'userContractCategoryForDisp',
      width: 300,
      key: 'invoiceContents'
    }
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any[]) => {
      if (!selectedRows.length) {
        setDeleteButtonDisabled(true);
      } else {
        setDeleteButtonDisabled(false);
      }
      setSelectedInvoices(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
      defaultChecked: false
    })
  };
  const dataSource: IDataSource[] = [];

  if (state.userContractUnpaidInvoiceData) {
    state.userContractUnpaidInvoiceData.forEach(e => {
      dataSource.push({
        invoiceId: e.invoiceId,
        userContractInvoiceNumber: e.userContractInvoiceNumber,
        invoiceDate: e.invoiceDate as string,
        userContractCategoryForDisp: e.userContractCategoryForDisp
      });
    });
  }

  let confirmDataSource: IDataSource[] = [];
  async function confirmMordal(): Promise<void> {
    // ワンタイムトークン取得
    await onGetToken();

    function confirmModalContent(): any {
      return (
        <>
          <Table className={'confirmInvoiceDeleteTable'} columns={columns} dataSource={confirmDataSource} bordered pagination={false} />
        </>
      );
    }
    const onPostDeleteUnpaidInvoiceAsync = async (): Promise<void> => {
      try {
        await onPostDeleteUnpaidInvoice(selectedInvoices);
        setSelectedInvoices([]);
      } catch (err) {
        onError(err);
      }
    };
    Modal.confirm({
      title: '選択した請求書の削除処理を実行します。',
      centered: true,
      content: confirmModalContent(),
      width: 800,
      okText: '削除を確定',
      cancelText: '取消',
      onOk: onPostDeleteUnpaidInvoiceAsync,
      onCancel: onClearToken
    });
  }

  function deleteHandleOk(): void {
    confirmDataSource = [];
    selectedInvoices.forEach(e => {
      confirmDataSource.push({
        invoiceId: e.invoiceId,
        userContractInvoiceNumber: e.userContractInvoiceNumber,
        invoiceDate: e.invoiceDate as string,
        userContractCategoryForDisp: e.userContractCategoryForDisp
      });
    });
    confirmMordal();
  }

  return (
    <>
      <div className="notice-line">利用予定のない請求書情報については、削除処理をお願いします。</div>
      <div className="notice-line">代行企業登録料の請求書情報を削除すると、対象代行企業は一定期間経過後に</div>
      <div className="notice-line" style={{ marginBottom: 10 }}>
        グリーンサイトのデータが削除されますので、必ず請求書に記載の対象代行企業をご確認ください。
      </div>
      <div className="notice-line">削除できる請求書情報は、代行企業登録料と、CCUS技能者情報登録支援機能利用料金の</div>
      <div className="notice-line">請求のみとなっております。</div>
      <div className="invoiceDeleteTable">
        <Table rowSelection={rowSelection} columns={columns} dataSource={dataSource} bordered pagination={false} style={{ width: 650 }} />
      </div>
      <Button
        className="DeleteButton"
        key="1"
        disabled={DeleteButtonDisabled}
        onClick={() => {
          deleteHandleOk();
        }}
      >
        削除
      </Button>
    </>
  );
}
