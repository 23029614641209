import { notification } from 'antd';
import * as Const from '../../../domain/const';

const recalcNotification = (): void => {
  notification.info({
    message: '内容を変更した場合には、計算ボタンを再押下してください。',
    placement: 'topLeft',
    duration: 9.5
  });
};

const planChangeNotification = (): void => {
  notification.warning({
    message: 'プロジェクト利用料のコースが変更となっています。変更内容に誤りがないかご確認ください。',
    placement: 'topLeft',
    duration: 9.5
  });
};

const renewedNotification = (): void => {
  notification.warning({
    message:
      '次年度更新実施済のため、次年度更新前の契約に対するプロジェクト利用数の追加は次年度更新後の契約には引き継がれません。' +
      '次年度更新後においても追加が必要な場合は、契約変更をお願いします。',
    placement: 'topLeft',
    duration: 9.5
  });
};

export function basicServiceMustBeTenOrMore(): void {
  notification.error({
    message: 'オーナー基本サービス利用料をお申込みの際は10以上のプロジェクト数をお申し込みください。',
    placement: 'topLeft',
    duration: 9.5
  });
}

export function primeMustBeLessThanBasicNotification(): void {
  notification.error({
    message: 'プライムサービス利用料のプロジェクト数をプロジェクト利用料のプロジェクト数以下に修正してください。',
    placement: 'topLeft',
    duration: 9.5
  });
}
export function primeMustBeTenOrMore(): void {
  notification.error({
    message: 'プライムサービス利用料をお申込みの際は10以上のプロジェクト数をお申し込みください。',
    placement: 'topLeft',
    duration: 9.5
  });
}

export function liteMustBeTenOrMore(): void {
  notification.error({
    message: 'オーナー基本サービスLite利用料をお申込みの際は10以上のプロジェクト数をお申し込みください。',
    placement: 'topLeft',
    duration: 9.5
  });
}

export function litePrimeMustBeLessThanBasicNotification(): void {
  notification.error({
    message: 'Liteプライムサービス利用料のプロジェクト数をLiteプロジェクト利用料のプロジェクト数以下に修正してください。',
    placement: 'topLeft',
    duration: 9.5
  });
}

export function litePrimeMustBeTenOrMore(): void {
  notification.error({
    message: 'Liteプライムサービス利用料をお申込みの際は10以上のプロジェクト数をお申し込みください。',
    placement: 'topLeft',
    duration: 9.5
  });
}

export function liteSetMustBeTenOrMore(): void {
  notification.error({
    message: 'グリーンサイトLiteプライムサービスセット利用料をお申込みの際は10以上のプロジェクト数をお申し込みください。',
    placement: 'topLeft',
    duration: 9.5
  });
}

export function liteProjectAndSetAtSameTime(): void {
  notification.error({
    message: 'オーナー基本サービスLite・オーナープライムサービスLiteとLiteプライムサービスセットを同時に申し込むことはできません。',
    placement: 'topLeft',
    duration: 9.5
  });
}

export function noContractChargeProduct(): void {
  // TODO: Lite表示時以下を有効にする
  // notification.error({
  //   message: 'オーナー基本サービス、グリーンサイトLiteプライムサービスセットのプロジェクト数をすべて0で申し込むことはできません。',
  //   placement: 'topLeft',
  //   duration: 9.5
  // });
  notification.error({
    message: 'オーナー基本サービスのプロジェクト数を0で申し込むことはできません。',
    placement: 'topLeft',
    duration: 9.5
  });
}

let isPlanChanged = false;
export let isNeedRecalc = false;

// eslint-disable-next-line complexity
export function alertNotification(process: string): void {
  if (process === 'calc' && isNeedRecalc) {
    recalcNotification();
  } else if (process === 'planChange' && isPlanChanged) {
    planChangeNotification();
  } else if (process === 'renewed') {
    renewedNotification();
  } else if (process === 'invalidPrime') {
    primeMustBeLessThanBasicNotification();
  } else if (process === 'invalidPrimeLessThanTen') {
    primeMustBeTenOrMore();
  } else if (process === 'invalidBasic') {
    basicServiceMustBeTenOrMore();
  } else if (process === 'invalidLitePrime') {
    litePrimeMustBeLessThanBasicNotification();
  } else if (process === 'invalidLitePrimeLessThanTen') {
    litePrimeMustBeTenOrMore();
  } else if (process === 'liteProjectAndSetAtSameTime') {
    liteProjectAndSetAtSameTime();
  } else if (process === 'noContractChargeProduct') {
    noContractChargeProduct();
  } else if (process === 'invalidLiteLessThanTen') {
    liteMustBeTenOrMore();
  } else if (process === 'invalidLiteSetLessThanTen') {
    liteSetMustBeTenOrMore();
  }
}

export function setPlanChangeFlag(flag: boolean): void {
  isPlanChanged = flag;
}

export function setRecalcFlag(flag: boolean): void {
  isNeedRecalc = flag;
}

export function alertAddQuantityLimit(serviceName: string): void {
  const limit = (Const.ADD_QUANTITY_LIMIT + 1).toLocaleString();
  notification.error({
    message: `${serviceName}の追加数を${limit}未満に修正してください。`,
    placement: 'topLeft',
    duration: 9.5
  });
}
