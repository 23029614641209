import { Modal } from 'antd';

export default function acceptDeletionModal(content: string, onOkFunction: () => void): void {
  Modal.success({
    centered: true,
    content,
    width: 550,
    onOk() {
      onOkFunction();
    }
  });
}
