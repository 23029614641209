import React from 'react';
import { IProps } from '../../domain/model';
import { JournalDataList } from '../../components/admin/journalDataDownload/journalDataDownload';

export default function JournalDataDownloadPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <JournalDataList state={state} />
        </>
      )}
    </>
  );
}
