import React from 'react';
import { Modal } from 'antd';
import { onError, onGetToken, onClearToken } from '../../../domain/middleware';
import './confirmModal.css';

export async function showConfirmModal(text: string, onOKClick: any): Promise<void> {
  // ワンタイムトークン取得
  await onGetToken();

  const onOkClickAsync = async (): Promise<void> => {
    try {
      onOKClick();
    } catch (err) {
      onError(err);
    }
  };
  Modal.confirm({
    centered: true,
    icon: <img src={`${process.env.PUBLIC_URL}/Attention.png`} alt="modalIcon" className="modalIcon"></img>,
    content: text,
    cancelText: 'キャンセル',
    onOk: onOkClickAsync,
    onCancel: onClearToken
  });
}
