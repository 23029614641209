import { IState } from './model';
import moment from 'moment';
import { INVOICE_DIGEST_SIMPLE } from '../domain/const';

export type Subscriber = (state: IState) => void;

/**
 * stateが変更された際に通知を受け取るハンドラー。subscribe()で登録
 */
export const subscribers: Subscriber[] = [];

/**
 * アプリケーションで管理するステート
 */
let state: IState = {
  loginStatus: 'Init',
  loading: true,
  isAdmin: false,
  pageId: '',
  pageTitle: '',
  accountInfo: {
    account: '',
    companyType: '',
    stop: ''
    // accountNumber: '',
    // department: '',
    // email: '',
    // zipCode: '',
    // addressPrefecture: '',
    // addressCityAndTown: '',
    // addressStreet: '',
    // addressBuilding: ''
  },
  loginInfo: {
    lastName: '',
    firstName: '',
    managerFlag: ''
  },
  subscriptionInfo: [],
  unpaidInvoiceList: [
    {
      amount: 0,
      dueDate: '',
      createdDate: '',
      invoiceId: '',
      invoiceNumber: '',
      status: '',
      customerRemark: ''
    }
  ],
  completedInvoiceList: [
    {
      amount: 0,
      dueDate: '',
      createdDate: '',
      invoiceId: '',
      invoiceNumber: '',
      status: '',
      customerRemark: ''
    }
  ],
  notifyInfo: [
    {
      isNotify: false,
      serviceName: '',
      isOutOfRange: false,
      isRenewed: false
    }
  ],
  productList: {
    subscriptionName: '',
    currentDate: '',
    productList: []
  },
  courseInfo: {
    basicProjectList: [],
    primeProjectList: [],
    liteProjectList: [],
    litePrimeProjectList: [],
    liteSetList: []
  },
  updateForm: {
    remainTerm: '',
    remainMonths: '',
    contractEffectiveDate: '',
    subtotal: [],
    total: 0,
    savedUserInputList: [],
    relatedProductList: [],
    newCourse: {
      productId: '',
      productRatePlanId: '',
      productRatePlanChargeId: '',
      subscriptionId: '',
      ratePlanId: '',
      ratePlanChargeId: '',
      amount: 0,
      salesUnit: 0,
      uom: '',
      productName: '',
      productRatePlanName: '',
      unitCost: 0,
      contractStatus: false,
      initialSetUp: false,
      productRatePlanChargeKey: ''
    },
    ownerBasicProductList: [],
    updateContract: {
      subscriptionName: '',
      targetDate: '',
      products: [],
      customerRemark: ''
    }
  },
  previewForm: {
    targetDate: '',
    initialTerm: '',
    products: []
  },
  searchCompanyList: {
    isGetSearchResult: false,
    companyList: [],
    targetDate: ''
  },
  searchCondition: {
    companyId: '',
    companyName: '',
    virtualAccountNoPayment: '',
    invoiceNumber: ''
  },
  searchCompanyPagination: { offset: 0, totalCount: 0 },
  accountingTransferSearchCondition: {
    companyId: '',
    companyName: '',
    virtualAccountNoPayment: '',
    invoiceNumber: ''
  },
  companyListForTransfer: [],
  companyListForTransferPagination: { offset: 0, totalCount: 0 },
  userContextUpload: {
    invoiceFileList: [],
    deletionFileList: [],
    paymentFileList: []
  },
  downloadForm: {
    targetDate: ''
  },
  userContractStatus: {
    headerList: [],
    detailList: [],
    deleteDetailList: [],
    params: { targetDateFrom: moment(), targetDateTo: moment() }
  },
  userPaymentStatus: {
    headerList: [],
    detailList: [],
    params: { targetDateFrom: moment(), targetDateTo: moment() }
  },
  accountingDetailList: [],
  accountingSummary: {
    companyId: '',
    companyName: '',
    accountBalance: 0,
    creditBalance: 0,
    invoiceBalance: 0,
    companyType: '',
    chargingCycleStartDate: '',
    chargingCycleEndDate: ''
  },
  accountingSearchCondition: {
    companyId: '',
    targetDateTo: '',
    targetDateFrom: '',
    onlyInvoiceWithBalance: false,
    digest: INVOICE_DIGEST_SIMPLE
  },
  reconcileModalVisible: false,
  refundModalVisible: false,
  updateRefundModal: {
    modalVisible: false,
    targetRefundPaymentAmount: 0,
    targetRefundCreatedDate: '',
    refundInfo: { reason: '', refundId: '', isCompleted: false, galileoptClientCode: '' }
  },
  journalCreation: {
    targetDate: moment().add(-1, 'month'),
    defaultTargetDate: moment().add(-1, 'month'),
    forceExecutionFlg: false,
    execInvoiceDeletionFlg: false,
    execOverdueNoticeFlg: false,
    execRevenueScheduleUpdateFlg: false
  },
  journalDataDownload: {
    journalList: [],
    batchRunList: [],
    params: { targetDate: moment().add(-1, 'month') }
  },
  idInvoiceDownload: {
    idInvoiceList: [],
    params: { targetDate: moment() }
  },
  paymentOkDownload: {
    paymentOkList: [],
    params: { targetDate: moment() }
  },
  userContractUnpaidInvoiceData: [],
  moveTo: '',
  invoiceListPagination: { offset: 0, totalCount: 0 }
};

/**
 * ステート変更ハンドラー登録
 */
export function subscribe(subscriber: Subscriber): void {
  subscribers.push(subscriber);
}

/**
 * ステート変更通知
 */
export function emit(): void {
  subscribers.forEach(function(subscriber) {
    subscriber(state);
  });
}

/**
 * ステート取得
 */
export function getState(): IState {
  return state;
}

/**
 * ステート更新
 */
export function setState(reducer: (oldState: IState) => IState): IState {
  state = reducer(state);
  emit();
  return state;
}
