import React from 'react';
import { IProps } from '../../domain/model';
import UserPayentStatusDetail from '../../components/admin/statusMgmt/userPaymentStatusDetail';

export default function userPaymentStatusMgmtDetailPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <UserPayentStatusDetail state={state} />
        </>
      )}
    </>
  );
}
