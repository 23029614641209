import * as Reducers from './reducers';
import * as Model from './model';
import moment, { Moment } from 'moment';
import history from './history';
import { alertNotification } from '../components/user/notification/alertNotification';
import { FormEvent } from 'react';
import { getState } from './store';

/**
 * ローディングの状態を保存
 * @param loading ローディングの状態
 */
export function setLoading(loading: boolean): void {
  Reducers.isLoading(loading);
}

/**
 * 更新処理のワンタイムトークンを取得する
 */
export async function onGetToken(): Promise<void> {
  await Reducers.getToken();
}

/**
 * 更新処理のワンタイムトークンを削除する
 */
export async function onClearToken(): Promise<void> {
  await Reducers.clearToken();
}

/**
 * 管理者権限でTOP画面の情報を取得する
 * @param companyId 企業ID
 */
export function onGetCustomerInfoWithAdminAuth(companyId?: string): void {
  Reducers.isLoading(true);
  Reducers.getCustomerInfoWithAdminAuth(companyId);
}

/**
 * TOP画面の情報を取得する
 */
export function onGetCustomerInfo(fromCallback = false): void {
  Reducers.isLoading(true);
  Reducers.getCustomerInfo(fromCallback);
}

/**
 * 請求書のＰＤＦを取得する
 * @param invoiceId 請求書ID
 * @param invoiceNumber Zuora請求書番号
 */
export function onGetInvoicePDF(invoiceId: string, invoiceNumber: string): void {
  Reducers.getInvoicePDF(invoiceId, invoiceNumber);
}

/**
 * 未払請求書をキャンセルする
 * @param invoiceId 請求書ID
 * @param invoiceNumber 請求書番号
 */
export async function onCancelOwnerUnapaidInvoice(invoiceId: string, invoiceNumber: string): Promise<void> {
  Reducers.isLoading(true);
  await Reducers.postCancelOwnerUnpaidInvoice(invoiceId, invoiceNumber);
}

export async function onPageChangeInvoiceList(page: number): Promise<void> {
  Reducers.setInvoiceListOffset(page);
}
/**
 * 指定の年月日を対象日付として
 * 必要な契約情報を取得する
 * @param serviceName サービス名
 * @param category カテゴリ名
 * @param targetDate 対象日付
 */
export function onGetUpdateProducList(serviceName: string, category: string, targetDate: string): void {
  Reducers.isLoading(true);
  Reducers.getUpdateProducList(serviceName, category, targetDate);
}

/**
 * 契約変更画面に遷移する（画面表示データなし）
 * @param serviceName サービス名
 * @param startDate 契約有効期間開始日
 * @param endDate 契約有効期間終了日
 * @param renewedEndDate 現在契約有効期間終了日
 * @param subscriptionName サブスクリプション名
 * @param category カテゴリ
 */
export function onGoToContractUpdate(
  serviceName: string,
  startDate: string,
  endDate: string,
  renewedEndDate: string,
  subscriptionName: string,
  category: string
): void {
  Reducers.isLoading(true);
  Reducers.goToContractUpdate(serviceName, startDate, endDate, renewedEndDate, subscriptionName, category);
}

/**
 * 契約更新画面に必要な商品リストを取得する
 * @param serviceName サービス名
 * @param startDate 契約有効期間開始日
 * @param endDate 契約有効期間終了日
 * @param subscriptionName サブスクリプション名
 */
export function onGetRenewProducList(serviceName: string, startDate: string, endDate: string, subscriptionName: string): void {
  Reducers.isLoading(true);
  Reducers.getRenewProducList(serviceName, startDate, endDate, subscriptionName);
}

/**
 * 画面遷移
 * @param pageName 画面名
 */
/* eslint-disable complexity */
export async function navigationTo(pageName: string): Promise<void> {
  const currentState = getState();
  Reducers.isLoading(true);
  await Reducers.clearToken();
  switch (pageName) {
    case 'top':
      history.push('/user/top');
      break;
    case 'update':
      history.push('/user/contract/update');
      break;
    case 'new':
      history.push('/user/contract/renew');
      break;
    case 'comp':
      history.push('/user/contract/completed');
      break;
    case 'back':
      Reducers.isLoading(true);
      Reducers.refreshContractData();
      if (currentState.isAdmin) {
        Reducers.getCustomerInfoWithAdminAuth();
      } else {
        Reducers.getCustomerInfo();
      }
      break;
    case 'adminSearch':
      Reducers.isLoading(true);
      Reducers.refreshAdmin();
      break;
    case 'adminOverCharge':
      Reducers.isLoading(true);
      Reducers.refreshAdminOverCharge();
      break;
    case 'userContractUpload':
      Reducers.isLoading(true);
      Reducers.refreshAdminUserContractUpload();
      break;
    case 'accounting':
      history.push('/admin/accounting');
      break;
    case 'accountingTransfer':
      Reducers.refreshTransfer();
      history.push('/admin/accounting/transfer');
      break;
    case 'adminIdInvoiceDownload':
      Reducers.isLoading(true);
      Reducers.refreshAdminIdInvoiceDownload();
      break;
    case 'userPaymentUpload':
      Reducers.isLoading(true);
      Reducers.refreshAdminUserPaymentUpload();
      break;
    case 'userContractStatus':
      Reducers.isLoading(true);
      Reducers.refreshAdminUserContractStatus(false);
      break;
    case 'userPaymentStatus':
      Reducers.isLoading(true);
      Reducers.refreshAdminUserPaymentStatus(false);
      break;
    case 'adminJournalCreation':
      Reducers.isLoading(true);
      Reducers.refreshAdminJournalCreation();
      break;
    case 'adminJournalDataStatus':
      Reducers.isLoading(true);
      Reducers.refreshAdminJournalDataStatus();
      break;
    case 'adminPaymentOkDownload':
      Reducers.isLoading(true);
      Reducers.refreshAdminPaymentOkDownload();
      break;
    case 'UserContractUnpaidInvoice':
      Reducers.isLoading(true);
      Reducers.refreshUserContractUnpaidInvoiceData();
      break;
    default:
      break;
  }
}

/**
 * PreviewDataをクリアする
 */
export function onRefreshPreviewData(): void {
  Reducers.refreshPreviewData();
}

/**
 * 契約追加画面のカレンダー選択時に発火される
 * @param dateValue 選択した日付
 * @param endDateValue 契約有効期間終了日
 * @param isRenewed 次年度更新済みフラグ
 * @param renewedEndDateValue 現在契約有効期間終了日
 */
export function onSetTerm(dateValue: string, endDateValue: string, isRenewed: boolean, renewedEndDateValue: string): void {
  const userSelectDate = moment(dateValue);
  const subscriptionEndDate = moment(endDateValue);
  const subscriptionRenewedEndDate = moment(renewedEndDateValue);
  let endDate = subscriptionEndDate;
  //次年度更新済み且つ選択月が現行契約内の場合、notificationを表示する
  if (isRenewed && userSelectDate <= subscriptionEndDate) {
    alertNotification('renewed');
  }
  //次年度更新済み且つ選択月が現行契約より未来の場合、表示する契約期間の終了日付を次年度更新の終了日付に変更
  if (isRenewed && userSelectDate > subscriptionEndDate) {
    endDate = subscriptionRenewedEndDate;
  }

  let remainMonths: number = endDate.diff(userSelectDate, 'months');
  remainMonths = remainMonths + 1;
  const effectiveDate = userSelectDate.startOf('month').format('YYYY-MM-DD');

  const dateItem = userSelectDate.format('YYYY/MM') + '～' + endDate.format('YYYY/MM') + `・${remainMonths}か月`;

  Reducers.setTerm(dateItem, effectiveDate, remainMonths.toString());
}

/**
 * 契約更新時に変更した新しいコースを保存する
 * @param obj 新しいコースの基本プロジェクトデータ
 */
export function onSetNewCourse(obj: Model.IOneProduct): void {
  Reducers.setNewCourse(obj);
}

/**
 * プライム契約のコース変更時に保存しておく
 * @param obj 新しいコースの基本プロジェクトデータ
 */
export function onSetPrimeCourse(obj: Model.IOneProduct): void {
  Reducers.setPrimeCourse(obj);
}

/**
 * 契約の見積もりを行う
 * @param pageId ページID（renew : 次年度更新, update : 契約更新）
 */
export function onPreviewContract(pageId: string): void {
  Reducers.isLoading(true);
  Reducers.previewContract(pageId);
}

/**
 * 契約の変更を行う
 */
export function onUpdateContract(): void {
  Reducers.isLoading(true);
  Reducers.updateContract();
}

/**
 * 契約の更新を行う
 */
export function onRenewContract(): void {
  Reducers.isLoading(true);
  Reducers.renewContract();
}

/* eslint-disable no-new-object */
/**
 * 企業の検索を行う
 * @param values 企業検索条件
 */
export function onSearchCompany(values: any, offset?: number): void {
  Reducers.isLoading(true);
  Reducers.setSearchCondition(values);
  Reducers.setSearchOffset(offset || 0);
  Reducers.searchCompany(values, offset);
}

/**
 * 企業の検索を行う
 * @param values 企業検索条件
 */
export function onSearchCompanyForTransfer(values: any, offset?: number): void {
  Reducers.isLoading(true);
  Reducers.setSearchConditionForTransfer(values);
  Reducers.setSearchOffsetForTransfer(offset || 0);
  Reducers.searchCompanyForTransfer(values, offset);
}

/* eslint-disable no-new-object */
/**
 * 他の画面から企業検索に戻り、保存された条件で検索する
 */
export function onBackToSearchCompany(): void {
  Reducers.isLoading(true);
  Reducers.searchCompany();
  history.push('/admin/companySearch');
}
/**
 * 他の画面から請求・入金管理に戻り、保存された条件で検索する
 */
export function onBackToAccounting(): void {
  Reducers.isLoading(true);
  Reducers.refreshAccountingInfo();
  history.push('/admin/accounting');
}

// 超過請求可能な企業一覧取得
/* eslint-disable no-new-object */
export function onSearchOverChargeCompany(): void {
  Reducers.isLoading(true);
  Reducers.searchOverChargeCompany();
}

/**
 * 預り金付替 再検索
 */
export function onRefreshSearchCompanyForTransfer(): void {
  Reducers.isLoading(true);
  Reducers.refreshSearchCompanyForTransfer();
}

/**
 * 超過料登録
 * @param companyId 企業ID
 * @param param 対象となる超過請求
 */
export function onUpdateOverCharge(companyId: string, param: Model.IOverChargeCompany): void {
  Reducers.isLoading(true);
  Reducers.updateOverCharge(companyId, param);
}

/**
 * ユーザー契約 請求・請求削除データ ファイルアップロード
 */
export function onUploadUserContractFile(invoiceFileList: File[], deletionFileList: File[]): void {
  Reducers.uploadUserContractFile(invoiceFileList, deletionFileList);
}

/**
 * ユーザー契約 入金データ ファイルアップロード
 */
export function onUploadUserPaymentFile(paymentFileList: File[]): void {
  Reducers.uploadUserPaymentFile(paymentFileList);
}

/**
 * ユーザー契約 ファイル追加（請求データ）
 * @param file アップロードするファイル
 */
export function onAddUserContractInvoiceFile(file: File): void {
  Reducers.addUserContractInvoiceFile(file);
}

/**
 * ユーザー契約 ファイル追加（請求削除データ）
 * @param file アップロードするファイル
 */
export function onAddUserContractDeletionFile(file: File): void {
  Reducers.addUserContractDeletionFile(file);
}

/**
 * ユーザー契約 ファイル追加（入金データ）
 * @param file アップロードするファイル
 */
export function onAddUserContractPaymentFile(file: File): void {
  Reducers.addUserContractPaymentFile(file);
}

/**
 * ユーザー契約 ファイル削除（請求データ）
 * @param file 削除対象のファイル
 */
export function onRemoveInvoiceFile(file: File): void {
  Reducers.removeInvoiceFile(file);
}

/**
 * ユーザー契約 ファイル削除（請求削除データ）
 * @param file 削除対象のファイル
 */
export function onRemoveDeletionFile(file: File): void {
  Reducers.removeDeletionFile(file);
}

/**
 * ユーザー契約 ファイル削除（入金データ）
 * @param file 削除対象のファイル
 */
export function onRemovePaymentFile(file: File): void {
  Reducers.removePaymentFile(file);
}

/**
 * ユーザー契約 請求データファイルリストをクリアする
 */
export function onClearUserContractInvoiceFile(): void {
  Reducers.clearUserContractUploadInvoiceList();
}

/**
 * ユーザー契約 請求削除データファイルリストをクリアする
 */
export function onClearUserContractDeletionFile(): void {
  Reducers.clearUserContractUploadDeletionList();
}

/**
 * ユーザー契約 入金データファイルリストをクリアする
 */
export function onClearUserContractPaymentFile(): void {
  Reducers.clearUserContractUploadPaymentList();
}

/**
 * ユーザー契約 ステータス検索用パラメータをセットする。
 * @param dateFrom 検索範囲開始日付
 * @param dateTo 検索範囲終了日付
 */
export function onSetUserContractStatusParams(dateFrom?: Moment, dateTo?: Moment): void {
  Reducers.setUserContractStatusParams(dateFrom, dateTo);
}

/**
 * ユーザー契約 アップロードステータスを検索する。
 */
export function onSearchUserContractStatus(e: FormEvent): void {
  e.preventDefault();
  Reducers.isLoading(true);
  Reducers.searchAdminUserContractStatus();
}

/**
 * ユーザー契約 アップロード ステータス明細画面情報を取得し、画面遷移する
 * @param mgmtId アップロード管理ID
 */
export function onGetUserContractUploadStatusDetail(mgmtId: number, uploadType: string): void {
  Reducers.isLoading(true);
  Reducers.getUserContractStatusDetail(mgmtId, uploadType);
}

/**
 * ユーザー契約 登録明細情報をダウンロードする
 * @param mgmtId 管理ID
 * @param fileName ダウンロード対象のファイル名
 */
export function onDownloadUserContractUploadStatusDetail(mgmtId: number): void {
  Reducers.isLoading(true);
  Reducers.DownloadUserContractUploadStatusDetail(mgmtId);
}

/**
 * ユーザー契約 削除明細情報をダウンロードする
 * @param mgmtId 管理ID
 * @param fileName ダウンロード対象のファイル名
 */
export function onDownloadInvoiceDeleteStatusDetail(mgmtId: number): void {
  Reducers.isLoading(true);
  Reducers.DownloadInvoiceDeleteStatusDetail(mgmtId);
}

/**
 * ユーザー入金 ステータス検索用パラメータをセットする。
 * @param dateFrom 検索範囲開始日付
 * @param dateTo 検索範囲終了日付
 */
export function onSetUserPaymentStatusParams(dateFrom?: Moment, dateTo?: Moment): void {
  Reducers.setUserPaymentStatusParams(dateFrom, dateTo);
}

/**
 * ユーザー入金 アップロードステータスを検索する。
 */
export function onSearchUserPaymentStatus(e: FormEvent): void {
  e.preventDefault();
  Reducers.isLoading(true);
  Reducers.searchAdminUserPaymentStatus();
}

/**
 * ユーザー入金 アップロード ステータス明細画面情報を取得し、画面遷移する
 * @param mgmtId アップロード管理ID
 */
export function onGetUserPaymentUploadStatusDetail(mgmtId: number): void {
  Reducers.isLoading(true);
  Reducers.getUserPaymentStatusDetail(mgmtId);
}

/**
 * ユーザー入金 アップロード ステータス明細情報をダウンロードする
 * @param userPaymentMgmtId アップロード管理ID
 * @param fileName ダウンロード対象のファイル名
 */
export function onDownloadUserPaymentStatusDetail(userPaymentMgmtId: number): void {
  Reducers.isLoading(true);
  Reducers.DownloadUserPaymentStatusDetail(userPaymentMgmtId);
}

/**
 * 仕訳実行用パラメータをセットする。
 * @param date 対象日付
 */
export function onSetJournalCreationParams(date?: Moment): void {
  Reducers.setJournalCreationParams(date);
}

/**
 * 仕訳実行依頼を行う。
 * @param params 仕訳実行用パラメータ
 */
export function onExecuteJournalCreation(params: Model.IJournalCreation): void {
  Reducers.isLoading(true);
  Reducers.executeJournalCreation(params);
}

/**
 * 仕訳データを検索する。
 */
export function onSearchJournalData(e: FormEvent): void {
  e.preventDefault();
  Reducers.isLoading(true);
  Reducers.searchAdminJournalData();
}

/**
 * 仕訳データをダウンロードする。
 * @param mgmtId 管理ID
 * @param fileName ダウンロード対象のファイル名
 */
export function onDownloadJournalData(mgmtId: number, fileName: string): void {
  Reducers.isLoading(true);
  Reducers.downloadJournalData(mgmtId, fileName);
}

/**
 * 仕訳データ検索用パラメータをセットする。
 * @param date 対象日付
 */
export function onSetJournalDataParams(date?: Moment): void {
  Reducers.setJournalDataParams(date);
}

/* eslint-disable no-new-object */
/**
 * ID追加請求書ダウンロード
 * @param values 請求書発行日付
 */
export function onDownloadZip(targetDate: string): void {
  Reducers.isLoading(true);
  Reducers.downloadZip(targetDate);
}

/**
 * ID追加請求書を検索する。
 */
export function onSearchIdInvoice(e: FormEvent): void {
  e.preventDefault();
  Reducers.isLoading(true);
  Reducers.searchAdminIdInvoice();
}

/**
 * ID追加請求書検索用パラメータをセットする。
 * @param date 対象日付
 */
export function onSetIdInvoiceParams(date?: Moment): void {
  Reducers.setIdInvoiceParams(date);
}

/**
 * 入金OKファイルを検索する。
 */
export function onSearchPaymentOk(e: FormEvent): void {
  e.preventDefault();
  Reducers.isLoading(true);
  Reducers.searchAdminPaymentOk();
}

/**
 * 入金OKファイルをダウンロードする。
 * @param paymentOkId 入金OKID
 * @param fileName ダウンロード対象のファイル名
 */
export function onDownloadPaymentOk(paymentOkId: number, fileName: string): void {
  Reducers.isLoading(true);
  Reducers.downloadPaymentOk(paymentOkId, fileName);
}

/**
 * 入金OKファイル検索用パラメータをセットする。
 * @param date 対象日付
 */
export function onSetPaymentOkParams(date?: Moment): void {
  Reducers.setPaymentOkParams(date);
}

/**
 * 請求・入金管理画面 初期表示
 */
export function onGetAccountingInfo(
  companyId: string,
  dateFrom: string,
  dateTo: string,
  onlyInvoiceWithBalance: boolean,
  digest: string,
  invoiceNumber?: string
): void {
  Reducers.isLoading(true);
  Reducers.getAccountingInfo(companyId, dateFrom, dateTo, onlyInvoiceWithBalance, digest, invoiceNumber);
}

/**
 * 請求・入金管理画面 リフレッシュ表示
 */
export function onRefreshAccountingInfo(): void {
  Reducers.isLoading(true);
  Reducers.refreshAccountingInfo();
}

/**
 * 請求・入金管理画面 消込対象請求書リスト取得
 */
export function onGetReconcileTargetList(companyId: string): void {
  Reducers.isLoading(true);
  Reducers.getReconcileModalInfo(companyId);
}

/**
 * 請求・入金管理画面 返金実行
 */
export async function onPostRefund(companyId: string, amount: number, galileoptClientCode: string, reason: string): Promise<void> {
  Reducers.isLoading(true);
  await Reducers.postRefund(companyId, amount, galileoptClientCode, reason);
}

/**
 * 請求・入金管理画面 消込実行
 */
export async function onPostReconcile(companyId: string, invoices: { invoiceNumber: string }[]): Promise<void> {
  Reducers.isLoading(true);
  await Reducers.postReconcile(companyId, invoices);
}

/**
 * 請求・入金管理画面 ユーザ契約請求書 取得
 */
export function onGetUserContractInvoiceInfo(invoiceNumber: string, invoiceAmount: number, dueDate: string): void {
  Reducers.isLoading(true);
  Reducers.getUserContractInvoiceInfo(invoiceNumber, invoiceAmount, dueDate);
}

/**
 * 請求・入金管理画面 消込モーダル キャンセル
 */
export function onCloseReconcileModal(): void {
  Reducers.setReconcileModalVisble(false);
}

/**
 * 預り金付替実行
 */
export function onPutTransferPayment(
  transferSourceCompanyId: string,
  transferDestinationCompanyId: string,
  amount: number,
  galileoptItems: { invoiceCompanyName: string; requestNo: string }
): void {
  Reducers.isLoading(true);
  Reducers.putTransferPayment(transferSourceCompanyId, transferDestinationCompanyId, amount, galileoptItems);
}

/**
 * 消込取消実行
 */
export function onPostDeleteInvoicePayment(companyId: string, cbaId: string): void {
  Reducers.isLoading(true);
  Reducers.postDeleteInvoicePayment(companyId, cbaId);
}

/**
 * 明細 備考登録・更新
 */
export function onPutRemark(detail: any, remark: string): void {
  Reducers.isLoading(true);
  Reducers.putRemark(detail, remark);
}

/**
 * 返金情報取得
 */

export function onGetUpdateRefundInfo(
  companyId: string,
  cbaId: string,
  targetRefundCreatedDate: string,
  targetRefundPaymentAmount: number
): void {
  Reducers.isLoading(true);
  Reducers.getUpdateRefundInfo(companyId, cbaId, targetRefundCreatedDate, targetRefundPaymentAmount);
}

/**
 * 返金情報更新
 */
export function onUpdateRefundInfo(refundId: string, isCompleted: true): void {
  Reducers.isLoading(true);
  Reducers.updateRefundInfo(refundId, isCompleted);
}

/**  返金追加情報モーダル 非表示 */

export function onCloseUpdateRefundModal(): void {
  Reducers.setUpdateRefundModalVisible(false);
}

export function onLogout(): void {
  Reducers.logout();
}

/** 不要請求削除画面 初期表示 */
export function onGetUserContractUnpaidInvoiceData(fromCallback = false): void {
  Reducers.isLoading(true);
  Reducers.userContractUnpaidInvoiceData(fromCallback);
}

/**
 * 不要請求削除画面 請求削除実行
 */
export async function onPostDeleteUnpaidInvoice(selectedInvoices: Model.IUserContractUnpaidInvoiceData[]): Promise<void> {
  Reducers.isLoading(true);
  await Reducers.postUnpaidInvoice(selectedInvoices);
}

/**
 *不要請求削除画面 完了ダイアログのOK押下後、不要請求削除画面を再表示
 */
export function onRefreshSearchUnpaidInvoiceData(): void {
  Reducers.isLoading(true);
  Reducers.refreshUserContractUnpaidInvoiceData();
}

/**
 * エラー処理
 * @param cause エラー原因
 */
export const onError = (cause: unknown): void => {
  Reducers.error(cause);
};
