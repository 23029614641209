import React, { useEffect } from 'react';
import { AuthCheck } from '../../domain/reducers';

/* eslint-disable react/no-unescaped-entities */
export default function UserLoginPage(): JSX.Element {
  useEffect(() => {
    AuthCheck('/user-contract/invoice/delete');
  }, []);

  return <div></div>;
}
