import React from 'react';
import { IProps } from '../../domain/model';
import UserContractStatus from '../../components/admin/statusMgmt/userContractStatus';

export default function userContractStatusMgmtPage({ state }: IProps): JSX.Element {
  return (
    <>
      {!state.loading && (
        <>
          <UserContractStatus state={state} />
        </>
      )}
    </>
  );
}
