import React, { useState } from 'react';
import { Button, Descriptions, Modal, Table, PageHeader, Breadcrumb, InputNumber, Divider, Input } from 'antd';
import { getState } from '../../../domain/store';
import { numberFormater, yyyymmddSlashFormatter, convertCompanyType } from '../../../domain/common';
import './companySummary.css';
import refundModal from './refundModal';
import {
  onGetReconcileTargetList,
  onCloseReconcileModal,
  onPostReconcile,
  onBackToSearchCompany,
  navigationTo,
  onError,
  onGetToken,
  onClearToken
} from '../../../domain/middleware';
import * as common from '../../../domain/common';
import * as Model from '../../../domain/model';

export default function CompanySummary(): JSX.Element {
  const [selectedInvoices, setSelectedInvoices] = useState([] as Model.IAccountingCompanyDetail[]);
  const [okButtonDisabled, setOkButtonDisabled] = useState(true);
  const [okRefundButtonDisabled, setOkRefundButtonDisabled] = useState(true);
  const [refundModalVisible, setRefundModalVisible] = useState(getState().refundModalVisible);
  const [totalSelectedAmount, setTotalSelectedAmount] = useState(0);
  const [refundAmount, setRefundAmount] = useState(0);
  const [remainingCreditBalance, setRemainingCreditBalance] = useState(getState().accountingSummary.creditBalance);
  const [modalMsg, setModalMsg] = useState('1つ以上の請求書を選択してください。');
  const [reason, setReason] = useState('');
  const [galileoptClientCode, setGalileoptClientCode] = useState('');

  const summary = getState().accountingSummary;
  const reconcileTarget = getState().reconcileTargetList;
  const reconcileModalVisible = getState().reconcileModalVisible;

  const isAdminMCDPUser = getState().loginInfo.managerFlag === '6';

  interface IDataSource {
    targetDate: string;
    invoiceNumber: any;
    category: string;
    invoiceAmount: number;
    invoiceAmountDisp: any;
  }
  const columns = [
    {
      title: '日付',
      dataIndex: 'targetDate',
      key: 'targetDate'
    },
    {
      title: '請求書番号',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber'
    },
    {
      title: '仕分',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: '請求額',
      dataIndex: 'invoiceAmountDisp',
      key: 'invoiceAmountDisp',
      className: 'right'
    }
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any[]) => {
      let tmpTotalAmount = 0;
      selectedRows.forEach(e => {
        tmpTotalAmount += e.invoiceAmount as number;
      });
      // 1つも選択されていないとき OKボタンをdisabled
      if (!selectedRows.length) {
        setModalMsg('1つ以上の請求書を選択してください。');
        setOkButtonDisabled(true);
      }
      // 選択された請求合計が預り金残より多い時OKボタンをdisabled
      else if (tmpTotalAmount > summary.creditBalance) {
        setModalMsg('預り金の範囲内で消し込む請求書を選択してください。');
        setOkButtonDisabled(true);
      } else {
        setOkButtonDisabled(false);
        setModalMsg('');
      }
      setTotalSelectedAmount(tmpTotalAmount);
      setSelectedInvoices(selectedRows);
      setRemainingCreditBalance(summary.creditBalance - tmpTotalAmount);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name
    })
  };
  const dataSource: IDataSource[] = [];

  if (reconcileTarget) {
    reconcileTarget.forEach(e => {
      dataSource.push({
        targetDate: e.targetDate as string,
        invoiceNumber: e.invoiceNumber,
        invoiceAmount: e.invoiceAmount || 0,
        category: e.category || '',
        invoiceAmountDisp: e.invoiceAmount && common.numberFormater(e.invoiceAmount)
      });
    });
  }
  let confirmDataSource: IDataSource[] = [];
  async function confirmModal(): Promise<void> {
    // ワンタイムトークン取得
    await onGetToken();

    function confirmModalContent(): any {
      return (
        <>
          <Descriptions size="small" title="企業情報" layout="vertical" bordered>
            <Descriptions.Item label="企業ID">{summary.companyId}</Descriptions.Item>
            <Descriptions.Item label="企業名" span={2}>
              {summary.companyName}
            </Descriptions.Item>
            <Descriptions.Item label="預り金残">{common.numberFormater(summary.creditBalance)}</Descriptions.Item>
            <Descriptions.Item label="消込後預り金残">
              {common.numberFormater(summary.creditBalance - totalSelectedAmount)}
            </Descriptions.Item>
            <Descriptions.Item label="選択された請求額合計">{common.numberFormater(totalSelectedAmount)}</Descriptions.Item>
          </Descriptions>
          <Divider></Divider>
          <Table className={'reconcileTable'} columns={columns} dataSource={confirmDataSource} pagination={false} />
        </>
      );
    }
    const onPostReconcileAsync = async (): Promise<void> => {
      try {
        const invoices = selectedInvoices.map(e => ({ invoiceNumber: e.invoiceNumber as string }));
        await onPostReconcile(summary.companyId, invoices);
        setSelectedInvoices([]);
      } catch (err) {
        onError(err);
      }
    };
    Modal.confirm({
      title: <span>消込処理を実行します。</span>,
      centered: true,
      content: confirmModalContent(),
      width: 600,
      okText: '消込を確定',
      cancelText: '戻る',
      onOk: onPostReconcileAsync,
      onCancel: onClearToken
    });
  }

  function reconcileHandleOk(): void {
    confirmDataSource = [];
    selectedInvoices.forEach(e => {
      confirmDataSource.push({
        targetDate: e.targetDate as string,
        invoiceNumber: e.invoiceNumber,
        invoiceAmount: e.invoiceAmount || 0,
        category: e.category || '',
        invoiceAmountDisp: e.invoiceAmount && common.numberFormater(e.invoiceAmount)
      });
    });
    confirmModal();
  }
  function reconcileHandleCancel(): void {
    setSelectedInvoices([]);
    onCloseReconcileModal();
  }
  function refundHandleOk(): void {
    refundModal(summary.companyId, summary.companyName, summary.creditBalance, refundAmount, galileoptClientCode, reason);
  }
  function refundHandleCancel(): void {
    setRefundModalVisible(false);
    setRefundAmount(0);
    setRemainingCreditBalance(summary.creditBalance);
    setOkRefundButtonDisabled(true);
  }

  function refundOnChange(value: any): void {
    setRefundAmount(isNaN(value + 0) ? 0 : value);
    setRemainingCreditBalance(isNaN(summary.creditBalance - value) ? 0 : summary.creditBalance - value);
    if (value > 0) {
      setOkRefundButtonDisabled(false);
    } else {
      setOkRefundButtonDisabled(true);
    }
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          {/* eslint-disable-next-line */}
          <a
            onClick={() => {
              onBackToSearchCompany();
            }}
          >
            企業検索
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>請求・入金管理</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        title={'企業情報'}
        extra={[
          <Button
            key="1"
            disabled={!isAdminMCDPUser}
            onClick={() => {
              onGetReconcileTargetList(summary.companyId);
            }}
          >
            預り金から消込
          </Button>,
          <Button
            key="2"
            disabled={!isAdminMCDPUser}
            onClick={() => {
              navigationTo('accountingTransfer');
            }}
          >
            預り金を他企業に付替
          </Button>,
          <Button
            key="3"
            disabled={!isAdminMCDPUser}
            onClick={() => {
              setRefundModalVisible(true);
            }}
          >
            返金
          </Button>
        ]}
      >
        <Descriptions className={'summaryTable'} size="small" layout="vertical" bordered column={7}>
          <Descriptions.Item label="企業ID" className="companyId">
            {summary.companyId}
          </Descriptions.Item>
          <Descriptions.Item label="企業名" className="companyName">
            {summary.companyName}
          </Descriptions.Item>
          <Descriptions.Item label="企業区分" className="companyType">
            {convertCompanyType(summary.companyType)}
          </Descriptions.Item>
          <Descriptions.Item label="課金サイクル" className="chargingCycle">
            {summary.chargingCycleStartDate && summary.chargingCycleEndDate
              ? yyyymmddSlashFormatter(summary.chargingCycleStartDate) + '～' + yyyymmddSlashFormatter(summary.chargingCycleEndDate)
              : ''}
          </Descriptions.Item>
          <Descriptions.Item className={'right'} label="請求残">
            {summary.invoiceBalance < 0 ? (
              <label style={{ color: 'red' }}>{numberFormater(summary.invoiceBalance)}</label>
            ) : (
              numberFormater(summary.invoiceBalance)
            )}
          </Descriptions.Item>
          <Descriptions.Item className={'right'} label="預り金残">
            {summary.creditBalance < 0 ? (
              <label style={{ color: 'red' }}>{numberFormater(summary.creditBalance)}</label>
            ) : (
              numberFormater(summary.creditBalance)
            )}
          </Descriptions.Item>
          <Descriptions.Item className={'right'} label="過不足">
            {summary.accountBalance < 0 ? (
              <label style={{ color: 'red' }}>{numberFormater(summary.accountBalance)}</label>
            ) : (
              numberFormater(summary.accountBalance)
            )}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <Modal
        title="預り金から消込"
        visible={reconcileModalVisible}
        style={{ top: 20 }}
        okText={'消込内容を確認'}
        cancelText={'取消'}
        onOk={reconcileHandleOk}
        onCancel={reconcileHandleCancel}
        okButtonProps={{ disabled: okButtonDisabled }}
      >
        <Descriptions size="small" title="企業情報" layout="vertical" bordered>
          <Descriptions.Item label="企業ID">{summary.companyId}</Descriptions.Item>
          <Descriptions.Item label="企業名" span={2}>
            {summary.companyName}
          </Descriptions.Item>

          <Descriptions.Item label="預り金残">{common.numberFormater(summary.creditBalance)}</Descriptions.Item>
          <Descriptions.Item label="消込後預り金残">
            {remainingCreditBalance < 0 ? (
              <label style={{ color: 'red' }}>{common.numberFormater(remainingCreditBalance)}</label>
            ) : (
              common.numberFormater(remainingCreditBalance)
            )}
          </Descriptions.Item>
          <Descriptions.Item label="消込額合計">{common.numberFormater(totalSelectedAmount)}</Descriptions.Item>
        </Descriptions>
        <div>
          <span className={'errMsg'}>{modalMsg}</span>
        </div>
        <Divider></Divider>
        <Table className={'reconcileTable'} rowSelection={rowSelection} columns={columns} dataSource={dataSource} pagination={false} />
      </Modal>
      <Modal
        title="返金"
        visible={refundModalVisible}
        style={{ top: 20 }}
        okText={'返金内容を確認'}
        cancelText={'取消'}
        onOk={refundHandleOk}
        onCancel={refundHandleCancel}
        okButtonProps={{ disabled: okRefundButtonDisabled }}
      >
        <Descriptions size="small" title="企業情報" layout="vertical" bordered>
          <Descriptions.Item label="企業ID">{summary.companyId}</Descriptions.Item>
          <Descriptions.Item label="企業名" span={2}>
            {summary.companyName}
          </Descriptions.Item>

          <Descriptions.Item label="預り金残">{common.numberFormater(summary.creditBalance)}</Descriptions.Item>
          <Descriptions.Item label="返金後預り金残">{common.numberFormater(remainingCreditBalance)}</Descriptions.Item>
          <Descriptions.Item label="返金額">{common.numberFormater(refundAmount)}</Descriptions.Item>
        </Descriptions>
        <Descriptions size="small" bordered column={1}>
          <Descriptions.Item label="返金額">
            <InputNumber
              min={0}
              max={summary.creditBalance}
              value={refundAmount}
              defaultValue={0}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
              onChange={refundOnChange}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Galileopt取引先コード">
            <Input
              value={galileoptClientCode}
              onChange={(e: any) => {
                setGalileoptClientCode(e.target.value);
              }}
            ></Input>
          </Descriptions.Item>
          <Descriptions.Item label="返金理由">
            <Input.TextArea
              value={reason}
              onChange={(e: any) => {
                setReason(e.target.value);
              }}
              rows={2}
            ></Input.TextArea>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  );
}
