import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import { SearchBtnContext } from '../context';

/* eslint-disable @typescript-eslint/no-unused-vars */
export default function InputField({ form, label, placeholder, id }: any): JSX.Element {
  const [searchDisable, setSearchDisable] = useContext(SearchBtnContext);

  const checkValidation = (rule: any, value: string, callback: any, label: string): any => {
    const values = form.getFieldsValue();
    let isContinue = true;
    for (const [key, value] of Object.entries(values)) {
      if (isContinue) {
        if (value) {
          isContinue = false;
          setSearchDisable(false);
        } else {
          setSearchDisable(true);
        }
      }
    }
  };

  return (
    <Form.Item label={id}>
      {form.getFieldDecorator(label, {
        rules: [
          {
            validator: checkValidation
          }
        ]
      })(<Input placeholder={placeholder} size="large" />)}
    </Form.Item>
  );
}
