import React from 'react';
import { IProps } from '../../domain/model';
import InvoiceDelete from '../../components/user/invoiceDelete/invoiceDelete';

/* eslint-disable react/no-unescaped-entities */
export default function invoiceDeletePage({ state }: IProps): JSX.Element {
  function renderContents(): any {
    let tempValue: any = '';
    if (!state.loading) {
      tempValue = (
        <div>
          <InvoiceDelete state={state} />
        </div>
      );
    }
    return tempValue;
  }

  return <span>{renderContents()}</span>;
}
