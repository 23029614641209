import React from 'react';
import { Alert, Icon } from 'antd';
import { IProps, INotifyInfo } from '../../../domain/model';
import { updateIndex } from '../../../domain/common';
import './nortification.css';

/* eslint-disable react/no-unescaped-entities */
export default function Notification({ state }: IProps): JSX.Element {
  const msg: any = [];
  const arrowIcon = <Icon type="arrow-down" className="arrow" />;

  function checkAlert(): void {
    if (state.unpaidInvoiceList.length > 0) {
      msg.push({
        key: `notificationId1${updateIndex()}`,
        index: '#invoiceList',
        title: '未入金の請求が' + state.unpaidInvoiceList.length + '件あります。'
      });
    }

    state.notifyInfo.forEach((notification: INotifyInfo) => {
      if (notification.isNotify) {
        msg.push({
          key: `notificationId2${updateIndex()}`,
          index: '#serviceContents',
          title: notification.serviceName + 'の「次年度更新」手続きを行ってください。'
        });
      }
      if (notification.isOutOfRange) {
        msg.push({
          key: `notificationId3${updateIndex()}`,
          index: '#serviceContents',
          title: notification.serviceName + 'の次年度更新が行われていません。次年度更新ボタンを押下し契約の更新を行ってください。'
        });
      }
    });
  }

  function createMSG(): any {
    checkAlert();
    const listItems = msg.map((obj: any) => (
      <a href={obj.index} key={obj.key}>
        <Alert
          className="alertCustom"
          icon={arrowIcon}
          key={obj.key}
          message={
            <span>
              <Icon type="exclamation" className="exclamation" />
              <Icon type="exclamation" className="exclamation" />
              {obj.title}
            </span>
          }
          banner
        />
      </a>
    ));
    return listItems;
  }

  return <div className="msgArea">{createMSG()}</div>;
}
