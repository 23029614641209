import React from 'react';
import { Modal, Descriptions } from 'antd';
import { onPostRefund, onError, onGetToken, onClearToken } from '../../../domain/middleware';
import * as common from '../../../domain/common';

export default async function refundModal(
  companyId: string,
  companyName: string,
  creditBalance: number,
  refundAmount: number,
  galileoptClientCode: string,
  reason: string
): Promise<void> {
  // ワンタイムトークン取得
  await onGetToken();

  function confirmContent(): JSX.Element {
    return (
      <>
        <Descriptions size="small" title="企業情報" layout="vertical" bordered>
          <Descriptions.Item label="企業ID">{companyId}</Descriptions.Item>
          <Descriptions.Item label="企業名" span={2}>
            {companyName}
          </Descriptions.Item>

          <Descriptions.Item label="預り金残">{common.numberFormater(creditBalance)}</Descriptions.Item>
          <Descriptions.Item label="返金後預り金残">{common.numberFormater(creditBalance - refundAmount)}</Descriptions.Item>
          <Descriptions.Item label="返金額">{common.numberFormater(refundAmount)}</Descriptions.Item>
          <Descriptions.Item label="Galileopt取引先コード" span={3}>
            {galileoptClientCode}
          </Descriptions.Item>
          <Descriptions.Item label="返金理由" span={3}>
            {reason}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  }
  const onPostRefundAsync = async (): Promise<void> => {
    try {
      await onPostRefund(companyId, refundAmount, galileoptClientCode, reason);
    } catch (err) {
      onError(err);
    }
  };
  Modal.confirm({
    title: '返金処理を確定します。',
    centered: true,
    content: confirmContent(),
    width: 800,
    okText: '返金を確定',
    cancelText: '戻る',
    onOk: onPostRefundAsync,
    onCancel: onClearToken
  });
}
