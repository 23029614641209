import { getState } from './store';
import * as Model from './model';
import * as Const from './const';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { onSetNewCourse, onPreviewContract } from './middleware';
import {
  setPlanChangeFlag,
  setRecalcFlag,
  isNeedRecalc,
  alertNotification,
  alertAddQuantityLimit
} from '../components/user/notification/alertNotification';

/**
 * 値を円に変換
 * @param item 金額
 */
export function jpyFormater(item: number): string {
  return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(item);
}

/**
 * 値を3桁毎にカンマ打って表示
 * @param item 金額
 */
export function numberFormater(item: number): string {
  return item.toLocaleString();
}

/**
 * 日付を「年/月」に変換
 * @param dateValue 日付
 */
export function dateFormatter(dateValue: string): string {
  const userSelectDate = moment(dateValue);
  const dateItem = userSelectDate.format('YYYY/MM');

  return dateItem;
}

/**
 * 期間を「開始日付～終了日付」に変換
 * @param startDate 開始日
 * @param endDate 終了日
 */
export function termFormatter(startDate: string, endDate: string): string {
  const startTerm = moment(startDate);
  const endTerm = moment(endDate);
  const dateItem = startTerm.format('YYYY/MM') + '～' + endTerm.format('YYYY/MM');

  return dateItem;
}

/**
 * 日付を「YYYY-MM-DD」に変換
 * @param dateValue 日付
 */
export function yyyymmddFormatter(dateValue: any): string {
  const date = moment(dateValue);
  const dateItem = date.format('YYYY-MM-DD');

  return dateItem;
}

/**
 * 日付を「YYYY/MM/DD」に変換
 * @param dateValue 日付
 */
export function yyyymmddSlashFormatter(dateValue: any): string {
  let date;
  if (dateValue) {
    date = moment(dateValue);
  } else {
    date = moment();
  }

  return date.format('YYYY/MM/DD');
}

/**
 * 1日を取得します。
 * 第2引数を指定することで前月、翌月も取得できます。
 * YYYY-MM-DDの形式で返却します。
 *
 * @param date 日付
 * @param when ..., -1:前月末日 0:当月末日 1:翌月末日, ...
 */
export const getFirstDayOfMonth = (date: any, when = 0): string => {
  const tempDate = new Date(date);

  // NOTE: +9 hours to adjust from UTC to JST
  return yyyymmddFormatter(new Date(tempDate.getFullYear(), tempDate.getMonth() + when, 1, 0, 0, 0, 0));
};

/**
 * ソート用に日付を比較して、差を数字で返却します。
 * @param date1
 * @param date2
 */
export const getDateDifference = (date1: string, date2: string): number => {
  if (date1 === '' && date2 === '') {
    return 0;
  }
  if (date1 === '') {
    return -1;
  }
  if (date2 === '') {
    return 1;
  }

  if (moment(date1) > moment(date2)) {
    return 1;
  }
  if (moment(date1) === moment(date2)) {
    return 0;
  }
  if (moment(date1) < moment(date2)) {
    return -1;
  }
  return 0;
};

/**
 * 一意のkeyを生成
 */
let lastIndex = 0;
export const updateIndex = (): number => {
  lastIndex++;
  return lastIndex;
};

/**
 *isAddItemがTrue/Falseの判定
 * @param userInputValue ユーザーが入力した値
 * @param product 対象となる商品情報
 * @param estimateCourseResult コース判定結果
 */
export function checkIsAddItem(userInputValue: boolean | number, product: Model.IOneProduct, estimateCourseResult?: string): boolean {
  let isAddItem = false;

  if (typeof userInputValue === 'boolean') {
    if (userInputValue) {
      // 契約なしのときtrue
      isAddItem = !product.contractStatus;
    }
  } else {
    // 値が０を超える場合はtrue
    isAddItem = userInputValue > 0;
    // 全プロジェクト利用料は次年度更新時に0を受け付ける。
    if (userInputValue === 0 && product.productRatePlanChargeKey.includes('Charge')) {
      isAddItem = true;
    }
  }

  // remove/ignoreの場合はfalse判断
  if (estimateCourseResult === 'remove' || estimateCourseResult === 'ignore') {
    isAddItem = false;
  }

  return isAddItem;
}

/**
 * estimateCourseResultを元に変更カテゴリの取得
 * @param estimateCourseResult コース判定結果
 */
export function getChangeCategory(estimateCourseResult?: string): string {
  return estimateCourseResult ? estimateCourseResult : 'add';
}

/**
 * productRatePlanChargeKey の接頭辞（_まで）を取得
 * @example All_Initial ⇒ All_ を取得（_まで）
 * @param prpcKey キー
 * @returns _までの文字列
 */
export function getProductRatePlanChargeKeyPrefix(prpcKey: string): string {
  let prefix = '';

  if (prpcKey) {
    // _があればそこまで、なければ全体
    const pos = prpcKey.indexOf('_');
    prefix = pos >= 0 ? prpcKey.substring(0, pos + 1) : prpcKey;
  }

  return prefix;
}

/**
 * 契約のフィールド変更を保存
 * @param userInputValue ユーザーが入力した値
 * @param product 対象となる商品情報
 * @param estimateCourseResult コース判定結果
 */
/* eslint-disable complexity */
export function onSaveInputValues(userInputValue: boolean | number, product: Model.IOneProduct, estimateCourseResult?: string): void {
  const savedItemList: Model.ISavedUserInputList[] = getState().updateForm.savedUserInputList;
  const productList = getState().productList;

  const changeCategory = getChangeCategory(estimateCourseResult);
  const isAddItem = checkIsAddItem(userInputValue, product, estimateCourseResult);

  let isPush = true;

  savedItemList.forEach((savedItem: Model.ISavedUserInputList, index: number) => {
    if (product.productRatePlanChargeKey === savedItem.product.productRatePlanChargeKey) {
      // 同じデータを変えたとき
      let prpckeyGroup: string;
      let deleteGroupProduct = false;
      if (typeof userInputValue === 'boolean') {
        // 初期手数料のトグルをOFFにした場合は同時にリストから削除する
        deleteGroupProduct = !userInputValue && product.initialSetUp;
        // 同時にリストから除外するプロダクトを指定（セットとなっているCharge系アイテム）
        prpckeyGroup = getProductRatePlanChargeKeyPrefix(product.productRatePlanChargeKey);
        // 契約状態に変更がない場合はリスト追加対象外
        isPush = !(savedItem.product.contractStatus === userInputValue);
      } else {
        // 数値に変更がない場合はリスト追加対象外
        isPush = !(savedItem.product.amount === userInputValue);
      }

      // リストから除外
      savedItemList.splice(index, 1);

      // 同時にリストから除外するものがあれば除外
      if (deleteGroupProduct) {
        savedItemList.forEach((item: Model.ISavedUserInputList, index: number) => {
          // 同一グループのProductRatePlanChargeKeyの場合は削除
          if (item.product.productRatePlanChargeKey.startsWith(prpckeyGroup)) {
            savedItemList.splice(index, 1);
            isPush = false;
          }
        });
      }
    }

    if (
      Const.isProductCharge(savedItem.product.productRatePlanChargeKey) &&
      Const.isProductCharge(product.productRatePlanChargeKey) &&
      product.productRatePlanChargeKey !== savedItem.product.productRatePlanChargeKey
    ) {
      // リストから除外
      savedItemList.splice(index, 1);
    }
    if (
      Const.isPrimeProjectCharge(savedItem.product.productRatePlanChargeKey) &&
      Const.isPrimeProjectCharge(product.productRatePlanChargeKey) &&
      product.productRatePlanChargeKey !== savedItem.product.productRatePlanChargeKey
    ) {
      // リストから除外
      savedItemList.splice(index, 1);
    }
    if (
      Const.isLiteProjectCharge(savedItem.product.productRatePlanChargeKey) &&
      Const.isLiteProjectCharge(product.productRatePlanChargeKey) &&
      product.productRatePlanChargeKey !== savedItem.product.productRatePlanChargeKey
    ) {
      // リストから除外
      savedItemList.splice(index, 1);
    }
    if (
      Const.isPrimeLiteProjectCharge(savedItem.product.productRatePlanChargeKey) &&
      Const.isPrimeLiteProjectCharge(product.productRatePlanChargeKey) &&
      product.productRatePlanChargeKey !== savedItem.product.productRatePlanChargeKey
    ) {
      // リストから除外
      savedItemList.splice(index, 1);
    }
    if (
      Const.isLiteSetCharge(savedItem.product.productRatePlanChargeKey) &&
      Const.isLiteSetCharge(product.productRatePlanChargeKey) &&
      product.productRatePlanChargeKey !== savedItem.product.productRatePlanChargeKey
    ) {
      // リストから除外
      savedItemList.splice(index, 1);
    }

    // isAddItemがfalseでremove以外は常に追加対象外
    if (!isAddItem && changeCategory !== 'remove') {
      isPush = false;
    }
    if (isNeedRecalc) {
      alertNotification('calc');
    }
    setRecalcFlag(false);
  });

  if (isPush) {
    savedItemList.push({ product, userInputValue, changeCategory, isAddItem });

    // システム連携サービスの初期手数料を追加するときは利用料も追加する
    if (product.productRatePlanChargeKey === Const.DATALINK_INITIAL) {
      productList.productList.forEach(product => {
        if (product.category === 'オーナー') {
          product.product.forEach(p => {
            if (p.productRatePlanChargeKey === Const.DATALINK_CHARGE) {
              savedItemList.push({ product: p, userInputValue, changeCategory, isAddItem });
            }
          });
        }
      });
    }
  }
}

/**
 * プロジェクト数からコースの判定
 * @param userInputValue ユーザーが入力した値
 * @param product 対象となる商品情報
 */
/* eslint-disable complexity */
export function estimateCourse(userInputValue: number, product: Model.IOneProduct): void {
  const ownerBasic: Model.IOneProduct[] = getState().updateForm.ownerBasicProductList;
  let course = '';
  if (1 <= userInputValue && userInputValue <= 99) {
    course = 'Aコース';
  } else if (100 <= userInputValue && userInputValue <= 249) {
    course = 'Bコース';
  } else if (250 <= userInputValue && userInputValue <= 499) {
    course = 'Cコース';
  } else if (500 <= userInputValue && userInputValue <= 999) {
    course = 'Dコース';
  } else if (1000 <= userInputValue && userInputValue <= 2999) {
    course = 'Eコース';
  } else if (3000 <= userInputValue && userInputValue <= 4999) {
    course = 'Fコース';
  } else if (5000 <= userInputValue) {
    course = 'Gコース';
  } else {
    course = '担当者にお問い合わせください。';
  }

  for (const basicPj of ownerBasic) {
    if (basicPj.productRatePlanName.includes(course)) {
      // course名が存在する場合
      if (basicPj.productRatePlanChargeId === product.productRatePlanChargeId) {
        // 去年と同じコース：add対象とする（updateとしない）
        onSaveInputValues(userInputValue, product, 'add');
      } else {
        // 前サイクルと異なるコースに変更されたとき
        if (product.contractStatus) {
          // 次のSubscriptionでは不要のため削除対象
          onSaveInputValues(userInputValue, product, 'remove');
        } else {
          // 次のSubscriptionでは不要のため無視対象
          onSaveInputValues(userInputValue, product, 'ignore');
        }

        // 変更先のコースをadd対象とする
        onSaveInputValues(userInputValue, basicPj, 'add');
        setPlanChangeFlag(true);
      }

      // 新しいコースとして設定
      onSetNewCourse(basicPj);

      // コースが1つ特定できたら終了
      break;
    }
  }
}

/**
 * 初期設定料金をオンにした際に活性化する商品を保存
 * @param productName ユーザーがオンにした事務契約手数料の商品名
 * @param userInputValue ユーザーが変更した事務契約手数料の値(オン/オフ=True/False)
 */
export function saveRelatedProductList(productName: string, userInputValue: boolean): void {
  const relatedProductList: Model.IRelatedProductList[] = getState().updateForm.relatedProductList;
  let isAddItem = false;

  relatedProductList.forEach((item: Model.IRelatedProductList, index: number) => {
    if (item.productName === productName) {
      relatedProductList.splice(index, 1);
    }
  });
  if (!userInputValue) {
    isAddItem = true;
  }
  relatedProductList.push({ productName, userInputValue, isAddItem });
}

/**
 * 現在契約と変更した契約を比較し、変更されていない契約は昨年度の契約を引き継ぐ
 */
export function takeOverFromCurrentContract(): void {
  const currentState = getState();
  const currentContract: Model.IProductList[] = currentState.productList.productList;
  const savedList: Model.ISavedUserInputList[] = currentState.updateForm.savedUserInputList;

  currentContract.forEach((prod: Model.IProductList) => {
    prod.product.forEach((product: Model.IOneProduct) => {
      if (
        !savedList.some(s => Const.isSameProduct(s.product.productRatePlanChargeKey, product.productRatePlanChargeKey)) &&
        product.contractStatus
      ) {
        // 画面で変更していないが現在の契約には存在する場合は追加
        let userInputValue: number | boolean;
        if (product.initialSetUp || product.salesUnit === 0) {
          // トグル形式で入力するもの
          userInputValue = product.contractStatus;
        } else {
          // 数値入力するもの
          userInputValue = product.amount;
        }
        savedList.push({ product, userInputValue, changeCategory: 'add', isAddItem: true });
      }
    });
  });
}

/**
 * 画面の入力情報から変更情報（数量＋対象プロダクトのキー）のリストを生成する
 *
 * @param savedList 画面入力情報
 * @returns 変更情報（数量と対象プロダクトのキーのリスト）
 */
export function createProductUpdateList(savedList: Model.ISavedUserInputList[]): Model.IUpdateProductInfo[] {
  const updateInfoList: Model.IUpdateProductInfo[] = [];

  if (savedList && savedList.length > 0) {
    let quantity = 0;
    let prevQuantity = 0;
    savedList
      .filter(s => s.changeCategory === 'add')
      .forEach((item: Model.ISavedUserInputList) => {
        if (typeof item.userInputValue === 'number') {
          // 入力された数値をセット
          quantity = item.userInputValue;
          prevQuantity = item.product.amount || 0;
        } else {
          // 利用可否の選択項目の場合（type : boolean（true:利用あり））
          quantity = item.userInputValue ? 1 : 0;
          prevQuantity = item.product.contractStatus ? 1 : 0;
        }

        // 全プロジェクト利用料はは次年度更新時に0を受け付ける。
        if (item.product.productRatePlanChargeKey.includes('Charge') && item.product.contractStatus && item.userInputValue === 0) {
          updateInfoList.push({
            productRatePlanChargeKey: item.product.productRatePlanChargeKey,
            totalQuantity: 0,
            addQuantity: 0
          });
        } else {
          const updateInfo: Model.IUpdateProductInfo = {
            productRatePlanChargeKey: item.product.productRatePlanChargeKey,
            totalQuantity: quantity,
            addQuantity: quantity - prevQuantity
          };
          updateInfoList.push(updateInfo);
        }
      });
  }

  // addQuantity/totalQuantityが両方0のものは除く
  return updateInfoList.filter(
    ui => ui.totalQuantity !== undefined && ui.addQuantity !== undefined && (ui.totalQuantity > 0 || ui.addQuantity > 0)
  );
}

/**
 * 見積もりPreviewのレスポンスデータの生成 見積もり実行
 * @param pageId ページID
 * @returns result 計算API 成功可否
 */
export function previewContract(pageId: string): boolean {
  const currentState = getState();
  const previewContract = currentState.previewForm;

  previewContract.targetDate = currentState.updateForm.contractEffectiveDate;
  previewContract.initialTerm = currentState.updateForm.remainMonths;

  if (pageId === 'renew') {
    currentState.updateForm.remainTerm = '12';
  }
  // 契約済みで変更してない情報もsavedUserInputListに追加
  takeOverFromCurrentContract();
  previewContract.products = createProductUpdateList(currentState.updateForm.savedUserInputList);

  let noError = true;

  let projectChargeTotalQuantity = 0;
  let primeProjectChargeTotalQuantity = 0;
  // TODO: Lite表示時以下を有効にする
  // let liteProjectChargeTotalQuantity = 0;
  // let litePrimeProjectChargeTotalQuantity = 0;
  // let liteSetChargeTotalQuantity = 0;
  previewContract.products.forEach(p => {
    if (Const.isProductCharge(p.productRatePlanChargeKey)) {
      projectChargeTotalQuantity = p.totalQuantity as number;
      if ((p.addQuantity as number) > Const.ADD_QUANTITY_LIMIT) {
        alertAddQuantityLimit('オーナー基本サービス');
        noError = false;
      }
    }
    if (Const.isPrimeProjectCharge(p.productRatePlanChargeKey)) {
      primeProjectChargeTotalQuantity = p.totalQuantity as number;
      if ((p.addQuantity as number) > Const.ADD_QUANTITY_LIMIT) {
        alertAddQuantityLimit('プライムサービス');
        noError = false;
      }
    }
    if (p.productRatePlanChargeKey === Const.BACKUP_CHARGE) {
      if ((p.addQuantity as number) > Const.ADD_QUANTITY_LIMIT) {
        alertAddQuantityLimit('データバックアップサービス');
        noError = false;
      }
    }
    // TODO: Lite表示時以下を有効にする
    // if (Const.isLiteProjectCharge(p.productRatePlanChargeKey)) {
    //   liteProjectChargeTotalQuantity = p.totalQuantity as number;
    //   if ((p.addQuantity as number) > Const.ADD_QUANTITY_LIMIT) {
    //     alertAddQuantityLimit('オーナー基本サービスLite');
    //     noError = false;
    //   }
    // }
    // if (Const.isPrimeLiteProjectCharge(p.productRatePlanChargeKey)) {
    //   litePrimeProjectChargeTotalQuantity = p.totalQuantity as number;
    //   if ((p.addQuantity as number) > Const.ADD_QUANTITY_LIMIT) {
    //     alertAddQuantityLimit('Liteプライムサービス');
    //     noError = false;
    //   }
    // }
    // if (Const.isLiteSetCharge(p.productRatePlanChargeKey)) {
    //   liteSetChargeTotalQuantity = p.totalQuantity as number;
    //   if ((p.addQuantity as number) > Const.ADD_QUANTITY_LIMIT) {
    //     alertAddQuantityLimit('Liteプライムサービスセット');
    //     noError = false;
    //   }
    // }
    // if (p.productRatePlanChargeKey === Const.LITEBACKUP_CHARGE) {
    //   if ((p.addQuantity as number) > Const.ADD_QUANTITY_LIMIT) {
    //     alertAddQuantityLimit('データバックアップサービスLite');
    //     noError = false;
    //   }
    // }
  });
  if (primeProjectChargeTotalQuantity > projectChargeTotalQuantity) {
    alertNotification('invalidPrime');
    noError = false;
  }
  if (1 <= primeProjectChargeTotalQuantity && primeProjectChargeTotalQuantity <= 9) {
    alertNotification('invalidPrimeLessThanTen');
    noError = false;
  }
  // TODO:Lite表示時以下を有効にする
  // if (1 <= liteProjectChargeTotalQuantity && liteProjectChargeTotalQuantity <= 9) {
  //   alertNotification('invalidLiteLessThanTen');
  //   noError = false;
  // }
  // if (litePrimeProjectChargeTotalQuantity > liteProjectChargeTotalQuantity) {
  //   alertNotification('invalidLitePrime');
  //   noError = false;
  // }
  // if (1 <= litePrimeProjectChargeTotalQuantity && litePrimeProjectChargeTotalQuantity <= 9) {
  //   alertNotification('invalidLitePrimeLessThanTen');
  //   noError = false;
  // }
  // if (1 <= liteSetChargeTotalQuantity && liteSetChargeTotalQuantity <= 9) {
  //   alertNotification('invalidLiteSetLessThanTen');
  //   noError = false;
  // }
  // if (liteSetChargeTotalQuantity && (liteProjectChargeTotalQuantity || litePrimeProjectChargeTotalQuantity)) {
  //   alertNotification('liteProjectAndSetAtSameTime');
  //   noError = false;
  // }
  // if (!projectChargeTotalQuantity && !liteProjectChargeTotalQuantity && !liteSetChargeTotalQuantity) {
  //   alertNotification('noContractChargeProduct');
  //   noError = false;
  // }

  if (1 <= projectChargeTotalQuantity && projectChargeTotalQuantity <= 9) {
    alertNotification('invalidBasic');
    noError = false;
  }

  // TODO:Lite表示時以下を削除する
  if (!projectChargeTotalQuantity) {
    alertNotification('noContractChargeProduct');
    noError = false;
  }

  if (noError) {
    onPreviewContract(pageId);
  }
  return noError;
}

/**
 * 契約データと入力データに差分があるか確認
 */
export function isChangedData(): boolean {
  const savedUserInputList: Model.ISavedUserInputList[] = getState().updateForm.savedUserInputList;
  // 差分があればtrueを返す
  return savedUserInputList.length > 0;
}

/**
 * 追加・変更＆次年度更新のリクエストデータの生成
 * @param subscriptionName サブスクリプション契約の名前
 */
export function createContractRequestData(subscriptionName: string): void {
  const currentState = getState();
  const updateContract = currentState.updateForm.updateContract;
  updateContract.subscriptionName = subscriptionName;
  updateContract.targetDate = currentState.updateForm.contractEffectiveDate;
  // 画面入力情報から更新用情報を生成する
  // 契約済みで変更してない情報もsavedUserInputListに追加
  takeOverFromCurrentContract();
  updateContract.products = createProductUpdateList(currentState.updateForm.savedUserInputList);
}

/**
 * ファイルダウンロード
 * @param response バックエンドからのレスポンス
 * @param fileName ダウンロードするファイル名
 */
export function downloadFile(response: any, fileName: string): void {
  const blob = new Blob([response.data], {
    type: response.data.type
  });
  saveAs(blob, fileName);
}

/**
 * 請求書PDFをダウンロード
 * @param response バックエンドから返却されたPDFデータ
 * @param invoiceNumber 請求書番号
 */
export function downloadInvoiceFile(response: any, invoiceNumber: string): void {
  const fileName = `kensetsu-site_${invoiceNumber}.pdf`;
  downloadFile(response, fileName);
}

/**
 * ID追加請求書Zipファイルをダウンロード
 * @param response バックエンドからのレスポンス
 * @param targetDate Zipファイル対象日付
 */
export function downloadIdInvoiceZipFile(response: any, targetDate: string): void {
  const zipFileName = targetDate + '.zip';
  downloadFile(response, zipFileName);
}

/**
 * BlobをTextに変換し、JSON型で返却
 * @param response バックエンドから返却されたデータ
 */
export async function blobToText(response: any): Promise<any> {
  const fileReader = new FileReader();
  const blob = new Blob([response.data], {
    type: response.data.type
  });
  return new Promise((resolve, reject) => {
    // 読み込み失敗時に実行されるイベント
    fileReader.onerror = () => {
      fileReader.abort();
      reject('Error');
    };

    // 読み込み成功時に実行されるイベント
    fileReader.onload = () => {
      if (typeof fileReader.result === 'string') {
        try {
          const result = JSON.parse(fileReader.result);
          resolve(result);
        } catch (error) {
          const makeResult: any = '{"data": {"readStatus":"success"}}';
          const result = JSON.parse(makeResult);
          resolve(result);
        }
      }
    };

    // 読み込みを開始する（UTF-8 と解釈してテキスト文字列を得る）
    fileReader.readAsText(blob);
  });
}

interface IDispString {
  [key: string]: string;
}

// アップロードステータス表示文字列
const statusDisp: IDispString = {
  initial: '初期状態',
  registered: '登録',
  deleted: '削除',
  error: 'エラー',
  warn: '警告',
  nodata: '請求データなし'
};

// データ種別表示文字列
const dataClassDisp: IDispString = {
  new: '新規',
  renew: '次年度更新',
  agent: '代行',
  ccus: 'CCUS',
  id: 'ID'
};

// 仕訳実行ステータス
const journalStatusDisp: IDispString = {
  initial: '初期状態',
  success: '成功',
  error: '失敗',
  warn: '警告',
  'N/A': '対象外'
};

/**
 * アップロードステータスの文字列変換
 *
 * @param status アップロードステータス
 * @return アップロードステータス表示文字列
 */
export function convertStatus(status: string): string {
  return statusDisp[status];
}

/**
 * 仕訳実行statusの文字列変換
 *
 * @param status 仕訳実行のステータス
 * @returns 仕訳実行ステータスの表示文字列
 */
export function convertJournalStatus(status: string): string {
  return journalStatusDisp[status];
}

/**
 * データ種別の文字列変換
 *
 * @param dataClass データ種別
 * @return データ種別表示文字列
 */
export function convertDataClass(dataClass: string): string {
  return dataClassDisp[dataClass];
}

/**
 * 企業区分の文字列変換
 *
 * @param companyType 企業区分
 */
export function convertCompanyType(companyType: string): string {
  let companyTypeStr = '';
  switch (companyType) {
    case '1':
      companyTypeStr = '元請';
      break;
    case '2':
      companyTypeStr = '協力';
      break;
    case '3':
      companyTypeStr = '代行';
      break;
  }
  return companyTypeStr;
}

/**
 * 指定の数値を指定の数値単位に切り上げ処理をする
 * （対象：正の整数のみ）
 * 1) value を ceilNum で割った余り (mod) を value から引く
 * 2) 1に対して mod が 0 より大きい（余りがある）場合は ceilNum 分を足す
 *    mod が 0 の場合（割り切れる）場合は何も足さない
 *
 * @param value 対象の数値
 * @param ceilNum この数量単位に切り上げる
 * @returns 切り上げた数値
 */
export function ceilBy(value: number, ceilNum: number): number {
  if (value > 0 && ceilNum > 0) {
    return value - (value % ceilNum) + (value % ceilNum > 0 ? 1 : 0) * ceilNum;
  } else {
    // どちらかが0の場合は0を返す
    return 0;
  }
}
